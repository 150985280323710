import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import { checkoutSchema } from '../Schema';
import DeliveryAddress from '../Components/DeliveryAddress';
import { toast } from "react-toastify";
import { server } from '../util/server';
import axios from 'axios';
import { useFilterContext } from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
const Thankyou = () => {
    const {orderref} = useParams()
    const navigate = useNavigate()
    const { i18n, t } = useTranslation();
    const { project, isUserLogin } = useFilterContext();
    const [data, setData] = useState({'message_heading' : null, 'message_body' : null});
    const [orderData, setOrderData] = useState(null);
    const getOrderInfo = async () => {
        try {
            let url = server + 'auth/user/thank-you?api_type=web&user_id=' + isUserLogin +'&project=' +project +'&order_reference='+orderref
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                //console.log(data);
                setData({'message_heading' : data.message_heading, 'message_body' : data.message_body})
                setOrderData(data.order)

            } else {
                navigate('/cart')
            }
        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (orderref && isUserLogin) {
            getOrderInfo()
            let interval2 = setInterval(() => {
                getOrderInfo()
            }, 60000);
            return () => clearInterval(interval2);
           
        }else{
            navigate('/cart')
        }

    }, [orderref])
    const [remainingSeconds, setRemainingSeconds] = useState(sessionStorage.getItem('ottf'+orderref) ? sessionStorage.getItem('ottf'+orderref) : 
    orderData && orderData === 'Picked Up'
    ? 35 * 60
    :
    45 * 60);

  useEffect(() => {
    let interval = setInterval(() => {
        setRemainingSeconds(prevSeconds => {
            const newRemainingSeconds = prevSeconds - 1;
            sessionStorage.setItem('ottf'+orderref, newRemainingSeconds); 
            return newRemainingSeconds;
          });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formattedMinutes = Math.floor(remainingSeconds / 60)
    .toString()
    .padStart(2, '0');
  const formattedSeconds = (remainingSeconds % 60).toString().padStart(2, '0');

    const cancelOrder = async()=>{
        try {
            let res = await axios.post(server+"auth/user/order-cancel", {
                api_type: 'web',
                user_id: isUserLogin,
                order_id: orderData && orderData.order_id
              });
            let data = await res.data;
            if(data.status === 0){
                if(data.message){toast.error(t(data.message))}
                
                if(data.errors){
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(item);
                        })
                        
                    })
                }
            }else{
                toast.success(data.message);
                navigate('/menu')
            }
            
        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }


    return (
        <>
            <div className="container-fluid container-log col-lg-8 ">
                <div className="row">
                    <div className="col-lg-6">
                        <img className="img-fluid" src="/assets/front/images/product/thanku.png" alt="Thank you" />
                    </div>
                    <div className="container-login col-lg-6 text-center">
                        <div className="thank-h1">
                            <h1 className="fw-bold">{data && data.message_heading}</h1>
                        </div>
                        <div className="col-lg-12 mt-3 mb-0 thanku-head">
                            <h5> Order Reference : {orderData && orderData.order_ref}</h5>
                            
                            <p>{data && data.message_body}</p>
                        </div>
                        {remainingSeconds > 0 
                            ?
                                <div className="col-lg-12 timer">
                                    <span id="timer">{formattedMinutes}:{formattedSeconds}</span>
                                </div>
                            :
                            <div></div>
                        }
                       
                        <div className="col-lg-12 timer">
                            <span id="timer"></span>
                        </div>
                        <div className="col-lg-12 time">
                            <p className="del_hide"><b>Est. Time</b></p>
                        </div>
                        {
                            orderData &&
                            orderData.order_status === 'Preparing' 
                            ?
                            <p class="mt-1 fw-bold"><Link to="#" onClick={() => cancelOrder()} class="btn btn-primary rounded-0 py-2 px-4 del_hide" style={{ backgroundColor:'#ea1d25',border:'1px solid #ea1d25' }}>Cancel Order</Link></p>
                            :
                            <>
                            <div className="d-grid mt-4 del_hide">
                                <input type="submit" value="Track Your Order" className="btn btn-primary rounded-0 py-2 px-4 del_hide" style={{ backgroundColor: '#ea1d25', border: '1px solid #ea1d25' }} />
                            </div>
                            <div className="col-lg-12 thanku-link">
                                <p className="mt-1 fw-bold del_hide"><Link to="" className="color-black">Talk To Rider</Link></p>
                            </div>
                            </>
                        }
                       
                        
                    </div>

                </div>
            </div>
        </>
    )
}

export default Thankyou