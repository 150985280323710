import React,{useEffect,useState} from 'react'
import { Link,useNavigate,useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { logInSchema } from '../Schema';
import { toast } from "react-toastify";
import {server} from '../util/server';
import axios from 'axios';
import {useFilterContext} from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import { Meta } from '../Components/Meta';
import { Modal } from "react-bootstrap";
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

const Login = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (item) => setShow(true);
    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const {setUserLogin,setCartTotalCount,project,isUserLogin,updatemetas,updatePageBanner,page_banner,media_server} = useFilterContext();
    if(isUserLogin){
        navigate('/user/dashboard')
    }

    const passwordReset = async(e) => {
        e.preventDefault();
        let email = document.getElementById('forget-password').value;
        if(!email){
            toast.error(t("Email is required"))
        }else{
            try {
                let res = await axios.post(server+"auth/user/forgot-password", {email:email});
                let data = await res.data;
                if(data.status === false){
                    if(data.message){toast.error(t(data.message))}
                    
                    if(data.errors){
                        Object.keys(data.errors).forEach(key => {
                            data.errors[key].map(item => {
                                toast.error(t(item));
                            })
                            
                        })
                    }
                }else{
                    toast.success(t(data.message));
                    navigate('/recover-password')
                }
                
                
            } catch (error) {
                toast.error(t("Unexpected error occurred!"));
            }
        }
       
    }

    const submitData = async(query, actions)=>{
        try {
            query['project'] = project;
            query['api_type'] = 'web';
            let res = await axios.post(server+"auth/user/login", query);
            let data = await res.data;
            if(data.status === 0){
                if(data.message){t(toast.error(t(data.message)))}

                if(data.errors){
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(t(item));
                        })
                    })
                }
            }else{
                actions.resetForm();
                setUserLogin(data.session_id);
                sessionStorage.setItem('referral', data.referal_token)
                if(data.cart_count !== 0){
                    setCartTotalCount(data.cart_count)
                }
                if(data.restaurant_id){
                    sessionStorage.setItem('cart_res', data.restaurant_id)
                }

                if(sessionStorage.getItem('last_url')){
                    navigate(sessionStorage.getItem('last_url'))
                }else{
                    navigate('/user/dashboard')
                }
            }
        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }

    const getData = async() => {
        try {
            let url = server+'auth/web/web-login?project='+project+'&page_link=login&api_type=web'
            let res = await axios.get(url);
            let data = await res.data;
            if(data.status === 1){
                updatemetas(null,0,data.meta)
                updatePageBanner(data.page_banner)
            
            }else{
                toast.error(t(data.message))
            }
        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getData()
    },[])
    useEffect(() => {
        if(params.get("link")){
            axios({
                method: "get",
                url: server+"auth/web/account-activation?code="+params.get("link"),
            }).then(function (result) {
                if(result.data.status === 0){
                    toast.error(t(result.data.message))
                }else{
                    toast.success(t(result.data.message))
                    navigate('/login')
                }
             
            })
            .catch(function (error) {
                toast.error(t("Unexpected error occurred!"))
            });
        }
    },[params]);


    const initialValues = {
        email: '',
        password: '',
    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
        validationSchema: logInSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            submitData(values, actions);
        }
    });

    
    const handleFacebookSignIn = async(data) => {
        var signInData = data.data;
        try {
            let res = await axios.post(server + "auth/user/login", {'email' : signInData.email ? signInData.email : '', 'type_id' : signInData.id, 'project' : project, 'login_type' : 'facebook', 'api_type' : 'web'});
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
            } else {
                toast.success(t(data.message))
                setUserLogin(data.session_id);
                sessionStorage.setItem('referral', data.referal_token)
                if(data.cart_count !== 0){
                    setCartTotalCount(data.cart_count)
                }
                if(data.restaurant_id){
                    sessionStorage.setItem('cart_res', data.restaurant_id)
                }

                if(sessionStorage.getItem('last_url')){
                    navigate(sessionStorage.getItem('last_url'))
                }else{
                    navigate('/user/dashboard')
                }
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }


    const handleGoogleSignIn = async(data) => {
        var signInData = data;
        try {
            let res = await axios.post(server + "auth/user/login", {'email' : signInData.email, 'type_id' : signInData.sub, 'project' : project, 'login_type' : 'google', 'api_type' : 'web'});
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
            } else {
                //toast.success(data.message)
                setUserLogin(data.session_id);
                sessionStorage.setItem('referral', data.referal_token)
                if(data.cart_count !== 0){
                    setCartTotalCount(data.cart_count)
                }
                if(data.restaurant_id){
                    sessionStorage.setItem('cart_res', data.restaurant_id)
                }

                if(sessionStorage.getItem('last_url')){
                    navigate(sessionStorage.getItem('last_url'))
                }else{
                    navigate('/user/dashboard')
                }
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }

    return (
        <>
            <Meta />
            <div className="container login2-main col-lg-10">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 login-form2-pic bg-white ps-4">
                        {
                            page_banner &&  <img className="img-fluid mt-3"
                            src={media_server+"/"+page_banner}
                            alt="Login" />
                        }
                       
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 login2-form signUp-main2">
                        <form className="login-form" onSubmit={handleSubmit}>
                            <h2 className="mb-4 text-white">Login</h2>
                            <div className="row justify-content-center">
                                
                                <div className="col-12">
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <label className="cheack-label text-white">Email</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="email" name="email" defaultValue={values.email} onBlur={handleBlur} onChange={handleChange} autoComplete="off"/>
                                    <span className="color-white float-start">{errors.email && touched.email && errors.email}</span>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <label className="cheack-label text-white mt-3">Password</label>
                                    <input className="form-control bg-transparent text-white mt-3 mb-2" type="password" name="password" defaultValue={values.password} onBlur={handleBlur} onChange={handleChange} autoComplete="off" />
                                    <span className="color-white float-start">{errors.password && touched.password && errors.password}</span>
                                    <p>
                                        <Link to="#" className=" login2-p text-white float-end"  onClick={() => handleShow()}>Forget password?</Link></p>
                                </div>
                                <div className="col-lg-8 col-sm-12 mt-3 signup-form2-btn">
                                    <div className="d-grid">
                                        <button type="submit" value="login" className="btn mb-1">Login</button>
                                        <div className="login2-last">
                                            <p>Don't have an account?
                                                <Link to="/register">Register</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-8 social-login text-center">
                                    <LoginSocialFacebook
                                        appId='618432306862496'
                                        onResolve={(response) => handleFacebookSignIn(response)}
                                        onReject={(response) => console.log(response)}
                                    >
                                        <FacebookLoginButton style={{ fontSize: '15px' }}>Login with Facebook</FacebookLoginButton>
                                    </LoginSocialFacebook>
                                </div> */}
                                <div className="col-8 social-login text-center">
                                    <GoogleOAuthProvider clientId="954796258794-lsstgps91299hot5nnjsompo4s1d5ujh.apps.googleusercontent.com">
                                       <GoogleLogin
                                        onSuccess={credentialResponse => {
                                            const details = jwt_decode(credentialResponse.credential)
                                            handleGoogleSignIn(details)
                                        }}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                        />
                                    </GoogleOAuthProvider>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
        <Modal.Title>{t("Password Reset")}</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group mb-4">
                                <label>{t("Email")}</label>
                                <input type="email" className="form-control" id="forget-password" onChange={(e) => e.target.value} required/>
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" onClick={(e) => passwordReset(e)} className="btn btn-danger btn-block mt-10">
                                {t("Request Code")}
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default Login