
import React,{useEffect,useState} from 'react'
import { Link,useNavigate,useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { passwordResetSchema } from '../Schema';
import { toast } from "react-toastify";
import {server} from '../util/server';
import axios from 'axios';
import {useFilterContext} from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import { Meta } from '../Components/Meta';
import { Modal } from "react-bootstrap";

const RecoverPassword = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (item) => setShow(true);
    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const {setUserLogin,setCartTotalCount,project,isUserLogin,updatemetas,updatePageBanner,page_banner,media_server} = useFilterContext();
    if(isUserLogin){
        navigate('/')
    }



    const submitData = async(query, actions)=>{
        try {
            let res = await axios.post(server + "auth/user/password-reset", query);
            let data = await res.data;
            if (data.status === false) {
                if (data.message) { toast.error(t(data.message)) }

                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(item);
                        })

                    })
                }
            } else {
                toast.success(t(data.message));
                actions.resetForm();
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    
    }

   

    const initialValues = {
        code: '',
        password: '',
        cpassword: '',
    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
        validationSchema: passwordResetSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            submitData(values, actions);
        }
    });
    return (
        <>
            <div className="container login2-main col-lg-10">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 login-form2-pic bg-white ps-4">
                       <img className="img-fluid mt-3"
                            src={media_server+"/storage/banner/1667907496_register.jpg"}
                            alt="Login" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 login2-form signUp-main2">
                        <form className="login-form" onSubmit={handleSubmit}>
                            <h2 className="mb-4 text-white">Recover Password</h2>
                            <div className="row justify-content-center">
                                <div className="col-12">
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <label className="cheack-label text-white">Code</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="text"  name="code"
                                                        placeholder={t("Code")}
                                                        value={values.code} onBlur={handleBlur} onChange={handleChange} autoComplete="off"/>
                                    <span className="color-white float-start">{errors.code && touched.code && errors.code}</span>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <label className="cheack-label text-white mt-3">Password</label>
                                    <input className="form-control bg-transparent text-white mt-3"  type="password"
                                                        name="password"
                                                        placeholder={t("Password")}
                                                        value={values.password} onBlur={handleBlur} onChange={handleChange} autoComplete="off" />
                                    <span className="color-white float-start">{errors.password && touched.password && errors.password}</span>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <label className="cheack-label text-white mt-3">Confirm Password</label>
                                    <input className="form-control bg-transparent text-white mt-3 mb-2"  type="password"
                                                        name="cpassword"
                                                        placeholder={t("Confirm password")}
                                                        value={values.cpassword} onBlur={handleBlur} onChange={handleChange} autoComplete="off" />
                                    <span className="color-white float-start">{errors.cpassword && touched.cpassword && errors.cpassword}</span>
                                </div>
                                <div className="col-lg-8 col-sm-12 mt-3 signup-form2-btn">
                                    <div className="d-grid">
                                        <button type="submit" value="login" className="btn mb-1">Password Reset</button>
                                        <div className="login2-last">
                                            <p>{t("Password reset successfully?")}
                                                <Link to="/login">{t("Login")}</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

         
        </>
    )
}

export default RecoverPassword
