import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../context/filterContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { server } from '../util/server';
import axios from 'axios';
import { Meta } from '../Components/Meta';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { giftCardSchema } from '../Schema';


export const GiftCardDetail = () => {
    const { slug } = useParams();
    const { i18n, t } = useTranslation();
    const { updatemetas, updatePageBanner, isUserLogin, project, page_banner, media_server } = useFilterContext();
    const [data, setData] = useState(null);
    const [priceArray, setpriceArray] = useState([]);
    const [imageArray, setImageArray] = useState([]);
    const [pageData, setPageData] = useState(null);
    const [imageVal, setImageVal] = useState('');

    const getData = async () => {
        var myArray = slug.split('-');
        try {
            let url = server + 'auth/web/single-gift-card?project=Winterthur&api_type=web&page_link=gift-card&card_id=' + myArray[0]
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setData(data.data)
                setpriceArray(data.data.prices.split(','))
                setImageArray(data.data.images.split(','))
                if(data.data.images.split(',').length>0){
                    setImageVal(data.data.images.split(',')[0])
                }
                // updatemetas(null, 0, data.meta)
                // updatePageBanner(data.page_banner)
                // setPageData(data.page_data)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getData()
    }, [slug])


    const initialValues = {
        recepient_name: '',
        sender_name: '',
        recepient_email: '',
        delivery_date: '',
        message: '',
        gift_image: imageArray.length > 0 ? imageArray[0] : '',
        gift_price: '',
        card_type: data ? data.card_type : '',
    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
        validationSchema: giftCardSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            if(!isUserLogin){
                toast.info('Please Login to Proceed further!')
            }
            else{
                submitData(values, actions);
            }
        }
    });

    const submitData = async(query, actions)=>{
        try {
            query['project'] = project;
            query['api_type'] = 'web';
            query['user_id'] = isUserLogin;
            let res = await axios.post(server+"auth/web/buy-gift-card", query);
            let data = await res.data;
            if(data.status === 0){
                if(data.message){t(toast.error(t(data.message)))}
                if(data.errors){
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(t(item));
                        })
                    })
                }
            }else{
                actions.resetForm();
                toast.success(t(data.message))
            }
        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }


    return (
        <>
            <div className="container">
                <form method="post" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-4 col-md-10 col-sm-12 p-2">
                            {
                                imageArray.length > 0 &&
                                <img className="img-fluid mt-5 main-image" src={media_server + '' + imageVal} alt={data.card_type} />
                            }
                        </div>
                        <div className="col-lg-8 col-md-10 col-12 p-5">
                            <h6 className="display-6">{data && data.card_type} {t("Gift Cards")}</h6>
                            <h5 className="mt-3">{t("Choose design of")} {data && data.card_type} {t("card")}</h5>

                            <div className="row gift-cards">
                                <div className="col-lg-12 field mt-3">
                                    <div className="my-container" id="img-container">
                                        {
                                            imageArray.length > 0 && imageArray.map(item => (
                                                <label key={item}><input type="radio" name="gift_image" value={item} defaultValue={values.gift_image === item} onBlur={handleBlur} onChange={handleChange} onClick={()=>setImageVal(item) }/> <img className="img-fluid item" alt={data && data.card_type} src={media_server + '' + item} /></label>
                                            ))
                                        }
                                        <small className='text-danger fw-bold'>{errors.gift_image && touched.gift_image && errors.gift_image}</small>
                                    </div>
                                </div>

                            </div>

                            <div className="row gift-p">
                                <p className="mt-3">{t("Select amount for your gift card")}</p>
                                <div className="col-lg-12 field mt-3">
                                    <div className="my-container">
                                        {
                                            priceArray.length > 0 && priceArray.map(item => (
                                                <label key={item}><input type="radio" name="gift_price" value={item} defaultValue={values.gift_price === item} onBlur={handleBlur} onChange={handleChange} /><span id="price-radio">CHF{item}</span></label>
                                            ))
                                        }
                                    </div>
                                    <small className='text-danger fw-bold'>{errors.gift_price && 
                                    touched.gift_price && 
                                    <>
                                        {errors.gift_price}
                                        <br />
                                    </>
                                    }</small>
                                    <small><b className="color-red">{t("Note")}: </b>{t("your amount will not be returned once card is purchased even after card validation period is over")}</small>
                                </div>
                            </div>
                            
                            <h5 className="mt-4" style={{ marginBottom: '-8px' }}>{t("Delivery information")}</h5>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="cheack-label mt-3">{t("Recipient Name")}</label>
                                    <input className="form-control mt-3" type="text" name="recepient_name" defaultValue={values.recepient_name} onBlur={handleBlur} onChange={handleChange} />
                                    <small className='text-danger fw-bold'>{errors.recepient_name && touched.recepient_name && errors.recepient_name}</small>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="cheack-label mt-3">{t("Sender Name")}</label>
                                    <input className="form-control mt-3" type="text" name="sender_name" defaultValue={values.sender_name} onBlur={handleBlur} onChange={handleChange} />
                                    <small className='text-danger fw-bold'>{errors.sender_name && touched.sender_name && errors.sender_name}</small>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <label className="cheack-label mt-3">{t("Recipient Email")}</label>
                                    <input className="form-control mt-3 mb-2" type="email" name="recepient_email" defaultValue={values.recepient_email} onBlur={handleBlur} onChange={handleChange} />
                                    <small className='text-danger fw-bold'>{errors.recepient_email && touched.recepient_email && errors.recepient_email}</small>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="cheack-label  mt-3">{t("Delivery Date (Card Valid From)")}</label>
                                    <input className="form-control mt-3" type="date" name="delivery_date" defaultValue={values.delivery_date} onBlur={handleBlur} onChange={handleChange} min={new Date().toISOString().slice(0, 10)} />
                                    <small className='text-danger fw-bold'>{errors.delivery_date && touched.delivery_date && errors.delivery_date}</small>
                                    <div id="showvalidationdate"></div>
                                </div>
                                <div className="col-lg-12 gift-area col-md-12 col-sm-12">
                                    <label className="cheack-label mt-3">{t("Message")} ({t("Optional")})</label>
                                    <textarea className="form-control mt-3 mb-2" name="message" defaultValue={values.message} onBlur={handleBlur} onChange={handleChange}></textarea>
                                    <small className='text-danger fw-bold'>{errors.message && touched.message && errors.message}</small>
                                </div>
                                <div className="col-lg-4 col-md-4 gift-btn col-sm-4 mt-3">
                                    <div className="d-grid">
                                        <input type='hidden' name='card_type' value={values.card_type} />
                                        <button type="submit" className="btn">{t("Purchase")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>


        </>
    )
}
