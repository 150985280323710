import React from 'react'
import { Link } from 'react-router-dom'

const Venue = () => {
    return (
        <>
            {/* Banner Section Start */}
            <section className="banner-n catering_venue_image">
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end">
                        <div className="col-lg-12">
                            <div className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                <h1 className="site-font color-red"> <strong>Venues</strong></h1>
                                <br />
                                <ul className="bread">
                                    <li><b><Link to="/">Home</Link></b></li>
                                    <li className="color-red"><b>Venues</b></li>
                                </ul>
                                <Link to="/catering-checkout/step-1" className="btn btn-danger">Book Venue</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner Section End */}

            {/* Let us hear from you start */}
            <section className="a-sec">
                <div className="container m-screen vn-pd">
                    <div className="row  align-items-center">
                        <div className="col-lg-6 col-md-6 wow fadeIn " data-wow-delay="0.5s">
                            <h2 className="mb-2 site-font color-red h1">
                                <strong>LET US HEAR FROM YOU</strong>
                            </h2>
                            <p className="mb-2 left-justify">
                                Everybody's mind is constantly searching for the best when a celebration is about to begin. We offer everything you need, whether you're looking for wedding venues in Switzerland or function halls in Switzerland for occasions like birthday parties, weddings, social gatherings, business events, etc. You will have a lot of options here, from the most popular banquets in Switzerland to the trendiest function hall in Switzerland. The venues have been divided into groups based on their work and appearance.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6  wow fadeIn" data-wow-delay="0.1s">
                            <div className="  position-relative overflow-hidden p-5 pe-0 d-none d-md-block a-sec3">
                                <img className="img-fluid w-100" src="https://demo.ninjashushi.ch/backend/storage/setting/1660885600_event%20main.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Let us hear from you end */}

            {/* Choose your banquet plan start */}
            <section className="vn-2 mb-5" id="venue_plans">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp vn-3" data-wow-delay="0.1s">
                        <h2 className="mb-3 col-lg-8 text-left mx-auto mb-5 wow fadeInUp color-red h1" data-wow-delay="0.5s text-center clr-red"><strong>Choose Your Banquet Plan</strong></h2>
                        <div className="col-lg-4">
                            <div className=" col-lg-4 text-left mx-auto mb-5 wow slideInRight" data-wow-delay="0.1s">
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div id="tab-1" className="tab-pane fade show p-0 active">
                            <div className="row ">
                                <div className="col-lg-4 col-md-6 wow fadeInUp mb-lg-0 mb-5 pb-lg-0 pb-5" data-wow-delay="0.2s">
                                    <div className="product-grid ct-shadow vn-card-1 ">
                                        <div className="product-image ">
                                            <Link to="/venue-detail" className="image custom-product-grid">
                                                <img className="pic-1" src="https://demo.ninjashushi.ch/backend/storage/customer/a62178a8e59f8331e1cb4f057cbf0a55.png" alt="" />
                                            </Link>
                                            <div className="price"> Palexpo</div>
                                        </div>
                                        <div className="bg-wh">
                                            <p className="vn-5"><b>Price: CHF 3500</b></p>
                                            <ul className="rating vn-6">
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                            </ul>
                                        </div>
                                        <div className="product-content bg-black">
                                            <div className="vn-7-card bg-black">
                                                <h5 className="title text-center justify-content-center color-white mb-0">
                                                    Wifi
                                                </h5>
                                                <h5 className="title text-center justify-content-center color-white mb-0">
                                                    Parking
                                                </h5>
                                                <h5 className="title text-center justify-content-center color-white mb-0">
                                                    Wheelchair Facility
                                                </h5>
                                            </div>
                                            <Link to="/venue-detail" className="btn btn-dark vn-btn mt-3 bg-red color-white"><b>More Details</b></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp bnqt-top-1 mb-lg-0 mb-5 pb-lg-0 pb-5" data-wow-delay="0.3s">
                                    <div className="product-grid ct-shadow vn-card-1 ">
                                        <div className="product-image ">
                                            <Link to="/venue-detail" className="image custom-product-grid">
                                                <img className="pic-1" src="https://demo.ninjashushi.ch/backend/storage/customer/584b98aac2dddf59ee2cf19ca4ccb75e.png" alt="" />
                                            </Link>
                                            <div className="price"> Swiss Events Agency</div>
                                        </div>
                                        <div className="bg-wh">
                                            <p className="vn-5"><b>Price: CHF 2000</b></p>
                                            <ul className="rating vn-6">
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                            </ul>
                                        </div>
                                        <div className="product-content bg-black">
                                            <div className="vn-7-card bg-black">
                                                <h5 className="title text-center justify-content-center color-white mb-0">
                                                    Wifi
                                                </h5>
                                                <h5 className="title text-center justify-content-center color-white mb-0">
                                                    Parking
                                                </h5>
                                                <h5 className="title text-center justify-content-center color-white mb-0">
                                                    Rain Protection
                                                </h5>
                                                <h5 className="title text-center justify-content-center color-white mb-0">
                                                    Unique View
                                                </h5>
                                            </div>
                                            <Link to="/venue-detail" className="btn btn-dark vn-btn mt-3 bg-red color-white"><b>More Details</b></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3 wow fadeInUp bnqt-top-2 mb-lg-0 mb-5 pb-lg-0 pb-5" data-wow-delay="0.4s">
                                    <div className="product-grid ct-shadow vn-card-1 ">
                                        <div className="product-image ">
                                            <Link to="/venue-detail" className="image custom-product-grid">
                                                <img className="pic-1" src="https://demo.ninjashushi.ch/backend/storage/customer/fea33a31df7d05a276193d32621ecbe4.png" alt="" />
                                            </Link>
                                            <div className="price"> Venue Two</div>
                                        </div>
                                        <div className="bg-wh">
                                            <p className="vn-5"><b>Price: CHF 3000</b></p>
                                            <ul className="rating vn-6">
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star"></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                            </ul>
                                        </div>
                                        <div className="product-content bg-black">
                                            <div className="vn-7-card bg-black">
                                                <h5 className="title text-center justify-content-center color-white mb-0">
                                                    Wifi
                                                </h5>
                                                <h5 className="title text-center justify-content-center color-white mb-0">
                                                    Local Ceremonies
                                                </h5>
                                            </div>
                                            <Link to="/venue-detail" className="btn btn-dark vn-btn mt-3 bg-red color-white"><b>More Details</b></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Choose your banquet plan end */}

            {/* Event Management Section Start */}
            <section className="a-sec">
                <div className="m-sec2">
                    <div className="container">
                        <div className="tab-content">
                            <div id="tab-1" className="tab-pane fade show p-0 active">
                                <div className="row g-4 d-flex text-wrap">
                                    <div className="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className=" overflow-hidden m-card vn-ev-card">
                                            <div className="position-relative overflow-hidden vn-ev-1">
                                                <h2 className="site-font color-black mb-0 h2"><b>Outstanding Event Management</b></h2>
                                                <h2 className="site-font  color-red vn-ev-2 h2"><b>And Catering Company</b></h2>
                                            </div>
                                            <div className="position-relative overflow-hidden ">
                                                <img className="img-fluid ct-shadow w-100 mb-4" src="https://demo.ninjashushi.ch/backend/storage/setting/1660885553_event%20man.jpg" alt="" />
                                                <div className="vn-ev-3 h3">
                                                    <b> Event Management </b>
                                                </div>
                                            </div>
                                            <br /><br />

                                            <div className="position-relative overflow-hidden ">
                                                <img className="img-fluid ct-shadow img-ct-w w-100 mb-4" src="https://demo.ninjashushi.ch/backend/storage/setting/1660885553_corporate%20events.jpg" alt="" />
                                                <div className="vn-ev-3 h3">
                                                    <b> Corporate Events </b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                                        <div className="  overflow-hidden m-card vn-ev-card">
                                            <div className="position-relative overflow-hidden ">
                                                <img className="img-fluid ct-shadow w-100 mb-4" src="https://demo.ninjashushi.ch/backend/storage/setting/1660885553_birthday.jpg" alt="" />
                                                <div className="vn-ev-3 h3">
                                                    <b> Birthday Parties </b>
                                                </div>
                                            </div><br /><br />
                                            <div className="position-relative overflow-hidden ">
                                                <img className="img-fluid img-ct-w w-100 ct-shadow mb-4" src="https://demo.ninjashushi.ch/backend/storage/setting/1660885553_wedding%20manag.jpg" alt="" />
                                                <div className="vn-ev-3 h3"><b>
                                                    Wedding Ceremonies
                                                </b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Event Management Section End */}

            {/* Our Partners Section Start */}
            <section className="vn-2">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp vn-3" data-wow-delay="0.1s">
                        <h2 className="mb-3 col-lg-8text-left mx-auto mb-5 wow fadeInUp color-red h1" data-wow-delay="0.5s text-center clr-red"><strong>Our Partners</strong></h2>
                        <div className="col-lg-4">
                            <div className=" col-lg-4 text-left mx-auto mb-5 wow slideInRight" data-wow-delay="0.1s">
                            </div>
                        </div>
                    </div>
                    <div className="tab-content container">
                        <div id="tab-1" className="tab-pane fade show p-0 active">
                            <div className="row g-4">
                                <div className="col-md-4 col-sm-12 wow fadeInUp text-center" data-wow-delay="0.2s">
                                    <div className=" ">
                                        <div className="product-image">
                                            <Link to="" className="image">
                                                <img className="pic-1" height="135px" width="auto" src="https://demo.ninjashushi.ch/backend/storage/customer/e4a86b0d7bf4c46d7d550a92b0b2fcae.png" alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 wow fadeInUp text-center" data-wow-delay="0.2s">
                                    <div className=" ">
                                        <div className="product-image">
                                            <Link to="" className="image">
                                                <img className="pic-1" height="135px" width="auto" src="https://demo.ninjashushi.ch/backend/storage/customer/01064f1de9dfcd9d77b14d11beefefd4.png" alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 wow fadeInUp text-center" data-wow-delay="0.2s">
                                    <div className=" ">
                                        <div className="product-image">
                                            <Link to="" className="image">
                                                <img className="pic-1" height="135px" width="auto" src="https://demo.ninjashushi.ch/backend/storage/customer/46a558d97954d0692411c861cf78ef79.png" alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our Partners Section End */}
        </>
    )
}

export default Venue