import React,{useState} from 'react'
import { useFilterContext } from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { server } from '../util/server'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Modal } from "react-bootstrap";
export const SingleItem = ({ product}) => {
    const { media_server, setCartTotalCount, project, isUserLogin } = useFilterContext();
    const { i18n, t } = useTranslation();
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [deleteFromCart, setDeleteFromCart] = useState(null);
    const handleClose = (type) => {
        if(type === '0'){
            setDeleteFromCart(null)
        }else{
            addToCart(deleteFromCart.food_id, deleteFromCart.res_id)
            
        }
        setShow(false);
    }
    const handleShow = (item) => setShow(true);
  
    const addToCart = (food_id, res_id) => {
        if (!isUserLogin) {
            sessionStorage.setItem('last_url', window.location.pathname)
            toast.error(t("Please login to add food to your cart!"));
            navigate('/login')
        } else if(sessionStorage.getItem('cart_res') && !deleteFromCart && res_id !== parseInt(sessionStorage.getItem('cart_res'))){
                setDeleteFromCart({food_id, res_id})
                handleShow()
        }else {
            let url = server + "auth/cart/add-to-cart"
            axios.post(url, { 'food_id': food_id, 'api_type': 'web', 'project': project, 'user_id': isUserLogin, 'quantity': 1 }).then(function (result) {
                if (result.data.status === 0) {
                    toast.error(t(result.data.message));
                } else {
                    toast.success(t(result.data.message));
                    setCartTotalCount(result.data.cart_count);
                    sessionStorage.setItem('cart_res', result.data.restaurant_id)
                }
                setDeleteFromCart(null)
            })
                .catch(function (error) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }
    }

    const addToWishlist = (food_id) => {
        if (!isUserLogin) {
            sessionStorage.setItem('last_url', window.location.pathname)
            toast.error(t("Please login to add food to your wishlist!"));
            navigate('/login')
        } else {
            let url = server + "auth/web/add-to-wishlist"
            axios.post(url, { 'product_id': food_id, 'api_type': 'web', 'project': project, 'user_id': isUserLogin }).then(function (result) {
                if (result.data.status === 0) {
                    toast.error('Food is already in your wishlist!');
                } else {
                    toast.success('Food is added to your wishlist!');
                }
            })
                .catch(function (error) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }
    }

    return (
        <>
        
        
       
            <div className="product-grid bg-red mx-0" style={{ borderRadius: '10px' }}>
                <div className="product-image">
                    <img className="pic-1 position-relative" src={media_server + "/" + product.product_image} alt={product['alt_' + i18n.language]} />

                    {
                        product.discount_type && <div className="price"> OFF {product.discount} {product.discount_type} </div>
                    }



                    <div className="position-absolute menu-add-to-cart" >
                        <button type="button" onClick={() => addToWishlist(product.product_id)} className="btn btn-dark d-block" style={{ fontSize: '15px', borderRadius: '4px' }}><i className="fa fa-heart"></i></button>
                        <button type="button" onClick={() => addToCart(product.product_id, product.restaurant_id)} className="btn btn-dark mt-1 menu-add-to-cart-btn"><i className="fa fa-shopping-cart"></i></button>
                    </div>

                    <div className="product-reviews">
                        <ul className="rating" style={{ float: 'right', display: 'inline', paddingRight: '20px' }}>
                            <li className={1 <= product.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                            <li className={2 <= product.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                            <li className={3 <= product.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                            <li className={4 <= product.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                            <li className={5 <= product.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                        </ul>

                    </div>

                    <div className="product-content" style={{ backgroundColor: '#ea1d25' }}>
                        <h3 className="title" style={{ fontSize: '17px', color: '#000' }}><Link to={'/food/' + product.product_id + "-" + product.slug} style={{ color: '#000' }}></Link>{product.product_name}</h3>
                        {
                            product.discount_type ?
                                <p style={{ fontSize: '13px', color: '#ffffff' }}> <span className='text-decoration-line-through' style={{ color: '#000' }}>CHF {(product.cost_price + product.profit).toFixed(2)}</span> CHF {(product.product_price).toFixed(2)}</p>
                                : <p style={{ fontSize: '13px', color: '#ffffff' }}> CHF {(product.product_price).toFixed(2)}</p>
                        }

                        <Link to={'/food/' + product.product_id + "-" + product.slug} 
                        state = { [product.food_category, product.food_subcategory]}
                        className="btn btn-dark " style={{ fontSize: '15px', borderRadius: '4px' }}>View Detail</Link>


                    </div>

                </div>



            </div>

            
        <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header>
        <Modal.Title>{t("Delete previous cart?")}</Modal.Title>
        </Modal.Header>
            <Modal.Body className='text-center'>
               <p>{t("You still have products from another category. Shall we start over with a fresh cart!")}</p>
               <button type="button" className="btn btn-dark" style={{ backgroundColor:'#ea1d25',border:"1px solid #ea1d25" }} onClick={() => handleClose('1')}>Remove</button>
               <button type="button" className="btn btn-dark ms-1" onClick={() => handleClose('0')}>Don't Remove</button>
            </Modal.Body>
        </Modal>
        </>
        
    )
}
