import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios'
import { server } from '../util/server'
import { useFilterContext } from '../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { CopyToClipboard } from "react-copy-to-clipboard";
import Spinner from '../Components/Spinner';
const Vouchers = () => {
    const [spinner, setSpinner] = useState(true);
    const [isCopied, setIsCopied] = useState(false);
    const { t } = useTranslation();
    const { project, isUserLogin } = useFilterContext();
    const [generalVouchersList, setGeneralVouchersList] = useState([])
    const [specificVouchersList, setSpecificVouchersList] = useState([])
    const navigate = useNavigate();

    const getVouchersData = async () => {
        try {
            let url = server + 'auth/user/vouchers?project=' + project + '&api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data) {
                setGeneralVouchersList(data.general_vouchers)
                setSpecificVouchersList(data.specific_vouchers)
                setSpinner(false)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getVouchersData()
        }
        else {
            navigate('/login')
        }
    }, [])

    return (
        <>
            {/* Main Banner Section Start */}
            <section className="banner-n vouchers_banner_image">
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div>
                        <div className="row">
                            <div className="col-md-auto col-md-offset-2 col-sm-8 col-sm-offset-2  col-xs-8 col-xs-offset-2" style={{ paddingLeft: "50%" }}>
                            </div>
                        </div>
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                    <h1 className="color-red">Vouchers</h1>
                                    <ul className="bread">
                                        <li><b><Link to="/">Home</Link></b></li>
                                        <li className="color-red"><b>Vouchers</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Main Banner Section End */}

            {/* Main section Start */}
            <div className="container py-5">
                <h4 className='text-center mb-4 fw-bold color-red'>VOUCHERS</h4>
                {spinner ? <Spinner /> :
                    <>
                        <div className='giftcards'>
                            <Tabs
                                defaultActiveKey="general"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="general" title="General">
                                    <div className="col-lg-12 p-2">
                                        <div className="row">
                                            {generalVouchersList.length > 0 ?
                                                (generalVouchersList.map((item1, key1) => (
                                                    <div key={key1} className=" col-lg-6 col-sm-12 col-xs-12 coupon-n">
                                                        <div className="coupon rounded mb-3 d-flex justify-content-between bg-white cpn-hght">
                                                            <div className="tengah p-3p py-3 d-flex w-100 justify-content-start bg-white cpn-center">
                                                                <div className="text-center">
                                                                    <h5 className="color-red cpn-txt fs-2 text-center"><strong>VOUCHER</strong></h5>
                                                                    <p className=" mb-0 color-black cpn-txt-p">Valid Until: <strong> {item1.coupon_valid_till}</strong> </p>
                                                                    <hr width="150" style={{ margin: '4px auto' }} />
                                                                    <p className=" mb-0 color-black cpn-txt-p">{item1.coupon_title_en} </p>
                                                                    <Link to="#" target="_blank" className="btn btn-sm btn-success btn-block cpn-btn-font mt-3" data-bs-target={"#general_voucher_" + item1.coupon_id} data-bs-toggle="modal" data-bs-dismiss="modal">
                                                                        Show Code
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="kanan bg-red">
                                                                <div className="info d-flex align-items-center text-center" style={{ height: '100%' }}>
                                                                    <div className="text-vertical color-white" style={{ margin: '0px auto' }}>
                                                                        <h2 style={{ margin: '0px' }}>{item1.coupon_amount} {item1.coupon_amount_type}</h2>
                                                                        <p style={{ margin: '0px' }}>VOUCHER</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal fade" id={"general_voucher_" + item1.coupon_id} aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                                                            <div className="modal-dialog modal-dialog-scrollable">
                                                                <div className="modal-content">
                                                                    <div className="modal-body">
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ float: 'right' }}></button>
                                                                        <p style={{ margin: '0px' }} className='color-green mb-2'>VOUCHER CODE</p>
                                                                        <div className='row'>
                                                                            <div className='col-12 text-center' style={{ backgroundColor: '#ea1d25', padding: '14px', borderRadius: '6px' }}>
                                                                                <h4 className='color-white mt-1 mb-1' style={{ wordWrap: 'break-word' }}>{item1.coupon_code}</h4>
                                                                            </div>
                                                                            <div className='col-12 text-center'>
                                                                                <CopyToClipboard
                                                                                    text={item1.coupon_code}
                                                                                    onCopy={() => {
                                                                                        setIsCopied(true);
                                                                                        setTimeout(() => {
                                                                                            setIsCopied(false);
                                                                                        }, 1500);
                                                                                    }}
                                                                                >
                                                                                    <button className='btn mt-2' style={{ backgroundColor: '#E8E8E8' }}>
                                                                                        <p className='mt-2 mb-1'>Copy to Clipboard <span><i className="far fa-copy fs-5 ms-3 color-green"></i></span></p>
                                                                                    </button>
                                                                                </CopyToClipboard>
                                                                                {isCopied ? (
                                                                                    <p className="color-green">Coupon Code copied to clipboard</p>
                                                                                ) : null}
                                                                                <p className=" mb-0 color-black cpn-txt-p mt-4">Valid Until: <strong> {item1.coupon_valid_till}</strong> </p>
                                                                            </div>
                                                                        </div>
                                                                        <hr className='m-0' />
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <p className='p-4 pt-3 pb-2 justify-content-center text-center' style={{ fontSize: '15px' }}>{item1.coupon_purpose_en}</p>
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <h5 className='m-0 color-red'>CONDITIONS</h5>
                                                                                <ul>
                                                                                    <li>{item1.coupon_terms_en}</li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className='col-12 mt-3 text-center mb-0'>
                                                                                <p style={{ fontSize: '14px' }} className='mb-0'>Do you have a query? <strong>
                                                                                    <Link to="/contact" className='color-red'>Contact Us</Link>
                                                                                </strong> </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))) : (
                                                    <p className='text-center'>No Vouchers Found!</p>
                                                )
                                            }
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="foryou" title="For You">
                                    <div className="col-lg-12 p-2">
                                        <div className="row">
                                            {specificVouchersList.length > 0 ?
                                                (specificVouchersList.map((item2, key2) => (
                                                    <div className=" col-lg-6 col-sm-12 col-xs-12 coupon-n">
                                                        <div className="coupon rounded mb-3 d-flex justify-content-between bg-white cpn-hght">
                                                            <div className="tengah p-3p py-3 d-flex w-100 justify-content-start bg-white cpn-center">
                                                                <div className="text-center">
                                                                    <h5 className="color-red cpn-txt fs-2 text-center"><strong>VOUCHER</strong></h5>
                                                                    <p className=" mb-0 color-black cpn-txt-p">Valid Until: <strong> {item2.coupon_valid_till}</strong> </p>
                                                                    <hr width="150" style={{ margin: '4px auto' }} />
                                                                    <p className=" mb-0 color-black cpn-txt-p">{item2.coupon_title_en}</p>
                                                                    <Link to="#" target="_blank" className="btn btn-sm btn-success btn-block cpn-btn-font mt-3" data-bs-target={"#specific_voucher_" + item2.coupon_id} data-bs-toggle="modal" data-bs-dismiss="modal">
                                                                        Show Code
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="kanan bg-red">
                                                                <div className="info d-flex align-items-center text-center" style={{ height: '100%' }}>
                                                                    <div className="text-vertical color-white" style={{ margin: '0px auto' }}>
                                                                        <h2 style={{ margin: '0px' }}>{item2.coupon_amount} {item2.coupon_amount_type}</h2>
                                                                        <p style={{ margin: '0px' }}>VOUCHER</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal fade" id={"specific_voucher_" + item2.coupon_id} aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                                                            <div className="modal-dialog modal-dialog-scrollable">
                                                                <div className="modal-content">
                                                                    <div className="modal-body">
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ float: 'right' }}></button>
                                                                        <p style={{ margin: '0px' }} className='color-green mb-2'>VOUCHER CODE</p>
                                                                        <div className='row'>
                                                                            <div className='col-12 text-center' style={{ backgroundColor: '#ea1d25', padding: '14px', borderRadius: '6px' }}>
                                                                                <h4 className='color-white mt-1 mb-1' style={{ wordWrap: 'break-word' }}>{item2.coupon_code}</h4>
                                                                            </div>
                                                                            <div className='col-12 text-center'>
                                                                                <CopyToClipboard
                                                                                    text={item2.coupon_code}
                                                                                    onCopy={() => {
                                                                                        setIsCopied(true);
                                                                                        setTimeout(() => {
                                                                                            setIsCopied(false);
                                                                                        }, 1500);
                                                                                    }}
                                                                                >
                                                                                    <button className='btn mt-2' style={{ backgroundColor: '#E8E8E8' }}>
                                                                                        <p className='mt-2 mb-1'>Copy to Clipboard <span><i className="far fa-copy fs-5 ms-3 color-green"></i></span></p>
                                                                                    </button>
                                                                                </CopyToClipboard>
                                                                                {isCopied ? (
                                                                                    <p className="color-green">Coupon Code copied to clipboard</p>
                                                                                ) : null}
                                                                                <p className=" mb-0 color-black cpn-txt-p mt-4">Valid Until: <strong> {item2.coupon_valid_till}</strong> </p>
                                                                            </div>
                                                                        </div>
                                                                        <hr className='m-0' />
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <p className='p-4 pt-3 pb-2 justify-content-center text-center' style={{ fontSize: '15px' }}>{item2.coupon_purpose_en}</p>
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <h5 className='m-0 color-red'>CONDITIONS</h5>
                                                                                <ul>
                                                                                    <li>{item2.coupon_terms_en}</li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className='col-12 mt-3 text-center mb-0'>
                                                                                <p style={{ fontSize: '14px' }} className='mb-0'>Do you have a query? <strong>
                                                                                    <Link to="/contact" className='color-red'>Contact Us</Link>
                                                                                </strong> </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))) : (
                                                    <p className='text-center'>No Vouchers Found!</p>
                                                )
                                            }
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>

                            {/* Main section End */}
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default Vouchers