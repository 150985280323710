import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import { checkoutSchema } from '../Schema';
import DeliveryAddress from '../Components/DeliveryAddress';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { server } from '../util/server';
import axios from 'axios';
import { useFilterContext } from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import Spinner from '../Components/Spinner';
const Checkout = () => {
    const [spinner, setSpinner] = useState(true);
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([])
    const [addresses, setAddresses] = useState([])
    const [cartTotal, setCartTotal] = useState(null)
    const [deliveryOption, setDeliveryOption] = useState(0)
    const [deliveryCharges, setDeliveryCharges] = useState(null)
    const [customerInfo, setCustomerInfo] = useState(null)
    const { setCartTotalCount, project, isUserLogin, coupon, setCoupon } = useFilterContext();
    const navigate = useNavigate();

    const getData = async () => {
        try {
            let url = server + 'auth/cart/cart-items?project=' + project + '&page_link=cart&api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                // updatemetas(null,0,data.meta)
                // updatePageBanner(data.page_banner)
                if (data.data.length === 0) {
                    navigate('/menu')
                }
                setData(data.data)
                setCartTotal(parseFloat(data.cart_total))
                setCartTotalCount(data.cart_count);
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const getAddresses = async () => {
        try {
            let url = server + 'auth/user/checkout-delivery?project=' + project + '&api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setAddresses(data.data)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const getCustomerInfo = async () => {
        try {
            let url = server + 'auth/user/get-user-info?api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setCustomerInfo(data.data)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getData()
            getAddresses()
            getCustomerInfo()
        } else {
            navigate('/cart')
        }

    }, [])

    const get_charges = async (address_id, area_code) => {
        setDeliveryOption(address_id)
        try {
            let url = server + 'auth/user/delivery-charges?cart_total=' + cartTotal + '&restaurant_id=' + sessionStorage.getItem('cart_res') + '&area_code=' + area_code + '&project=' + project + '&api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setDeliveryCharges(parseFloat(data.charges))

            } else {
                toast.error(data.message)
                setDeliveryCharges(null)
            }
        } catch (error) {
            setDeliveryCharges(null)
            return toast.error(t("Unexpected error occurred!"))
        }

    }


    const submitData = async (query, actions) => {
        try {
            query['user_id'] = isUserLogin;
            query['project'] = project;
            query['delivery_address_id'] = deliveryOption;
            query['delivery_charges'] = deliveryCharges;
            query['coupon_code'] = coupon ? coupon.coupon_code : null;
            query['restaurant_id'] = sessionStorage.getItem('cart_res');
            query['api_type'] = 'web';
            let res = await axios.post(server + "auth/user/save-checkout-delivery-data", query);
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }

                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(item);
                        })

                    })
                }
            } else {
                actions.resetForm();
                sessionStorage.removeItem('cart_res')
                setCartTotalCount(0);
                setCoupon(null)
                navigate('/thankyou/' + data.order_reference)
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }

    }

    const initialValues = {
        firstName: customerInfo ? customerInfo.firstName : '',
        lastName: customerInfo ? customerInfo.lastName : '',
        phone_number: customerInfo ? customerInfo.phone : '',
        email: customerInfo ? customerInfo.email : '',
        order_note: '',
        payment_method: '',
        terms_conditons: ''
    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
        validationSchema: checkoutSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            if (deliveryOption === 0) {
                document.getElementById('cus-msg').innerText = "Please select delivery address";
            } else {
                document.getElementById('cus-msg').innerText = "";
                submitData(values, actions)
            }




        }
    });

    return (
        <>
            {spinner ? <Spinner /> :
                <>
                    <form className="login-form offset-1" onSubmit={handleSubmit} >
                        <div className="container container-log col-lg-12 my-5">
                            <div className="row">
                                <div className="container-login col-lg-7">
                                    <h3>Personal Information</h3>
                                    <div className="row mt-3">
                                        <div className="col-md-6 mb-3">
                                            <label>First Name</label>
                                            <input className="form-control" type="text" name="firstName" defaultValue={values.firstName} onBlur={handleBlur} onChange={handleChange} />
                                            <span id='msg'>{errors.firstName && touched.firstName && errors.firstName}</span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Last Name</label>
                                            <input className="form-control" type="text" name="lastName" defaultValue={values.lastName} onBlur={handleBlur} onChange={handleChange} />
                                            <span id='msg'>{errors.lastName && touched.lastName && errors.lastName}</span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Phone Number</label>
                                            <input className="form-control" type="text" name="phone_number" id="contactphone" defaultValue={values.phone_number} onBlur={handleBlur} onChange={handleChange} />
                                            <span id='msg'>{errors.phone_number && touched.phone_number && errors.phone_number}</span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="cheack-label">Email</label>
                                            <input className="form-control" defaultValue={values.email} onBlur={handleBlur} onChange={handleChange} name='email' />
                                            <span id='msg'>{errors.email && touched.email && errors.email}</span>
                                        </div>
                                        <h5>CUSTOMIZATION</h5>
                                        <div className="col-lg-12 mb-3">
                                            <label className="cheack-label">Order Note</label>
                                            <textarea placeholder="Anything you want to add in your order or remove" className="form-control" rows="5" defaultValue={values.order_note} onBlur={handleBlur} onChange={handleChange} name="order_note"></textarea>
                                            <span id='msg'>{errors.order_note && touched.order_note && errors.order_note}</span>
                                        </div>
                                        <h5>DELIVERY ADDRESS</h5>
                                        {/* Button trigger modal */}
                                        <div className="col-12">
                                            <a className="float-end color-black" data-bs-toggle="modal" href="#exampleModal">
                                                <u><b>Add New Address</b></u>
                                            </a>
                                        </div>
                                        <div className="row radio-with-Icon mb-4">
                                            {
                                                addresses.length > 0 &&
                                                addresses.map(address => (
                                                    <div className="col-md-6">
                                                        <p className="radioOption-Item">
                                                            <input type="radio" name="deliveryOption" id={"for_" + address.address_id} value={deliveryOption} checked={address.address_id === deliveryOption} onChange={() => get_charges(address.address_id, address.post_code)} className="ng-valid ng-dirty ng-touched ng-empty" />
                                                            <label htmlFor={"for_" + address.address_id}>
                                                                {address.street + " " + address.housenumber + ", " + address.post_code + " " + address.town + ", Switzerland"}
                                                            </label>
                                                        </p>
                                                    </div>
                                                ))
                                            }

                                            <span id='cus-msg' style={{ color: 'red' }}></span>
                                        </div>
                                        <h5>PAYMENT METHOD</h5>
                                        <div className="col-lg-8 check-radio">
                                            <label htmlFor="ff-option" className="l-radio form-check-inline">
                                                <input type="radio" id="ff-option" name="payment_method" defaultValue='1' onBlur={handleBlur} onChange={handleChange} />
                                                <span>Postfinance</span>
                                            </label>
                                            <label htmlFor="tt-option" className="l-radio form-check-inline">
                                                <input type="radio" id="tt-option" name="payment_method" defaultValue="0" onBlur={handleBlur} onChange={handleChange} />
                                                <span>Cash On Delivery</span>
                                            </label>
                                        </div>
                                        <span id='msg'>{errors.payment_method && touched.payment_method && errors.payment_method}</span>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="check-main py-5 px-md-5 px-2">
                                        <h3>Your cart</h3>
                                        <hr className="check-hr" />
                                        <div className="row mb-1">
                                            <div className="col-6">
                                                <h6 className="mb-0">Product</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <h6 className="mb-0">Subtotal</h6>
                                            </div>
                                        </div>
                                        {
                                            data.length > 0 && data.map(item => (
                                                <>
                                                    <hr className="check-hr" />
                                                    <div className="row mb-1">
                                                        <div className="col-6">
                                                            <p className="mb-0">{item.product_name} X {item.quantity}</p>
                                                            {
                                                                item.allergens && <p className="mb-0"><b>Allergens: </b>
                                                                    {
                                                                        JSON.parse(item.allergens).map((allergen, key) => (
                                                                            key === 0 ? allergen['name_' + i18n.language] : ', ' + allergen['name_' + i18n.language]
                                                                        ))
                                                                    }

                                                                </p>
                                                            }
                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <p className="mb-0">CHF {(item.price * item.quantity).toFixed(2)}</p>
                                                        </div>
                                                    </div>

                                                </>
                                            ))
                                        }

                                        <hr className="mt-2 check-hr" />
                                        <div className="row">
                                            <div className="col-6">
                                                <h6 className="mb-0">Cart Total</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <p className="mb-0"> {cartTotal ? "CHF " + cartTotal.toFixed(2)
                                                    : 0.00}</p>
                                            </div>
                                        </div>
                                        {
                                            coupon &&
                                            <>
                                                <hr className="check-hr" />
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h6 className="mb-0">Discount ({coupon.coupon_code})</h6>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <h6 className="mb-0" id="delivery_charges">CHF {coupon.coupon_discount}</h6>
                                                    </div>
                                                </div>

                                            </>
                                        }
                                        <hr className="check-hr" />
                                        <div className="row">
                                            <div className="col-6">
                                                <h6 className="mb-0">Delivery Charges</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <h6 className="mb-0" id="delivery_charges">CHF {deliveryCharges ? deliveryCharges.toFixed(2) : 0.00}</h6>
                                            </div>
                                        </div>

                                        <hr className="check-hr" />
                                        <div className="row">
                                            <div className="col-6">
                                                <h6 className="mb-0">Total</h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <h6 className="mb-0" id="order_total">CHF
                                                    {
                                                        !coupon ?
                                                            cartTotal ?
                                                                deliveryCharges ? (cartTotal + deliveryCharges).toFixed(2) : cartTotal
                                                                : 0.00
                                                            : ''
                                                    }

                                                    {
                                                        coupon ?
                                                            deliveryCharges ? (parseFloat(coupon.discounted_cart_total) + deliveryCharges).toFixed(2)
                                                                : coupon.discounted_cart_total
                                                            : ''

                                                    }
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                                            </div>
                                            <div className="form-check ms-2">
                                                <input className="form-check-input" type="checkbox" defaultValue="1" name="terms_conditons" onBlur={handleBlur} onChange={handleChange} id="flexCheckChecked" />
                                                <span id='msg'>{errors.terms_conditons && touched.terms_conditons && errors.terms_conditons}</span>
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                    By clicking this, you agree to our Terms and Conditons
                                                </label>
                                            </div>
                                            <div className="mt-2">
                                                <input type="submit" defaultValue="Proceed order" className="btn rounded-0 py-2 px-4 color-white bg-red" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </>
            }

            {/* Modal */}
            <DeliveryAddress getAddresses={getAddresses} />
        </>
    )
}

export default Checkout