import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
const Catering = () => {
    const CateringTestimonialsOptions = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 1500,
        responsive: {
            0: {
                margin: 10,
                items: 1,
            },
            576: {
                margin: 50,
                items: 1,
            },
            768: {
                margin: 10,
                items: 2,
            },
            992: {
                margin: 30,
                items: 3,
            },
            1200: {
                margin: 40,
                items: 3,
            },
        },
    };
    return (
        <>
            {/* Banner Section Start */}
            <section className="banner-n catering_banner_image">
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end bbbbbbb">
                        <div className="col-lg-12">
                            <div className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                <h1 className="color-red">Catering</h1>
                                <ul className="bread">
                                    <li><b><Link to="/">Home</Link></b></li>
                                    <li className="color-red"><b>Catering</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner Section End */}

            {/* Catering 1st section Start */}
            <section className="bg-ct-1">
                <div className="m-sec2">
                    <div className="container">
                        <div className="row g-0 gx-5 align-items-end ">
                            <div className="col-lg-12">
                                <div className="col-lg-10 text-center mx-auto mb-5 text-center">
                                    <h2 className="wow slideInLeft fnt-h" data-wow-delay="0.1s">Welcome To Our Best</h2>
                                    <h2 className="mb-3 site-font color-red about-h wow slideInRight h1" data-wow-delay="0.1s"><strong>SERVICES</strong></h2>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content">
                            <div id="tab-1" className="tab-pane fade show p-0 active">
                                <div className="row g-4 d-flex text-wrap">
                                    <div className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="property-item  overflow-hidden m-card">
                                            <div className="position-relative overflow-hidden">
                                                <Link to="/events">
                                                    <img className="img-fluid" src="https://demo.ninjashushi.ch/backend/storage/setting/1670400942_event%20opt%202.png" alt="" style={{ width: "100%" }} />
                                                </Link>
                                                <div className="site-font position-absolute start-0 bottom-0 mx-0 pt-0 px-0 menu-card">
                                                    <h4 className="color-white site-font m-card-h"> Events</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="property-item  overflow-hidden m-card">
                                            <div className="position-relative overflow-hidden">
                                                <Link to="/catering-menu">
                                                    <img className="img-fluid" src="https://demo.ninjashushi.ch/backend/storage/setting/1670400921_food%20and%20drink%20.png" alt="" style={{ width: "100%" }} />
                                                </Link>
                                                <div className="site-font position-absolute start-0 bottom-0 mx-0 pt-0 px-0 menu-card">
                                                    <h4 className="color-white site-font m-card-h">Food & Drinks</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="property-item  overflow-hidden m-card">
                                            <div className="position-relative overflow-hidden">
                                                <Link to="/venue">
                                                    <img className="img-fluid" src="https://demo.ninjashushi.ch/backend/storage/setting/1670400921_venue%20.png" alt="" style={{ width: "100%" }} />
                                                </Link>
                                                <div className="site-font position-absolute start-0 bottom-0 mx-0 pt-0 px-0 menu-card">
                                                    <h4 className="color-white site-font m-card-h"> Venues</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Catering 1st Section End */}

            {/* About Section Start */}
            <section className="bg-ct-2">
                <div className="container m-screen ct-2-2 ">
                    <div className="container m-screen">
                        <div className="row  align-items-center">
                            <div className="col-lg-6 wow fadeIn mb-lg-0 mb-3" data-wow-delay="0.5s">
                                <h2 className=" site-font color-red about-h h1">
                                    <strong>About Us</strong>
                                </h2>
                                <p className="mb-4 left-justify">From feeding hundreds of guests to providing table-side service for a small dinner party with a few of your closest friends, and everything in between. we are more
                                    than capable of catering to a wide range of events and group sizes. In addition to
                                    offering excellent food and service at affordable pricing, our full-service catering
                                    company can also plan events at the location of your choice and also provide
                                    different locations to make your event unforgettable. If you're prepared to get going,
                                    get in touch with our team to reserve our catering services in Switzerland. We are
                                    offering</p>
                                <p>
                                    <i className="fa fa-circle  me-3 color-red"></i>
                                    Swiss’s Best Catering Services
                                </p>
                                <p>
                                    <i className="fa fa-circle ry me-3 color-red"></i>
                                    Banquets plans
                                </p>
                                <p>
                                    <i className="fa fa-circle ry me-3 color-red"></i>
                                    Variety of food menu
                                </p>
                            </div>
                            <div className="col-lg-6 wow fadeIn d-none d-lg-block mb-3" data-wow-delay="0.1s">
                                <div className=" ">
                                    <img className="img-fluid w-100" alt="" src="https://demo.ninjashushi.ch/backend/storage/setting/1670565933_ABOUT%20US%202.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About Section End */}

            {/* Features Section Start */}
            <section className="color-white ct-3 ">
                <h2 className="text-center h1">
                    Planning An Event?
                </h2>
                <div className="row">
                    <div className="col-md-4 col-sm-12 mid-text-2 mid-txt-align">
                        <h4 className="color-red">
                            WEDDING CATERING
                        </h4>
                        <p className="">
                            Our catering services are of different types from different kitchens at remote locations. Wedding catering is one of the types from them.
                        </p>
                    </div>
                    <div className="col-md-4 "></div>
                    <div className="col-md-4 col-sm-12 mid-text-2 mid-txt-align">
                        <h4 className="color-red">
                            CONCESSION CATERING
                        </h4>
                        <p className="">
                            All about your sporting events, live concerts, or any seasonal event we are dedicated to providing you all our best services in catering.
                        </p>
                    </div>
                    <div className="col-md-4 col-sm-12 mid-text mid-txt-align">
                        <h4 className="color-red">
                            CORPORATE CATERING
                        </h4>
                        <p className="">
                            WHAT? If you are running an organization? Or you are an employee of an organization? Are you looking forward to catering services for your business parties and business meetings?
                        </p>
                    </div>
                    <div className="col-md-4  text-center d-none d-md-block">
                        <img src="https://demo.ninjashushi.ch/backend/storage/setting/1672208591_catering%20-03.png" className="img-mid" alt="" />
                    </div>
                    <div className="col-md-4 col-sm-12 mid-text mid-txt-align">
                        <h4 className="color-red">
                            SOCIAL EVENTS CATERING
                        </h4>
                        <p className="">
                            All social events are really valuable in our lives. These events may be grand openings, birthday parties, house warming parties, retirement parties, or baby & bridal showers.
                        </p>
                    </div>
                    <div className="col-md-4 col-sm-12 mid-text-2 mid-txt-align">
                        <h4 className="color-red"> FESTIVALS</h4>
                        <p className="">
                            The popularity of Switzerland depends on crystal-clear medieval towns beautiful alpine mountains, and unforgettable natural scenery.
                        </p>
                    </div>
                    <div className="col-md-4 "></div>
                    <div className="col-md-4 col-sm-12 mid-text-2 mid-txt-align">
                        <h4 className="color-red">
                            PRIVATE PARTIES
                        </h4>
                        <p className="">
                            Do you want to take your parties to an extreme level and serve yourself with a unique food experience from the fabulous venue or comfort of your own home?
                        </p>
                    </div>
                    <div className="col-md-4 "></div>
                    <div className="col-md-4 col-sm-12 mid-text-2 mid-txt-align" style={{ paddingLeft: "2%", paddingRight: "2%" }}>
                        <h4 className="color-red">
                            FUNERAL
                        </h4>
                        <p className="">
                            Although funerals & memorials of loved ones are the time of sorrow, catering is not the only and first thing that comes to mind.
                        </p>
                    </div>
                    <div className="col-md-4 "></div>
                </div>
            </section>
            {/* Features Section End */}

            {/* Catering Info Section Start  */}
            <div className="container-fluid ct-5-3 bg-ct-2">
                <div className="ct-5-3">
                    <div className="container ">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp ct-5-1  move-down-2" data-wow-delay="0.1s">
                                <div className=" position-relative overflow-hidden pe-0 img-border ct-shadow">
                                    <img className=" img-fluid w-100" data-aos="zoom-in" height="250px" width="400px" src="https://demo.ninjashushi.ch/backend/storage/setting/1660802252_girl.png" alt='' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 wow fadeIn move-up" data-wow-delay="0.5s">
                                <h2 className=" color-black h1">
                                    <strong>Meet Our</strong>
                                </h2>
                                <h2 className="catering-red h1 color-red">DELICIOUS CATERING MENU</h2>
                                <p className="mb-lg-4 mb-2 left-justify">Higly convenient for buffet lunches on any occasion cost per serving.</p>
                                <p className="mb-lg-4 mb-2 left-justify">We adore providing catering for your unique event.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ct-5-3">
                    <div className="container ">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12 wow fadeIn" data-wow-delay="0.5s">
                                <h2 className="color-black h1">
                                    <strong>Explore Our</strong>
                                </h2>
                                <h2 className="catering-red h1 color-red">BEST VENUE SERVICES</h2>
                                <p className="mb-lg-4 mb-2 left-justify">A good location for good times.</p>
                                <p className="mb-lg-4 mb-2 left-justify">Adding a new wing to your event.</p>
                                <p className="mb-lg-4 mb-2 left-justify">A sophisticte SERVICE for all occasions.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.2s" style={{ marginBottom: "40px" }}>
                                <div className=" position-relative overflow-hidden pe-0 img-border ct-shadow">
                                    <img className=" img-fluid w-100" data-aos="zoom-in" height="250px" width="400px" src="https://demo.ninjashushi.ch/backend/storage/setting/1660802441_blue.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Catering Info section End */}

            {/* Vision & Mission Section Start */}
            <div className="ct-5-3">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className=" rounded overflow-hidden" style={{ backgroundColor: "#" }}>
                                <div className="text-center p-4 mt-3">
                                    <img src="https://demo.ninjashushi.ch/backend/storage/setting/1660802900_mission-n.png" alt="" style={{ width: "50%", height: "50%" }} />
                                    <h3 className="fw-bold mb-0 color-red">
                                        Our Mission
                                    </h3>
                                    <p> To be a market leader in providing wholesome, mouthwatering cuisine and first-rate customer service by providing a flexible, multi-faceted service with consistency and presentation that will make a lasting impression</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className=" rounded overflow-hidden" style={{ backgroundColor: "#" }}>
                                <div className="text-center p-4 mt-3">
                                    <img src="https://demo.ninjashushi.ch/backend/storage/setting/1660802900_vision-n2.png" alt="" style={{ width: "50%", height: "50%" }} />
                                    <h3 className="fw-bold mb-0 color-red">
                                        Our Vision
                                    </h3>
                                    <br />
                                    <p>
                                        Jetzt Eat delivers a warm and welcoming experience that brings our customers' visions to life and forges lasting memories by offering flawless service, high-quality, delectable dishes, and a committed workforce
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Vision & Mission Section End */}

            {/* Testimonials Section Start */}
            <section className="test-sec-bg">
                <div className="container t-top test-sec">
                    <div className="text-center mx-auto  wow fadeInUp col-lg-12 test-sec2" data-wow-delay="0.1s">
                        <h2 className="mb-3 color-white site-font about-h h1">
                            <strong>What Our Customers Say</strong>
                        </h2>
                    </div>
                    <div id="demo">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <OwlCarousel id="testimonial-slider" className='owl-carousel testimonial-carousel mt-4 pb-4 ' {...CateringTestimonialsOptions}>
                                        <div className="testimonial">
                                            <span className="icon fa fa-quote-left color-black"></span>
                                            <ul className="rating d-block mb-1">
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                            </ul>
                                            <p className="description color-black text-center">
                                                Perfect & well managed
                                                <br />
                                            </p>
                                            <div className="testimonial-content">
                                                <div className="pic">
                                                    <img src="https://demo.ninjashushi.ch/backend/front/images/profile-14.png" alt="" />
                                                </div>
                                                <h3 className="title">Faiza</h3>
                                                <span className="post">April 03, 2023</span>
                                            </div>
                                        </div>
                                        <div className="testimonial">
                                            <span className="icon fa fa-quote-left color-black"></span>
                                            <ul className="rating d-block mb-1">
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                            </ul>
                                            <p className="description color-black text-center">
                                                Perfect & well managed
                                                <br />
                                            </p>
                                            <div className="testimonial-content">
                                                <div className="pic">
                                                    <img src="https://demo.ninjashushi.ch/backend/front/images/profile-14.png" alt="" />
                                                </div>
                                                <h3 className="title">Faiza</h3>
                                                <span className="post">April 03, 2023</span>
                                            </div>
                                        </div>
                                        <div className="testimonial">
                                            <span className="icon fa fa-quote-left color-black"></span>
                                            <ul className="rating d-block mb-1">
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                            </ul>
                                            <p className="description color-black text-center">
                                                Perfect & well managed
                                                <br />
                                            </p>
                                            <div className="testimonial-content">
                                                <div className="pic">
                                                    <img src="https://demo.ninjashushi.ch/backend/front/images/profile-14.png" alt="" />
                                                </div>
                                                <h3 className="title">Faiza</h3>
                                                <span className="post">April 03, 2023</span>
                                            </div>
                                        </div>
                                        <div className="testimonial">
                                            <span className="icon fa fa-quote-left color-black"></span>
                                            <ul className="rating d-block mb-1">
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-red" ></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                                <li className="fas fa-star color-lightgrey"></li>
                                            </ul>
                                            <p className="description color-black text-center">
                                                Perfect & well managed
                                                <br />
                                            </p>
                                            <div className="testimonial-content">
                                                <div className="pic">
                                                    <img src="https://demo.ninjashushi.ch/backend/front/images/profile-14.png" alt="" />
                                                </div>
                                                <h3 className="title">Faiza</h3>
                                                <span className="post">April 03, 2023</span>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <Link to="/reviews/catering" className="btn btn-dark m-auto mt-4 test-btn-f ">
                                See All Reviews
                                <i className="fa fa-arrow-right color-red ms-2"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Testimonials section end */}

            {/* Map Section Start */}
            <section id="contact" style={{ backgroundColor: "white", paddingTop: "8px" }}>
                <div className="map">
                    <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2699.3909765725843!2d9.366487914934776!3d47.42381917917297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479b1e4a752a9da9%3A0x7cc78e603172ff06!2sRosenbergstrasse%2046%2C%209000%20St.%20Gallen%2C%20Switzerland!5e0!3m2!1sen!2s!4v1653973187669!5m2!1sen!2s" width="100%" height="450" style={{ border: "0px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
            {/* Map Section End */}
        </>
    )
}

export default Catering