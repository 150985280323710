import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../context/filterContext';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { useTranslation } from 'react-i18next';
const Referrals = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isUserLogin, media_server } = useFilterContext();
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (!isUserLogin) {
            navigate('/login')
        }
    }, [])

    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        <h3>{t("Refer a Friend")}</h3>
                        <hr />
                        <p className="text-center text-uppercase color-red" style={{ fontSize: "1.5rem" }}>
                            <b>{t("Your reward points will automatically convert into CHF 20 after reaching limit of 20000 every time.")}</b>
                        </p>

                        {/* Get Points sec Start */}
                        <div className="bg-light rounded r-3">
                            <div className="bg-white rounded r-4 ref-a1">
                                <div className="row rg-5 align-items-center">
                                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                        <img className="img-fluid rounded w-100" src="/assets/front/images/referal.png" alt="Referral" />
                                    </div>
                                    <div className="col-lg-6 col-md-12 wow fadeIn ref1 text-center" data-wow-delay="0.5s">
                                        <div className="rg-5">
                                            <h1 className=" color-red ref-a6-tx1 ">{t("GET POINTS")}</h1>
                                            <h4 className=" site-font color-green ref-a6-tx2">{t("For every new user")} <b> {t("You Refer!")}</b></h4>
                                            <p className="color-black ref-a6-tx3">
                                                {t("Share your referral link and")} <b>{t("Earn Points")}</b>!
                                            </p>
                                        </div>
                                        <div className="row d-flex ">
                                            <div className="col-sm-12 ref2">
                                                <div className="card p-3 px-3 bg-green mb-2">
                                                    <div className=" justify-content-between align-items-center text-center">
                                                        <div>
                                                            <span className=" ">
                                                                <h6 className="color-white ref-a2 ref-a6-tx4" id="demo">
                                                                    {media_server + '/register?referral_token=' + sessionStorage.getItem('referral')}
                                                                </h6>
                                                            </span>
                                                        </div>
                                                        <CopyToClipboard
                                                            text={media_server + '/register?referral_token=' + sessionStorage.getItem('referral')}
                                                            onCopy={() => {
                                                                setIsCopied(true);
                                                                setTimeout(() => {
                                                                    setIsCopied(false);
                                                                }, 1500);
                                                            }}
                                                        >
                                                            <button className='btn btn-sm btn-dark copy-button ref-a3 ref-a6-tx4'>
                                                            {t("Copy Your Referral Link")}
                                                            </button>
                                                        </CopyToClipboard>
                                                        {isCopied ? (
                                                            <p className="color-white">{t("Referral link is copied to clipboard")}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <FacebookShareButton url={media_server + '/register?referral_token=' + sessionStorage.getItem('referral')} style={{ marginLeft: "6px", marginTop: "8px" }}>
                                                        <FacebookIcon size={32} round={true} />
                                                    </FacebookShareButton>
                                                    <WhatsappShareButton url={media_server + '/register?referral_token=' + sessionStorage.getItem('referral')} style={{ marginLeft: "6px", marginTop: "8px" }}>
                                                        <WhatsappIcon size={32} round={true} />
                                                    </WhatsappShareButton>
                                                    <TwitterShareButton url={media_server + '/register?referral_token=' + sessionStorage.getItem('referral')} style={{ marginLeft: "6px", marginTop: "8px" }}>
                                                        <TwitterIcon size={32} round={true} />
                                                    </TwitterShareButton>
                                                    <FacebookMessengerShareButton url={media_server + '/register?referral_token=' + sessionStorage.getItem('referral')} style={{ marginLeft: "6px", marginTop: "8px" }} >
                                                        <FacebookMessengerIcon size={32} round={true} />
                                                    </FacebookMessengerShareButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Get Points sec End */}

                        {/* Start Referral steps section */}
                        <div className="fdiv ref-a6" style={{ backgroundColor: "#f7f7f7" }}>
                            <div className="container contact-main">
                                <div className="text-center mx-auto mb-4">
                                    <h2 className="mb-3 color-red ref-a6-tx1">{t("Referral Steps")}</h2>
                                </div>
                                <div className="row g-4">
                                    <div className="col-lg-4 col-md-6 mt-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" id="icon-divs">
                                        <div className="">
                                            <div className="text-center">
                                                <i className="fa fa-user-plus color-green ref-a5-icon"></i>
                                                <h4 className="ref3-t1 color-black">{t("Refer a Friend")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" id="icon-divs">
                                        <div className=" ">
                                            <div className="text-center">
                                                <i className="fa fa-sign-in-alt color-green ref-a5-icon"></i>
                                                <h4 className="ref3-t1 color-black">{t("They Signup on Jetzt eat")}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 mt-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" id="icon-divs" style={{ background: "none" }}>
                                        <div className=" ">
                                            <div className="text-center">
                                                <i className="fa fa-money-check  color-green ref-a5-icon"></i>
                                                <h4 className="ref3-t1 color-black">{t("You both get paid")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Referral steps section */}

                        {/* Start Invite Text section */}
                        <section className="about-section section-padding ref-a7" style={{ backgroundImage: `url('/assets/front/images/contact/contact-us-page-bg-image-with-black-overlay-and-bg.png')`, height: "auto", paddingTop: "5%", paddingBottom: "5%" }}>
                            <div className="container ">
                                <div className="row">
                                    <div className="col-lg- 12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="row wow fadeIn" data-wow-delay="0.1s">
                                            <div className="col-lg- 12 col-md-12 col-sm-12 col-xs-12  contact-main3">
                                                <h2 className=" text-center site-font color-red ref3-t1">{t("INVITE YOUR FRIENDS & EARN POINTS")}</h2>
                                                <h4 className=" text-center site-font color-green ref3-t2 h5">{t("Even better, your friends will earn Points too!")}</h4>
                                            </div>
                                            <div className="abt-content site-font col-lg- 12 col-md-12">
                                                <p className="ref3-t3" style={{ textAlign: "justify", textAlignLast: "center" }}>
                                                    {t("Sign in to your account for your personalized link and share it with friends. Once they register with Jetzt Eat, you both get paid!")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* End Invite Text section */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Referrals