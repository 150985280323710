import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useFormik } from 'formik';
import { server } from '../../util/server'
import { useFilterContext } from '../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { addressSchema } from '../../Schema';
import Spinner from '../../Components/Spinner';
const Address = () => {
    const [spinner, setSpinner] = useState(true);
    const { t } = useTranslation();
    const { isUserLogin } = useFilterContext();
    const [addressList, setAddressList] = useState([])
    const [houseNumber, setHouseNumber] = useState('')
    const [street, setStreet] = useState('')
    const [postCode, setPostCode] = useState('')
    const [town, setTown] = useState('')
    const [addressID, setAddressID] = useState()
    const [defaultAddress, setDefaultAddress] = useState(false)
    const navigate = useNavigate();

    const getAddressList = async () => {
        try {
            let url = server + 'auth/user/address-list?&api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setAddressList(data.data)
                setSpinner(false)

            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getAddressList()
        }
        else {
            navigate('/login')
        }
    }, [])

    const submitData = async (query, actions) => {
        try {
            let default_address;
            if (defaultAddress === true) {
                default_address = 1;
            }
            else {
                default_address = 0;
            }
            query['api_type'] = 'web';
            query['user_id'] = isUserLogin;
            query['default_address'] = default_address;
            let url = server + 'auth/user/add-address'
            let res = await axios.post(url, query);
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                getAddressList()
                toast.success('Address is added Successfully!')
                actions.resetForm();
            }
        } catch (error) {
            // toast.error(t("Unexpected error occurred!"));
        }
    }

    const initialValues = {
        housenumber: '',
        street: '',
        post_code: '',
        project: '',
    };

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
        validationSchema: addressSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            submitData(values, actions)
        }
    });

    const handleUpdateSubmit = (event) => {
        event.preventDefault()
        const query = {
            'housenumber': houseNumber,
            'street': street,
            'post_code': postCode,
            'town': town,
        }
        // console.log(query);
        updateData(query)
    }

    const updateData = async (query) => {
        try {
            let default_address;
            if (defaultAddress === true) {
                default_address = 1;
            }
            else {
                default_address = 0;
            }
            query['api_type'] = 'web';
            query['address_id'] = addressID;
            query['user_id'] = isUserLogin;
            query['default_address'] = default_address;
            let url = server + 'auth/user/update-address'
            let res = await axios.post(url, query);
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                getAddressList()
                toast.success('Address is updated Successfully!')
            }
        } catch (error) {
            // toast.error(t("Unexpected error occurred!"));
        }
    }

    const deleteDeliveryAddress = async (id) => {
        try {
            let url = server + 'auth/user/delete-delivery-address'
            let res = await axios.post(url, { 'address_id': id });
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                toast.success(t('Address is deleted successfully!'))
                getAddressList()
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }
    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Profile Update</h3>
                                <hr />
                                <div className="row mt-1">
                                    <div className="col-12"><a className="float-end color-black" data-bs-toggle="modal" href="#add_new_address"><u><b>Add New Address</b></u></a></div>
                                    {/* Add Address Modal Start */}
                                    <div className="modal fade" id="add_new_address" tabIndex="-1" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <form id="addressform" onSubmit={handleSubmit}>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-12" id="error-div"></div>
                                                            <h3>Delivery Address</h3>
                                                            <div className="col-md-6">
                                                                <input className="form-control mt-3" type="text" placeholder="House Number" name="housenumber" value={values.housenumber} onBlur={handleBlur} onChange={handleChange} />
                                                                <span id='msg'>{errors.housenumber && touched.housenumber && errors.housenumber}</span>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input className="form-control mt-3" type="text" placeholder="Street" name="street" value={values.street} onBlur={handleBlur} onChange={handleChange} />
                                                                <span id='msg'>{errors.street && touched.street && errors.street}</span>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input className="form-control mt-3" type="text" placeholder="Postcode" name="post_code" value={values.post_code} onBlur={handleBlur} onChange={handleChange} />
                                                                <span id='msg'>{errors.post_code && touched.post_code && errors.post_code}</span>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input className="form-control mt-3" type="text" placeholder="Town" name="project" value={values.project} onBlur={handleBlur} onChange={handleChange} />
                                                                <span id='msg'>{errors.project && touched.project && errors.project}</span>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <input className="form-control mt-3" type="text" disabled value=" Switzerland" />
                                                            </div>
                                                            <div className="col-12 mt-3">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="default_address" defaultValue={defaultAddress} onChange={() => setDefaultAddress(!defaultAddress)} />
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                        Mark As Default Address
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-danger">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Add Address Modal End */}

                                    {/* Adrees Start */}
                                    {addressList.length > 0 &&
                                        addressList.map((item, key) => (
                                            <div key={key} className="col-lg-6 ">
                                                <section className={item.default_address === 1 ? "login-form3 form-default" : "login-form3"}>
                                                    <div className="row">
                                                        <div className="col-xl-7 col-lg-6 col-md-6 col-12">
                                                            {item.housenumber}, {item.street} <br /> {item.town}, Switzerland<br />
                                                            {item.default_address !== 0 &&
                                                                <Link to="">Default address</Link>
                                                            }
                                                        </div>
                                                        <div className="col-xl-5 col-lg-6 col-md-6 col-12 mt-md-0 mt-3">
                                                            <div className="d-grid">
                                                                <button type="button" className="btn btn-dark" data-bs-toggle="modal" data-bs-target={"#edit_address_" + item.address_id} onClick={() => { setHouseNumber(item.housenumber); setStreet(item.street); setPostCode(item.post_code); setTown(item.town) }}>Edit</button>
                                                                <Link to="#" onClick={() => deleteDeliveryAddress(item.address_id)} className="btn btn-danger mt-2 text-light">Delete</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <div className="modal fade" id={"edit_address_" + item.address_id} tabIndex="-1" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <form id="" onSubmit={handleUpdateSubmit}>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-12" id="error-div-update-"></div>
                                                                        <h4>Delivery Address</h4>
                                                                        <div className="col-md-6">
                                                                            <input className="form-control mt-3" type="text" placeholder="House Number" defaultValue={item.housenumber} onChange={(e) => setHouseNumber(e.target.value)} name="housenumber" />
                                                                            <input type="hidden" name="address_id" defaultValue={item.address_id} />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <input className="form-control mt-3" type="text" placeholder="Street" name="street" defaultValue={item.street} onChange={(e) => setStreet(e.target.value)} />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <input className="form-control mt-3" type="text" placeholder="Postcode" name="post_code" defaultValue={item.post_code} onChange={(e) => setPostCode(e.target.value)} />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <input className="form-control mt-3" type="text" placeholder="Town" name="town" defaultValue={item.town} onChange={(e) => setTown(e.target.value)} />
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <input className="form-control mt-3" type="text" disabled defaultValue=" Switzerland" />
                                                                        </div>
                                                                        <div className="col-12 mt-3">
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" name="default_address" defaultChecked={item.default_address === 1 ? "true" : ""} defaultValue={item.default_address} onChange={() => setDefaultAddress(!defaultAddress)} />
                                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                    Mark As Default Address
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                                                    <button type="submit" onClick={() => setAddressID(item.address_id)} className="btn btn-danger">Submit</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {/* Adrees End */}

                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Address