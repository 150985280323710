import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../util/server'
import { useFilterContext } from '../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import Star from '../../Components/Star'
import AddRating from '../../Components/AddRating'
import Spinner from '../../Components/Spinner'

const PastOrders = () => {
    const [spinner, setSpinner] = useState(true);
    const { t } = useTranslation();
    const { project, media_server, isUserLogin } = useFilterContext();
    const [rating, setRating] = useState(0)
    const [userMessage, setuserMessage] = useState('')
    const [userName, setuserName] = useState('')
    const [orderID, setOrderID] = useState('')
    const [pastOrdersList, setPastOrdersList] = useState([])
    const navigate = useNavigate();

    const getPastOrdersList = async () => {
        try {
            let url = server + 'auth/user/order-history/past?api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setPastOrdersList(data.data)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }
    const handleReviewSubmit = async (event) => {
        event.preventDefault()
        try {
            let url = server + 'auth/user/submit-review'
            let res = await axios.post(url, { 'rating_message': userMessage, 'user_name': userName, 'orderrating': rating, 'order_id': orderID, 'project': project, 'api_type': 'web', 'user_id': isUserLogin });
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                toast.success(t('Thank You for your kind reviews!'))
                getPastOrdersList()
                setuserMessage('')
                setRating(0)
                setOrderID('')
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }
    useEffect(() => {
        if (isUserLogin) {
            getPastOrdersList()
        }
        else {
            navigate('/login')
        }

    }, [])

    const reOrder = async (restaurant_id, order_id) => {
        try {
            let url = server + 'auth/web/reorder'
            let res = await axios.post(url, { 'order_id': order_id, 'restaurant_id': restaurant_id, 'project': 'Winterthur', 'user_id': isUserLogin, 'api_type': 'web' });
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                sessionStorage.setItem('cart_res', restaurant_id)
                navigate('/cart')
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }
    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Past Orders</h3>
                                <hr />
                                <div className='row'>
                                    {pastOrdersList.length > 0 ?
                                        pastOrdersList.map((item, key) => (
                                            <div key={key} className='col-lg-6 col-md-6'>
                                                <div className=" shopping-cart ">
                                                    <div className="shopping-cart-header">
                                                        <h5>Order Reference: {item.order_ref} {
                                                            item.coupon_code &&
                                                            (<> (Coupon: {item.coupon_code}) </>)
                                                        } </h5>
                                                        <p className="text-muted mb-1"> {item.date}
                                                            {(item.order_status === 'Cancelled' || item.order_status === 'Failed') &&
                                                                <span className="badge bg-danger ms-1">{item.order_status}</span>
                                                            }
                                                            {(item.order_status === 'Delivered' || item.order_status === 'Success') &&
                                                                <span className="badge bg-success ms-1">{item.order_status}</span>
                                                            }
                                                            {(item.order_status === 'Preparing' || item.order_status === 'Picked Up') &&
                                                                <span className="badge bg-info ms-1">{item.order_status}</span>
                                                            }
                                                        </p>
                                                        {item.order_status === 'Cancelled' &&
                                                            <p className="mb-1">Payment Refund: {item.refund === 0 ? 'Pending' : 'Done'}</p>
                                                        }
                                                    </div>
                                                    <div className="shopping-cart-items" style={{ overflow: "auto", height: "270px" }}>
                                                        {item.cart &&
                                                            item.cart.map((item2, key2) => (
                                                                <div key={key2}>
                                                                    <div className="clearfix">
                                                                        <img src={media_server + "/" + item2.image} alt={item2.product_name} className="item-img" />
                                                                        <h6>{item2.product_name}</h6>
                                                                        {item2.allergens &&
                                                                            <span className="item-name text-muted ">
                                                                                Allergens: {item2.allergens}
                                                                            </span>
                                                                        }
                                                                        <span className="f-txt">
                                                                            {item2.original_price !== null && item2.discount !== null &&
                                                                                <s>CHF {item2.original_price * item2.quantity}</s>
                                                                            }
                                                                            <b className='ms-1'>CHF {item2.price * item2.quantity}</b></span>
                                                                        <span className="item-quantity f-txt f-txt2 " style={{ float: "right", paddingRight: "5.5%" }}> Quantity: {item2.quantity}</span>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className="row pt-2">
                                                            <div className="col-md-7">
                                                                <p className="mb-0"><b>Payment Method:</b> {item.order_detail.payment_method === "0" ? 'Cash On Delivery' : 'Postfinance'}</p>
                                                                <p className="mb-0"><b>Delivery Charges:</b> CHF {item.delivery_charges}</p>
                                                                <h6><b>Total: CHF </b>
                                                                    {item.actual_amount &&
                                                                        <s className='me-1'> {item.actual_amount}</s>
                                                                    }
                                                                    {item.basket_total}
                                                                </h6>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="d-grid gap-2">
                                                                    <Link to="#" onClick={() => { reOrder(item.restaurant_id, item.order_id) }} className="btn btn-danger">Reorder</Link>
                                                                    {item.order_status === 'Delivered' ?
                                                                        item.orderreview === null ?
                                                                            (<Link to={"#review_" + item.order_id} className="btn btn-dark" data-bs-toggle="modal">Review</Link>) : (<Link to={"#seereview_" + item.order_id} className="btn btn-dark" data-bs-toggle="modal">See Review</Link>) : ('')
                                                                    }
                                                                    <div className="modal fade" id={"seereview_" + item.order_id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-scrollable test-form">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header t-form-h color-white">
                                                                                    <h5 className="modal-title" id="exampleModalLabel color-white">Review</h5>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    <b>Rating: </b>
                                                                                    <Star stars={item.orderreview && item.orderreview.review_rating} />
                                                                                    <br />
                                                                                    <b>Review: </b>{item.orderreview && item.orderreview.review_data}
                                                                                    <br />
                                                                                    <b>Given On :</b> {item.orderreview && item.orderreview.review_date}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="modal fade" id={"review_" + item.order_id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div className="modal-dialog modal-dialog-scrollable test-form">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header t-form-h color-white">
                                                                                <h5 className="modal-title" id="exampleModalLabel color-white">Leave Us A Testimonial</h5>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                            </div>
                                                                            <div className="modal-body ">
                                                                                <form onSubmit={handleReviewSubmit} className="t-form-b needs-validation">
                                                                                    <input type="hidden" className="form-control" name="order_id" defaultValue={item.order_id} />
                                                                                    <input type="hidden" className="form-control orderrating" name="orderrating" value="5" />
                                                                                    <div className="row">
                                                                                        <div className="col-12 mb-3">
                                                                                            <label className="form-label float-start">
                                                                                                <h6>Service Rating</h6>
                                                                                            </label>
                                                                                            <div className='rating-stars text-center'>
                                                                                                <ul id='stars'>
                                                                                                    <AddRating rating={rating} onRating={(rate) => setRating(rate)} />
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="mb-3">
                                                                                                <label className="form-label">
                                                                                                    <h6>Your Message</h6>
                                                                                                </label>
                                                                                                <textarea type="text" className="form-control" id="mag-box" rows="7" value={userMessage} onChange={(e) => setuserMessage(e.target.value)} name='message' placeholder="Write your message"></textarea>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="modal-footer d-block">
                                                                                        <button type="submit" onClick={() => { setOrderID(item.order_id); setuserName(item.user.firstName) }} className="btn btn-success float-end t-f-btn customer_review_button">Submit</button>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : (<p className='text-center'>No Previous Orders Found</p>)
                                    }
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default PastOrders