import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import axios from 'axios'
import { server } from '../../../util/server'
import { useFilterContext } from '../../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'
import Spinner from '../../../Components/Spinner';
import Pagination from "react-js-pagination";

const ScheduledMealPlannerOrder = () => {
    const [spinner, setSpinner] = useState(true);
    const { project, isUserLogin } = useFilterContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [plannersList, setPlannersList] = useState([]);
    const [data, setData] = useState([]);
    const [currentDate, setCurrentDate] = useState(null);
    const getMealPlannerList = async (pageNumber = 1) => {
        try {
            let url = server + `auth/web/meal-planner/scheduled-meal-planner-orders?page=${pageNumber}&api_type=web&user_id=` + isUserLogin + `&project=` + project
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                
                setPlannersList(data.data.data)
                setData(data.data)
                setSpinner(false)
                setCurrentDate(data.current_date)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getMealPlannerList()
        }
        else {
            navigate('/login')
        }
    }, [])

    const splitFoodArray = (food_items) => {
        let foodArray = food_items.split('|')
        let newFoodArray = foodArray.map((foodItem, foodKey) => {
            return foodItem.split('-')
        })
        return newFoodArray
    }
 

    const days = (date_1) => {
        let difference = new Date(date_1).getTime() - new Date(currentDate).getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    const cancelOrder = async(order_id) => {
        try {
            let url = server + 'auth/web/meal-planner/cancel-scheduled-meal-planner-orders'
            let res = await axios.post(url, { 'order_id': order_id, 'order_status' : 'Cancel', 'project': project, 'user_id': isUserLogin, 'api_type': 'web' });
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                toast.success(t('Order is Cancelled!'))
                getMealPlannerList()
            }

        } catch (error) {
            //console.log(error);
            toast.error(t("Unexpected error occurred!"));
        }
    }

    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Scheduled Meal Planner Order</h3>
                                <hr />
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead className="text-center">
                                            <tr>
                                                <th></th>
                                                <th>Order Detail</th>
                                                <th>Delivery address</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-justify">
                                            {(plannersList && plannersList.length > 0) ?
                                                plannersList.map((item, key) => (
                                                    <tr key={key}>
                                                        <td>

                                                            <b> <Link to={"#meal_order_details_" + item.meal_planner_days_id} data-bs-toggle="modal" className="color-black">
                                                                <i className="fa fa-eye me-2"></i>
                                                            </Link> 
                                                            JEMP-{item.meal_planner_days_id}</b>
                                                            <br />
                                                            {item.meal_planner.planner_name==='weekend_planner' ? 'Weekly Planner' : ''}
                                                            {item.meal_planner.planner_name==='monthly_planner' ? 'Monthly Planner' : ''}
                                                            {item.meal_planner.planner_name==='preorder_planner' ? 'Pre-Order Planner' : ''}
                                                            <div className="modal fade" id={"meal_order_details_"  + item.meal_planner_days_id} tabIndex="-1" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                        <div className="modal-body pt-4">
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <table className="table table-bordered table-sm" style={{ marginTop: "15px" }}>
                                                                                        <tbody>
                                                                                            <tr className="bg-secondary color-white text-center">
                                                                                                <td>Name</td>
                                                                                                <td>Qty</td>
                                                                                                <td>Price</td>
                                                                                            </tr>
                                                                                            {item.meal_items !== null &&
                                                                                            splitFoodArray(item.meal_items).map((item3, key3) => (
                                                                                                    <tr key={key3}>
                                                                                                        <td>
                                                                                                            {item3[4]}
                                                                                                            <br />
                                                                                                        </td>
                                                                                                        <td>Qty: {item3[1]} </td>
                                                                                                        <td>CHF {item3[1] * item3[2]} </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                            <tr>
                                                                                                <td colSpan="2" style={{ textAlign: "right", fontWeight: "bold" }}>Standard</td>
                                                                                                <td>CHF {item.delivery_charges}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td colSpan="2" style={{ textAlign: "right", fontWeight: "bold" }}>Total</td>
                                                                                                <td>CHF {item.subtotal}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <b>Payment: </b> Cash On Delivery<br />
                                                            <b>Delivery:</b> CHF {item.delivery_charges} <br />
                                                            <b>Total : </b> CHF {item.meal_planner.total}
                                                        </td>
                                                        <td>
                                                            {item.delivery_address.housenumber}, {item.delivery_address.street}, {item.delivery_address.post_code}, {item.delivery_address.town}, Switzerland
                                                            <br />
                                                            <b>Deliver At :</b> {item.meal_date} {item.time}
                                                        </td>
                                                        <td>
                                                            <b>Order Status: </b>
                                                                <span className="badge bg-info">{item.order_status}</span>
                                                            <br /> <br />
                                                            <b>Order Placed From: </b>{item.meal_planner.project}
                                                            <br />
                                                            {item.meal_order_rider !==null ?
                                                                <>
                                                                    <b>Rider: </b>{item.meal_order_rider.rider_name}
                                                                    <Link to={'tel:'+item.meal_order_rider.rider_number}>
                                                                        <i className="fa fa-phone me-3"></i>
                                                                    </Link>
                                                                </>
                                                                : ''
                                                            }
                                                        </td>
                                                        <td>
                                                        {item.order_status === 'Picked Up' ? 
                                                        <Link to="" className="btn btn-info btn-sm color-white">Track Order</Link>
                                                        : ''
                                                        }
                                                        {
                                                            (days(item.meal_date) === 1 && item.order_status !== 'Cancelled') ? 
                                                            <Link to="#" onClick={() => cancelOrder(item.meal_planner_days_id)} className="btn btn-danger btn-sm color-white mt-2">Cancel Order</Link> : ''
                                                        }
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                (<p>No Meal Planners Found</p>)
                                            }
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="d-flex justify-content-center mt-4">

                                                        {
                                                            data.last_page > 1 &&
                                                            <Pagination
                                                                totalItemsCount={data && data.total}
                                                                activePage={data && data.current_page}
                                                                itemsCountPerPage={data && data.per_page}
                                                                onChange={(pageNumber) => { getMealPlannerList(pageNumber) }}
                                                                itemClass='page-item'
                                                                linkClass='page-link'
                                                                activeClass='active'
                                                            />
                                                        }

                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScheduledMealPlannerOrder