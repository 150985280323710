import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFilterContext } from '../../context/filterContext';
import { server } from '../../util/server';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Spinner from '../../Components/Spinner';

const CateringMenu = () => {
    const [spinner, setSpinner] = useState(true);
    const { i18n, t } = useTranslation();
    const { project, media_server } = useFilterContext();
    const [caterersList, setCaterersList] = useState([]);
    const [pageData, setPageData] = useState([]);
    let imageUrl = "https://demo.ninjashushi.ch/backend/storage/setting/1671015424_extraordinary%20experiences.png";
    const getData = async () => {
        try {
            let url = server + 'auth/web/catering/catering-menu?api_type=web&project=' + project
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setCaterersList(data.caterers)
                setPageData(data.page_data.catering_foods_section_1[0])
                if (data.page_data.catering_foods_section_1[0].link) {
                    imageUrl = media_server + '/' + data.page_data.catering_foods_section_1[0].link;
                }
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            {/* Banner Section Start */}
            <section className="banner-n catering_menu_image">
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end">
                        <div className="col-lg-12">
                            <div className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                <h1 className="color-red">Catering Foods & Drinks</h1>
                                <ul className="bread">
                                    <li><b><Link to="/">Home</Link></b></li>
                                    <li><b><Link to="/catering">Catering</Link></b></li>
                                    <li className="color-red"><b>Foods & Drinks</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Bann Section End */}

            {spinner ? <Spinner /> :
                <>

                    {/* About Section Start */}
                    <section className="my-lg-5" id="catering_with_venues">
                        <div className="container-overlay-image">
                            <div className="card-menu card--testimonial">
                                <div className="card__img__wrapper">
                                    {pageData &&
                                        <div className="card__img" style={{ backgroundImage: `url(${imageUrl})` }}>
                                        </div>
                                    }
                                </div>
                                <div className="card__info__wrapper">
                                    <div className="card__info">
                                        <div className="card__line">
                                            <div className="card__quote">
                                                <h2 className="color-red fw-bold mb-4">
                                                    {pageData.name}
                                                </h2>
                                                <div dangerouslySetInnerHTML={{ __html: pageData.text_en }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mob_text">
                            <h2 className="color-red fw-bold mb-4">
                                {pageData.name}
                            </h2>
                            <div dangerouslySetInnerHTML={{ __html: pageData.text_en }} />
                        </div>
                    </section>
                    {/* About Section End */}

                    {/* Catering Menu Plan Start */}
                    <section className="menuPlan-section">
                        <div className="container">
                            <div className="text-center mx-auto mb-5 wow fadeInUp menuPlan-heading" data-wow-delay="0.1s">
                                <h2 className="mb-3 col-lg-8text-left mx-auto mb-5 wow fadeInUp color-red h1" data-wow-delay="0.5s text-center"><strong>Catering Menu Plans</strong></h2>
                                <div className="col-lg-4">
                                    <div className=" col-lg-4 text-left mx-auto mb-5 wow slideInRight" data-wow-delay="0.1s">
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div id="tab-1" className="tab-pane fade show p-0 active">
                                    <div className="row g-4">
                                        {caterersList.length > 0 &&
                                            caterersList.map((item, key) => (
                                                <div key={key} className="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
                                                    <div className="product-grid menuPlan">
                                                        <div className="product-image">
                                                            <Link to="/catering/menu-plan-1" className="image">
                                                                <img className="pic-1" src="https://demo.ninjashushi.ch/backend/front/images/menu-plan-1.png" alt="" />
                                                            </Link>
                                                        </div>
                                                        <div className="bg-white">
                                                            <p className="color-red menuPlan-price"></p>
                                                            <ul className="rating menuPlan-rating pe-2">
                                                                <li className="fas fa-star color-red"></li>
                                                                <li className="fas fa-star color-red"></li>
                                                                <li className="fas fa-star color-red"></li>
                                                                <li className="fas fa-star color-red"></li>
                                                                <li className="fas fa-star color-lightgrey"></li>
                                                            </ul>
                                                        </div>
                                                        <div className="product-content menu-card-bg-color">
                                                            <h3 className="title text-center justify-content-center" style={{ fontSize: "20px" }}><Link to={"/catering/menu-plan-" + item.menu_plan} className="color-red">Menu Plan {item.menu_plan}</Link></h3>
                                                            <p style={{ fontSize: "15px;" }} className="color-white"></p>
                                                            <Link to={"/catering/menu-plan-" + item.menu_plan} className="btn btn-danger explore-menu-btn">Explore Menu</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Catering Menu Plan End */}

                </>
            }


        </>
    )
}

export default CateringMenu