import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFilterContext } from '../context/filterContext';
import { toast } from "react-toastify";
import { server } from '../util/server';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PlannerDishes from './PlannerDishes';
const MealPlanner = () => {
    const { t,i18n } = useTranslation();
    const navigate = useNavigate()
    const { isUserLogin, project} = useFilterContext();
    const [plannerDataStatus, setPlannerDataStatus] = useState(true)
    const [plannerDaysDataStatus, setPlannerDaysDataStatus] = useState(false)
    //meal planner data
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const minDate = tomorrow.toISOString().slice(0, 10);

    const week = new Date();
    week.setDate(week.getDate() + 7);
    const maxDate = week.toISOString().slice(0, 10);
    const [plannerDays, setPlannerDays] = useState([])
    const [checkboxes, setCheckboxes] = useState([]);
    const [plannerInfo, setPlannerInfo] = useState({'planner_start_date': null, 'planner_end_date':null, 'planner_type':null})
   
    //get planner end date and days list
    const getPlannerDaysData = (type) => {
        let planner_start_Date = '';
        if(type === 'weekly'){
            planner_start_Date = document.getElementById('week_min').value;
            document.getElementById('month_min').value = '';
            document.getElementById('preorder_planner').value = '';
        }
        else if(type === 'monthly'){
            planner_start_Date = document.getElementById('month_min').value;
            document.getElementById('week_min').value = '';
            document.getElementById('preorder_planner').value = '';
        }
        else if(type === 'preorder'){
            planner_start_Date = document.getElementById('preorder_planner').value;
            document.getElementById('month_min').value = '';
            document.getElementById('week_min').value = '';
        }

        axios.get(server+"auth/web/get-planner-end-date?planner_type="+type+"&planner_start_Date="+planner_start_Date)
        .then(function (response) {
            if(response.data.status === 1){
                setPlannerDays(response.data.days_data)
                setPlannerInfo({'planner_start_date': planner_start_Date, 'planner_end_date':response.data.end_date, 'planner_type':type})
                setCheckboxes([])
                let check_input = [...document.querySelectorAll('.form-check-input')];
                check_input.map((input) => {
                    if (input.checked) {
                        let fakeInput = {
                            target: {
                                value: input.value,
                                checked: false
                            }
                        }
                        input.checked = !input.checked;
                    }
                    return null;
                })

                if(type === 'monthly'){
                    var inputs = document.querySelectorAll('input.monthly_planner_input');

                        for (var i = 0; i < inputs.length; i++) {
                        inputs[i].disabled = false;
                        }
                }else{
                    var inputs = document.querySelectorAll('input.monthly_planner_input');

                        for (var i = 0; i < inputs.length; i++) {
                        inputs[i].disabled = true;
                        }
                }

                if(type === 'preorder'){
                    var inputs = document.querySelectorAll('input.pre_order_input');

                        for (var i = 0; i < inputs.length; i++) {
                        inputs[i].disabled = false;
                        }
                }else{
                    var inputs = document.querySelectorAll('input.pre_order_input');

                        for (var i = 0; i < inputs.length; i++) {
                        inputs[i].disabled = true;
                        }
                }
            }
            
        })
        .catch(function (error) {
            toast.error(t("Unexpected error occurred!"));
        });
          
    }

    //submit data
    const submitData = (type) => {
        if(!plannerInfo.planner_start_date){
            toast.error("Please select date to proceed!")
        }
        else if(checkboxes.length === 0 || (checkboxes.length < 2 && plannerInfo.planner_type !== 'preorder')){
            toast.error("Please select at least two days/weeks to proceed!")
        }else{
            axios.post(server+'auth/web/save-meal-planner-data', {
                    'planner_type': type,
                    'planner_id': sessionStorage.getItem('planner_session'),
                    'min_date': plannerInfo.planner_start_date,
                    'max_date': plannerInfo.planner_end_date,
                    'user_id': isUserLogin,
                    'project': project,
                    'api_type' : 'web'
                }, {
                    headers: {
                    'Content-Type': 'application/json'
                    }
                }
                ).then(function (response) {
                    sessionStorage.removeItem('planner_session')
                    sessionStorage.setItem('planner_session', response.data.planner_id)
                    setPlannerDataStatus(false)
                    setPlannerDaysDataStatus(true)
                    // navigate('/planner-dishes', { state: { plannerInfo, checkboxes,plannerDays } })
                  })
                  .catch(function (error) {
                    toast.error(t("Unexpected error occurred!"));
                  });

            //
        }
        // console.log(checkboxes);
    }

    //end planner data


    return (
        <>
            {/* Banner Section Start */}
            <section className="banner-n meal_planner_banner_image">
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end">
                        <div className="col-lg-12">
                            <div className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                <h1 className="color-red"><strong>Meal Planner</strong></h1>
                                <ul className="bread">
                                    <li><b><Link to="/">Home</Link></b></li>
                                    <li className="color-red"><b>Meal Planner</b></li>
                                </ul>
                                <Link to="/meal-cart" className="btn btn-default bg-white color-black btn-sm">Meal Planner Cart</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner Section End */}

            {/* Meal Planner Start */}
            {
                plannerDataStatus &&
                <div className="bg-clr-white">
                <div className="card-container p-5  meal-sec">
                    <h2 className="text-center color-red p-4 h1"><b>Our Plans</b></h2>
                    {
                        !isUserLogin && <div className="col-lg-6 col-md-8 col-12">
                            <div className="alert alert-danger d-flex align-items-center" role="alert">
                                <i className="fas fa-exclamation-triangle me-2"></i>
                                <span>Please <Link to="/login" className="btn btn-dark btn-sm"><b>Login</b></Link> or <Link to="/register" className="btn btn-dark btn-sm"><b>SignUp</b></Link> before taking any planner!</span>
                            </div>
                        </div>
                    }
                    
                    <div className="row" id="planner_div">
                        {/* Weekly Planner Start */}
                        <div className="col-lg-4 col-sm-6 p-2">
                            <div className="card color-white meal-bg-card-1 meal-card-size">
                                <div className="card-count-container">
                                    <div className="card-count bg-r">1</div>
                                </div>
                                <div className="card-content" style={{ padding: '0px' }}>
                                    <h2 className="meal-card-font"><b>Days in a week Planner</b></h2>
                                    <div className="row">
                                        <div className="col-xl-4">
                                            Start Date:
                                        </div>
                                        <div className="col-xl-8">
                                            <input type="date" className="form-control" id="week_min" onChange={() => getPlannerDaysData('weekly')} min={minDate} value={plannerInfo.planner_type === 'weekly' && plannerInfo.planner_start_date}/>
                                        </div>
                                        <div className="col-xl-4 mt-1">
                                            End Date:
                                        </div>
                                        <div className="col-xl-8 mt-1">
                                            {/* <input type="date" className="form-control" id="week_max" defaultValue={plannerInfo.planner_type === 'weekly' && plannerInfo.planner_end_date} disabled /> */}
                                            {plannerInfo.planner_type === 'weekly' && plannerInfo.planner_end_date}
                                        </div>
                                    </div>
                                    <div className="weekend_planner_div mt-4">
                                        <div className="weekend_old_planner">
                                            
                                            {
                                                plannerDays.length > 0 && plannerInfo.planner_type === 'weekly' ?
                                                plannerDays.map(item => (
                                                    <div className="form-check">
                                                        <input className="form-check-input ch-n" type="checkbox"
                                                         value={checkboxes}
                                                          onChange={(e) => {
                                                            // add to list
                                                                if (e.target.checked) {
                                                                    setCheckboxes([
                                                                    ...checkboxes,
                                                                        {
                                                                            value: item
                                                                        },
                                                                    ]);
                                                                } else {
                                                                // remove from list
                                                                setCheckboxes(
                                                                    checkboxes.filter((singleitem) => singleitem.value !== item),
                                                                );
                                                                }
                                                            }} 
                                                        />
                                                        <label className="form-check-label">
                                                            {item}
                                                        </label>
                                                     </div>
                                                ))

                                                :
                                                <>
                                                <div className="form-check">
                                                    <input className="form-check-input ch-n" type="checkbox" value="Sunday" name="weekend_planner[]" disabled />
                                                    <label className="form-check-label">
                                                        Sunday
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input ch-n" type="checkbox" value="Monday" name="weekend_planner[]" disabled />
                                                    <label className="form-check-label">
                                                        Monday
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input ch-n" type="checkbox" value="Tuesday" name="weekend_planner[]" disabled />
                                                    <label className="form-check-label">
                                                        Tuesday
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input ch-n" type="checkbox" value="Wednesday" name="weekend_planner[]" disabled />
                                                    <label className="form-check-label">
                                                        Wednesday
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input ch-n" type="checkbox" value="Thursday" name="weekend_planner[]" disabled />
                                                    <label className="form-check-label">
                                                        Thursday
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input ch-n" type="checkbox" value="Friday" name="weekend_planner[]" disabled />
                                                    <label className="form-check-label">
                                                        Friday
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input ch-n" type="checkbox" value="Friday" name="weekend_planner[]" disabled />
                                                    <label className="form-check-label">
                                                        Saturday
                                                    </label>
                                                </div>
                                                
                                                
                                                </>
                                            }
                                           
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-r position-absolute bottom-0 start-0 end-0">
                                    <div className="social-icons">
                                        { 
                                            isUserLogin &&   <button className="btn btn-default bg-white text-black text-center float-end" onClick={() => submitData('weekend_planner')} type="button">Proceed
                                            </button>
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Weekly Planner End */}
                        {/* Monthly Planner Start */}
                        <div className="col-lg-4 col-sm-6 p-2">
                            <div className="card color-white meal-bg-card-2 meal-card-size">
                                <div className="card-count-container">
                                    <div className="card-count bg-g">2</div>
                                </div>
                                <div className="card-content m-card-2-h" style={{ padding: "0px" }}>
                                    <h2 className="meal-card-font"><b>Monthly Planner</b></h2>
                                    <div className="row">
                                        <div className="col-xl-5">
                                            Start Date:
                                        </div>
                                        <div className="col-xl-7">
                                            <input type="date" className="form-control" id="month_min" onChange={() => getPlannerDaysData('monthly')}  min={minDate} value={plannerInfo.planner_type === 'monthly' && plannerInfo.planner_start_date} />
                                        </div>
                                        <div className="col-xl-5 mt-1">
                                            End Date:
                                        </div>
                                        <div className="col-xl-7 mt-1">
                                            {/* <input type="date" className="form-control" id="month_max" value={plannerInfo.planner_type === 'monthly' && plannerInfo.planner_end_date} disabled /> */}
                                            {plannerInfo.planner_type === 'monthly' && plannerInfo.planner_end_date}
                                        </div>
                                    </div>

                                    <div className="form-check mt-4">
                                        <input className="form-check-input ch-n monthly_planner_input" disabled type="checkbox" value={checkboxes}
                                        onChange={(e) => {
                                        // add to list
                                            if (e.target.checked) {
                                                setCheckboxes([
                                                ...checkboxes,
                                                    {
                                                        value: 'Week 1'
                                                    },
                                                ]);
                                            } else {
                                            // remove from list
                                            setCheckboxes(
                                                checkboxes.filter((singleitem) => singleitem.value !== 'Week 1'),
                                            );
                                            }
                                        }} />
                                        <label className="form-check-label">
                                            Week 1
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input ch-n monthly_planner_input" disabled type="checkbox" value={checkboxes}
                                        onChange={(e) => {
                                        // add to list
                                            if (e.target.checked) {
                                                setCheckboxes([
                                                ...checkboxes,
                                                    {
                                                        value: 'Week 2'
                                                    },
                                                ]);
                                            } else {
                                            // remove from list
                                            setCheckboxes(
                                                checkboxes.filter((singleitem) => singleitem.value !== 'Week 2'),
                                            );
                                            }
                                        }} />
                                        <label className="form-check-label">
                                            Week 2
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input ch-n monthly_planner_input" disabled type="checkbox" value={checkboxes}
                                        onChange={(e) => {
                                        // add to list
                                            if (e.target.checked) {
                                                setCheckboxes([
                                                ...checkboxes,
                                                    {
                                                        value: 'Week 3'
                                                    },
                                                ]);
                                            } else {
                                            // remove from list
                                            setCheckboxes(
                                                checkboxes.filter((singleitem) => singleitem.value !== 'Week 3'),
                                            );
                                            }
                                        }} />
                                        <label className="form-check-label">
                                            Week 3
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input ch-n monthly_planner_input" disabled type="checkbox" value={checkboxes}
                                        onChange={(e) => {
                                        // add to list
                                            if (e.target.checked) {
                                                setCheckboxes([
                                                ...checkboxes,
                                                    {
                                                        value: 'Week 4'
                                                    },
                                                ]);
                                            } else {
                                            // remove from list
                                            setCheckboxes(
                                                checkboxes.filter((singleitem) => singleitem.value !== 'Week 4'),
                                            );
                                            }
                                        }} />
                                        <label className="form-check-label">
                                            Week 4
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input ch-n monthly_planner_input" type="checkbox" disabled value={checkboxes}
                                        onChange={(e) => {
                                        // add to list
                                            if (e.target.checked) {
                                                setCheckboxes([
                                                ...checkboxes,
                                                    {
                                                        value: 'Week 5'
                                                    },
                                                ]);
                                            } else {
                                            // remove from list
                                            setCheckboxes(
                                                checkboxes.filter((singleitem) => singleitem.value !== 'Week 5'),
                                            );
                                            }
                                        }} />
                                        <label className="form-check-label">
                                            Week 5
                                        </label>
                                    </div>
                                </div>
                                <div className="card-footer bg-g position-absolute bottom-0 start-0 end-0">
                                    <div className="social-icons">
                                        {
                                            isUserLogin &&  <button className="btn btn-default bg-white text-black text-center float-end" onClick={() => submitData('monthly_planner')} type='button'>Proceed
                                            </button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Monthly Planner End */}
                        {/* Pre Order Planner Start */}
                        <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3 p-2">
                            <div className="card color-white meal-bg-card-3 meal-card-size">
                                <div className="card-count-container">
                                    <div className="card-count bg-b">3</div>
                                </div>
                                <div className="card-content m-card-3-h" style={{ padding: "0px" }}>
                                    <h2 className="meal-card-font"><b>Pre-order Planner</b></h2>
                                    <div className="row">
                                        <div className="col-xl-5">
                                            Date:
                                        </div>
                                        <div className="col-xl-7">
                                            <input type="date" className="form-control" id="preorder_planner" onChange={() => getPlannerDaysData('preorder')}  min={minDate} max={maxDate} value={plannerInfo.planner_type === 'preorder' && plannerInfo.planner_start_date} />
                                        </div>
                                    </div>
                                    {
                                        plannerInfo.planner_type === 'preorder' ? 
                                        Object.entries(plannerDays).map(([key, item]) => (
                                            <div className="form-check mt-4">
                                                <input className="form-check-input ch-n "  type="checkbox" value={checkboxes}
                                        onChange={(e) => {
                                        // add to list
                                            if (e.target.checked) {
                                                setCheckboxes([
                                                ...checkboxes,
                                                    {
                                                        value: item
                                                    },
                                                ]);
                                            } else {
                                            // remove from list
                                            setCheckboxes(
                                                checkboxes.filter((singleitem) => singleitem.value !== item),
                                            );
                                            }
                                        }}/>
                                                <label className="form-check-label" id="pre_order_data">
                                                    {item}
                                                </label>
                                            </div>
                                        ))
                                        :
                                        <div className="form-check mt-4">
                                                <input className="form-check-input ch-n pre_order_input" disabled type="checkbox" value="Delivery Day" name="preorder_planner[]" id="pre_order_checkbox" />
                                                <label className="form-check-label" id="pre_order_data">
                                                    Selected Day
                                                </label>
                                        </div>

                                    }
                                    
                                </div>
                                <div className="card-footer bg-b position-absolute bottom-0 start-0 end-0">
                                    <div className="social-icons">
                                        {
                                             isUserLogin && <button className="btn btn-default bg-white text-black text-center  float-end" type="button" onClick={() => submitData('preorder_planner')}>Proceed</button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Pre Order Planner End */}
                    </div>
                    <div className="row mt-5" id="planner-dishes-1">
                    </div>
                </div>
                </div>
            }
           
            {/* Meal Planner End */}

            {
                plannerDaysDataStatus &&
                <PlannerDishes plannerInfo={plannerInfo} checkboxes={checkboxes} setPlannerDataStatus={setPlannerDataStatus} setPlannerDaysDataStatus={setPlannerDaysDataStatus} plannerDays={plannerDays}/>
            }

        </>
    )
}

export default MealPlanner