import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import { server } from '../util/server';
import axios from 'axios';
import { useFilterContext } from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import { SingleItem } from '../Components/SingleItem'
const Search = () => {
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState('')
    const [searchData, setSearchData] = useState([])
    const { project,media_server } = useFilterContext();
    const submitdata = () => {
        if(searchValue != ''){
            axios.post(server+'auth/web/search', {
                searchValue: searchValue,
                project: project
              })
              .then(function (response) {
                if(response.data.status === 1){
                    if(response.data.url){
                        navigate(response.data.url)
                    }else{
                        setSearchData(response.data.products)
                    }
                   
                   //console.log(response.data);
                }else{
                  toast.error("Unexpected error occured!")
                }
              
              })
              .catch(function (error) {
                  toast.error("Unexpected error occured!")
              });
        }else{
            // toast.error("Unexpected error occured!")
        }
        
    }
    return (
        <>
            {/* Main Banner section start */}
            <section className="banner-n search_banner_image">
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div>
                        <div className="row">
                            <div className="col-md-auto col-md-offset-2 col-sm-8 col-sm-offset-2  col-xs-8 col-xs-offset-2" style={{ paddingLeft: '50%' }}>
                            </div>
                        </div>
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div className="col-lg-10 text-center mx-auto wow slideInLeft" data-wow-delay="0.1s text-center" style={{ paddingTop: '5%', paddingBottom: "5%" }}>
                                    <h1 className="color-red">Search</h1>
                                    <ul className="bread">
                                        <li><b><Link href="{{ route('home.index') }}">Home</Link></b></li>
                                        <li className="color-red"><b>Search</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Main Banner section End */}

            {/* Main Section Start */}
            <section style={{ paddingBottom: '40px', paddingTop: '40px' }}>
                <div className="container mt-4">
                    <div className="s130">
                        <form style={{ maxWidth: "714px" }} id="mySearchFormId">
                            <div className="inner-form">
                                <div className="input-field first-wrap">
                                    <div className="svg-wrapper" style={{ minWidth: '32px' }}>
                                        {/* <svg width="15" height="24" viewBox="0 0 24 24" style={{ visibility:'hidden' }}>
                                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                        </svg> */}
                                    </div>
                                    <input className="srch-font" type="text" onChange={(e) => setSearchValue(e.target.value)} placeholder="Type Here" name="search" value={searchValue} required />
                                </div>
                                <div className="input-field second-wrap" style={{ minWidth: '65px' }} id="voiceSearchDiv">
                                    <button className="btn-search s-btn-txt" style={{ backgroundColor: 'transparent', fontSize: '25px', color: '#000' }} id="recordButton" type="button" onClick={() => submitdata()}>
                                        {/* <i className="fas fa-microphone-alt"></i> */}
                                        <i className="fas fa-search"></i>
                                    </button>
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div className="" style={{ backgroundColor: 'whitesmoke' }}>
                        <br /><br />
                        <div className="container pb-lg-5 pt-3">
                            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                                <h3 className="mb-3" style={{ color: '#EA1D25', fontWeight: 'bolder' }}>Available Deals against this search</h3>
                            </div>
                            <div className="owl-carousel search-carousel wow fadeInUp" id="search-carousel" data-wow-delay="0.1s">
                                <div className="color-black text-center mx-3">
                                    <Link href="" className="color-black">
                                        <img src="" width="100%" height="200px" alt="" />
                                        <h4 className="mb-0 mt-2">Product Name</h4>
                                        <h6>CHF 80.00</h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="tab-content mt-2">
                        <div id="tab-1" className="tab-pane fade show p-0 active">
                            <div className="row g-4 mt-2">
                                {
                                    searchData.length === 0 && <div className='col-12 text-center'>No Search results!</div>
                                }
                                {
                                    searchData.length > 0 &&
                                    <>
                                    <h3 className="mb-4 color-red fw-bold text-center">Available Dishes against this search</h3>
                                    {
                                        searchData.map(item => (
                                            <div className="col-lg-4 col-sm-6 mb-2 wow fadeInUp px-sm-2" data-wow-delay="0.2s" key={item.slug}>
                                                <SingleItem product={item} />
                                            </div>
                                        ))
                                    }
                                    </>
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Main Section End */}
        </>
    )
}

export default Search