import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFilterContext } from '../../context/filterContext'
import { server } from '../../util/server'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';

const CateringCart = () => {
    const { media_server } = useFilterContext();
    const { t } = useTranslation();
    const [cart, setCart] = useState([])
    const [foodPlanCart, setFoodPlanCart] = useState([])
    // let cartItems;
    const getCartItems = () => {
        setCart(JSON.parse(localStorage.getItem('catering_cart')))
    }
    const getFoodPlanCart = () => {
        setFoodPlanCart(JSON.parse(localStorage.getItem('catering_food_plan_cart')))
    }
    useEffect(() => {
        getCartItems()
        getFoodPlanCart()
    }, [])

    const removeItemFromCart = (food_id, food_type) => {
        let url = server + "auth/web/catering/delete-from-cart"
        if (food_type === 'Dishes') {
            axios.post(url, { 'food_id': food_id, 'food_type': food_type, 'catering_food_cart_array': JSON.parse(localStorage.getItem('catering_cart')) }).then(function (result) {
                if (result.data.status === 0) {
                    toast.error(t(result.data.message));
                } else {
                    var catering_cart = JSON.stringify(result.data.catering_food_cart_array)
                    localStorage.setItem('catering_cart', catering_cart)
                    toast.success('Food is removed from cart!');
                    getCartItems()
                }
            })
                .catch(function (error) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }
        else {
            axios.post(url, { 'food_id': food_id, 'food_type': food_type, 'catering_foodplan_cart_array': JSON.parse(localStorage.getItem('catering_food_plan_cart')) }).then(function (result) {
                if (result.data.status === 0) {
                    toast.error(t(result.data.message));
                } else {
                    var catering_cart = JSON.stringify(result.data.catering_foodplan_cart_array)
                    localStorage.setItem('catering_food_plan_cart', catering_cart)
                    toast.success('Food Plan is removed from cart!');
                    getFoodPlanCart()
                }
            })
                .catch(function (error) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }

    }

    return (
        <>
            <div className="wishlist-area shopping_cart shop-list py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mycart">
                            <h3 className="pt-4 mb-5">Catering Basket</h3>
                            <hr className="mb-4" style={{ backgroundColor: "#c0c1c2" }} />
                            {cart && Object.entries(cart).length > 0 &&
                                Object.entries(cart).map(([index, item], key) => (
                                    <div key={key}>
                                        <div className="row">
                                            <div className="col-sm-3 col-4 mycart1">
                                                <img src={media_server + item.image} alt={item.name} className="cart-img" />
                                            </div>
                                            <div className="col-sm-6 col-8 mycart1">
                                                <p className="color-black mb-0">Dish</p>
                                                <p className="mb-0 color-red fs-4" style={{ lineHeight: "18px" }}>{item.name} </p>
                                                <div className="cart-mob">
                                                    <input type="number" className="form-control" defaultValue="1" />
                                                    <p className="mb-0">CHF {item.price}</p>
                                                    <p><Link to="">Remove</Link></p>
                                                </div>
                                            </div>
                                            <div className="col-sm-2 cart-hide">CHF {item.price}</div>
                                            <div className="col-sm-1 cart-hide">
                                                <div className="cart-button"><Link className="float-right" to="#" onClick={() => removeItemFromCart(index, 'Dishes')}>X</Link></div>
                                            </div>
                                        </div>
                                        <hr className="mb-4" style={{ backgroundColor: "#c0c1c2" }} />
                                    </div>
                                ))
                            }

                            {foodPlanCart && Object.entries(foodPlanCart).length > 0 &&
                                Object.entries(foodPlanCart).map(([index1, item1], key) => (
                                    <div key={key}>
                                        <div className="row">
                                            <div className="col-sm-3 col-4 mycart1">
                                                <img src={item1.image ? media_server + item1.image : 'https://demo.ninjashushi.ch/backend/storage/customer/432-c292b3e9629fee6bbb741eb109321cf8.jpg'} alt={item1.name} className="cart-img" />
                                            </div>
                                            <div className="col-sm-6 col-8 mycart1">
                                                <p className="color-black mb-0">Dish</p>
                                                <p className="mb-0 color-red fs-4" style={{ lineHeight: "18px" }}>{item1.name} </p>
                                                <div className="cart-mob">
                                                    <input type="number" className="form-control" defaultValue="1" />
                                                    <p className="mb-0">CHF {item1.price}</p>
                                                    <p><Link to="">Remove</Link></p>
                                                </div>
                                            </div>
                                            <div className="col-sm-2 cart-hide">CHF {item1.price}</div>
                                            <div className="col-sm-1 cart-hide">
                                                <div className="cart-button"><Link className="float-right" to="#" onClick={() => removeItemFromCart(index1, 'Plan')}>X</Link></div>
                                            </div>
                                        </div>
                                        <hr className="mb-4" style={{ backgroundColor: "#c0c1c2" }} />
                                    </div>
                                ))
                            }

                            {(!cart && !foodPlanCart) &&
                                <div className="col-12">
                                    <p className="text-center">No Dish/Food Plan Found in Cart!</p>
                                </div>
                            }

                            <div className="row mt-3">
                                <div className="col-12">
                                    <Link to="/catering-menu" className="btn my-3 btn-black" style={{ backgroundColor: "#000", color: "whitesmoke" }}>Back to Catering Menu</Link>
                                    {((cart && Object.entries(cart).length > 0) || (foodPlanCart && Object.entries(foodPlanCart).length > 0)) &&
                                        <Link to="/catering-checkout/step-1" className="btn my-3 btn-danger ms-1">Go To Checkout</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CateringCart