import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../context/filterContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { server } from '../util/server';
import axios from 'axios';
import { Meta } from '../Components/Meta';
import Spinner from '../Components/Spinner';
export const GiftCardListing = () => {
    const [spinner, setSpinner] = useState(true);
    const { i18n, t } = useTranslation();
    const { updatemetas, updatePageBanner, page_banner, media_server } = useFilterContext();
    const [data, setData] = useState([]);
    const [pageData, setPageData] = useState(null);
    const getData = async () => {
        try {
            let url = server + 'auth/web/gift-cards?project=Winterthur&api_type=web&page_link=gift-card'
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setData(data.data)
                updatemetas(null, 0, data.meta)
                updatePageBanner(data.page_banner)
                setPageData(data.page_data)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Meta />
            {
                page_banner && <section className="banner-n" style={{ backgroundImage: `url(${media_server}/${page_banner})` }}>
                    <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div className="col-lg-10 text-center mx-auto mb-4 wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                    <h1 className="site-font color-red"> <strong>{t("Gift Cards")}</strong></h1>
                                    <ul className="bread">
                                        <li><b><Link to="/">{t("Home")}</Link></b></li>
                                        <li className="color-red"><b>{t("Gift Card")}</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {spinner ? <Spinner /> : <>

                <div className="container">
                    <div className="col-lg-12 ">
                        <div className="row my-5 py-4">
                            {
                                data.length === 0 && <div className="col-12 text-center"><p><b>{t("No Gift Card Found")}!</b></p></div>
                            }

                            {
                                data.length > 0 && data.map(item => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 p-4 gift-cards" key={item.card_id}>
                                        <img src={media_server + "/" + item.main_image} className="card-img-top img-fluid" alt={item.card_type} />
                                        <div className="card-body">
                                            <h5 className="card-title">{item.card_type}</h5>
                                            <Link to={"/gift-card/" + item.card_id + "-" + item.slug} className="btn">{t("Buy Card")}</Link>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="col-lg-12">

                        <div className="text-center mx-auto mb-5 wow fadeInUp loc-t" data-wow-delay="0.5s">
                            <div className="mb-3 col-lg-8text-left mx-auto mb-5 wow fadeInUp about-h" data-wow-delay="0.5s text-center ">
                                <h2 className="color-red site-font h1"><strong>{t("Redeem your Gift Card")}</strong></h2>
                            </div>
                            <div className="col-lg-4">
                                <div className=" col-lg-4 text-left mx-auto mb-5 wow slideInRight" data-wow-delay="0.1s">

                                </div>
                            </div>
                        </div>

                        <main className="mb-5">
                            <ul className="infoGraphic">

                                {
                                    pageData && pageData['giftcard_section_1'] && pageData['giftcard_section_1'].map((item2, index) => (
                                        <li key={index}>
                                            <div className="numberWrap">
                                                <div className="number fontColor1">{index + 1}</div>
                                                <div className="coverWrap">
                                                    <div className="numberCover"></div>
                                                </div>
                                            </div>
                                            <div className="content">

                                                <h2>{item2['name_' + i18n.language]}</h2>
                                                <p>{item2['text_' + i18n.language]}</p>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>

                        </main>


                    </div>
                </div>

            </>}


        </>
    )
}
