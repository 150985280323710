import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../util/server'
import { useFilterContext } from '../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Components/Spinner'
const EatWallet = () => {
    const [spinner, setSpinner] = useState(true);
    const { t } = useTranslation();
    const { isUserLogin } = useFilterContext();
    const [eatWalletData, setEatWalletData] = useState(null)
    const navigate = useNavigate();
    const getEatWalletData = async () => {
        try {
            let url = server + 'auth/web/eat-wallet?api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data) {
                setEatWalletData(data)
                setSpinner(false)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getEatWalletData()
        }
        else {
            navigate('/login')
        }
    }, [])

    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Eat Wallet</h3>
                                <hr />
                                {/* BALANCE CARDS START */}
                                <section>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="alert alert-secondary bg-red text-light" role="alert">
                                                Balance
                                                <Link to="#" role="button" className="float-end text-light">
                                                    CHF {eatWalletData && eatWalletData.balance}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="alert alert-secondary bg-red text-light" role="alert">
                                                Reward Points
                                                <Link to="" className="float-end text-light">
                                                    {eatWalletData && eatWalletData.points}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="alert alert-secondary bg-red text-light" role="alert">
                                                Redeem Gift Card
                                                <Link data-bs-toggle="collapse" to="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample" className="float-end text-light">
                                                    <i className="fas fa-angle-down"></i>
                                                </Link>
                                            </div>
                                            <div className="collapse" id="collapseExample1">
                                                <div className="card card-body">
                                                    <form method="POST">
                                                        <div className="row mt-3">
                                                            <div className="col-md-12 mb-2">
                                                                <label className="mb-1">Gift Card Code</label>
                                                                <input className="form-control" type="text" name="gift_card_code" required />
                                                            </div>
                                                            <div className="col-lg-12 float-end">
                                                                <button type="submit" className="btn btn-dark mt-4">Redeem</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* BALANCE CARDS END */}

                                {/* TABLE Section START */}
                                <section>
                                    <div className="row ">
                                        <br />
                                        <div className="col-lg-12 col-md-12 col-xs-12">
                                            <br />
                                            <div className="card mb-0">
                                                <div className="card-header bg-red">
                                                    <h4 className=" mb-0 color-white ph text-center">
                                                        <strong>Recent Transactions</strong>
                                                    </h4>
                                                </div>
                                                <div className="card-body2 bg-white">
                                                    <div className="table-responsive bg-white">
                                                        <table className=" table table-striped table-hover">
                                                            <thead className="th-d2">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Order Date</th>
                                                                    <th>Items</th>
                                                                    <th>Total Price</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="th-d">
                                                                    <th colSpan="4" className="text-center">No history found!</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </section>
                                {/* TABLE Section END */}
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default EatWallet