
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from "react-toastify";
import { server } from '../util/server';
import axios from 'axios';
import { useFilterContext } from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import Spinner from '../Components/Spinner';

const Cart = () => {
    const [spinner, setSpinner] = useState(true);
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([])
    const [cartTotal, setCartTotal] = useState(null)
    const { cartTotalCount, setCartTotalCount, project, isUserLogin, media_server, setCoupon, coupon } = useFilterContext();

    const getData = async () => {
        try {
            let url = server + 'auth/cart/cart-items?project=' + project + '&page_link=cart&api_type=web&user_id=' + isUserLogin
            if (coupon) {
                url += '&coupon_code=' + coupon.coupon_code
            }
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                // updatemetas(null,0,data.meta)
                // updatePageBanner(data.page_banner)
                setData(data.data)
                setCartTotal(data.cart_total)
                setCartTotalCount(data.cart_count);
                if (data.coupon) {
                    if (data.coupon.status === 1) {
                        setCoupon(data.coupon.coupon)
                    } else {
                        setCoupon(null)
                        data.coupon && toast.error(t(data.coupon.message))

                    }
                }
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getData()
        }
        else{
            setSpinner(false)
        }
    }, [])

    /* remove from cart */
    const removeFromCart = (cart_id) => {
        fetch(server + "auth/cart/delete-cart-item", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 'cart_id': cart_id, 'api_type': 'web', 'user_id': isUserLogin })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    getData()

                },
                (error) => {
                    toast.error(t("Unexpected error occurred!"));
                }
            )
    }

    const updateQuantity = (type, cart_id) => {
        fetch(server + "auth/cart/update-cart-item", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 'cart_id': cart_id, 'user_id': isUserLogin, 'type': type, 'api_type': 'web' })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === 1) {
                        getData()
                    } else {
                        toast.error(t(result.message));
                    }


                },
                (error) => {
                    toast.error(t("Unexpected error occurred!"));
                }
            )
    }

    //apply coupon
    const applyCoupon = () => {
        let coupon_code = document.getElementById('coupon_code').value;
        if (!coupon_code) {
            toast.error(t("Please enter coupon code to proceed!"));
        } else if (isUserLogin === null) {
            toast.error(t("Please login to continue"));
        }
        else {
            fetch(server + "auth/user/checkout-coupon", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 'coupon_code': coupon_code, 'user_id': isUserLogin, 'project': project, 'api_type': 'web' })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.status === 1) {
                            toast.success(t(result.message));
                            setCoupon(result.coupon);
                            document.getElementById('coupon_code').value = null;
                        } else {
                            toast.error(t(result.message));
                        }

                    },
                    (error) => {
                        toast.error(t("Unexpected error occurred!"))
                    }
                )
        }


    }

    return (
        <>
            {spinner ? <Spinner /> :
                <>
                    <div className="wishlist-area shopping_cart shop-list py-5">
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-md-8 mycart">
                                    <h3 className="pt-4 mb-5">Shopping Basket</h3>
                                    <form method="POST">
                                        {/* Item 1 */}

                                        {
                                            data.length === 0 && <><hr className="mb-4 bg-white" /><p className='text-center'>{t("Empty Basket!")}</p></>
                                        }
                                        {
                                            data.length > 0 &&
                                            data.map((item, key) => (
                                                <div key={key}>
                                                    <hr className="mb-4 bg-white" />
                                                    <div className="row">
                                                        <div className="col-sm-3 col-4 mycart1">
                                                            <img src={media_server + '/' + item.image} alt={item.product_name} className="cart-img" />
                                                        </div>
                                                        <div className="col-sm-4 col-8 mycart1">
                                                            <p className="mb-0 color-red fs-4">{item.product_name}</p>
                                                            {
                                                                item.allergens && <p className="mb-0"><b>Allergens: </b>
                                                                    {
                                                                        JSON.parse(item.allergens).map((allergen, key) => (
                                                                            key === 0 ? allergen['name_' + i18n.language] : ', ' + allergen['name_' + i18n.language]
                                                                        ))
                                                                    }
                                                                </p>
                                                            }

                                                            <div className="cart-mob">
                                                                <button className='btn btn-sm btn-dark d-inline-block' type="button" onClick={() => updateQuantity('decrement', item.cart_id)}>-</button>
                                                                <p className='d-inline-block px-2'>{item.quantity}</p>
                                                                <button className='btn btn-sm btn-dark d-inline-block' type="button" onClick={() => updateQuantity('increment', item.cart_id)}>+</button>
                                                                {
                                                                    item.original_price !== 0 && item.discount && <><p className="mb-0"><s>CHF {(item.original_price * item.quantity).toFixed(2)}</s></p><br /></>
                                                                }
                                                                <p className="mb-0">CHF {(item.price * item.quantity).toFixed(2)}</p>
                                                                <p><Link to="#">Remove</Link></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2 cart-hide">
                                                            <button className='btn btn-sm btn-dark d-inline-block' type="button" onClick={() => updateQuantity('decrement', item.cart_id)}>-</button>
                                                            <p className='d-inline-block px-2'>{item.quantity}</p>
                                                            <button className='btn btn-sm btn-dark d-inline-block' type="button" onClick={() => updateQuantity('increment', item.cart_id)}>+</button>
                                                        </div>
                                                        <div className="col-sm-2 cart-hide">
                                                            {
                                                                item.original_price !== 0 && item.discount && <><s>CHF {(item.original_price * item.quantity).toFixed(2)}</s><br /></>
                                                            }
                                                            CHF {(item.price * item.quantity).toFixed(2)}</div>
                                                        <div className="col-sm-1 cart-hide">
                                                            <div className="cart-button"><Link className="float-right" to="#" onClick={() => removeFromCart(item.cart_id)}>X</Link></div>
                                                        </div>
                                                    </div>
                                                    {/* Item 1 End */}
                                                </div>
                                            ))
                                        }


                                        {/* buttons */}
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <Link to="/menu" className="btn my-3" style={{ backgroundColor: 'black', color: 'whitesmoke' }}>Back to Menu</Link>
                                                {/* <button type="submit" className="btn ms-1 my-3" style={{ backgroundColor: '#EA1D25', color: 'whitesmoke' }} >Update Cart</button> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="pt-3 col-md-4 mt-lg-0 mt-md-5 mycart">
                                    <div className="cart-summary px-3 py-2">
                                        <h3 className="fw-bold mb-5">Summary</h3>
                                        <hr className="mb-4" style={{ backgroundColor: '#c0c1c2' }} />
                                        <div className="d-flex justify-content-between mb-4">
                                            <h5 className="text-uppercase">{cartTotalCount} item(s)</h5>
                                            <h5>CHF {cartTotal ? cartTotal : 0.00}
                                            </h5>
                                        </div>
                                        <form method="POST" id="couponForm">
                                            <div id="coupon-info"></div>
                                            <h5 className="text-uppercase mb-3 fw-bold">Coupon Code</h5>
                                            <div className="searchbox-wrap">
                                                <input type="text" className="form-control coupen_code" name="coupen_code" placeholder="Enter Code" id="coupon_code" style={{ padding: '10px 10px', border: '1px solid lightgrey' }} />
                                                <button className="coupon coupon_btn" type="button" onClick={() => applyCoupon()}><span>Apply</span> </button>
                                            </div>
                                        </form>
                                        <hr className="my-4" />
                                        {
                                            coupon &&
                                            <div className="d-flex justify-content-between mb-1">

                                                <h6 className="text-uppercase fw-bold">Discount ({coupon.coupon_code})</h6>
                                                <h6 className="float-right basket_total">
                                                    CHF {coupon.coupon_discount}</h6>
                                            </div>
                                        }


                                        <div className="d-flex justify-content-between mb-3">

                                            <h6 className="text-uppercase fw-bold">BASKET TOTAL</h6>
                                            <h6 className="float-right basket_total">
                                                {/* <span className="text-decoration-line-through">CHF 220.00</span><br /> */}
                                                CHF {cartTotal ?
                                                    coupon ? coupon.discounted_cart_total : cartTotal
                                                    : 0.00}</h6>
                                        </div>
                                        {
                                            data.length > 0 && <div className="d-flex sum2 justify-content-center flex-column">
                                                <Link to="/checkout" className="btn my-3 mob1" style={{ backgroundColor: '#EA1D25', color: 'whitesmoke' }}>Proceed to Checkout</Link>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Cart