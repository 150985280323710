import React, { useEffect, useState } from 'react'
import '../../User.css'
import Sidebar from './Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import { useFilterContext } from '../../context/filterContext';
import Spinner from '../../Components/Spinner';

const Dashboard = () => {
    const [spinner, setSpinner] = useState(true);
    const [profileToggle, setprofileToggle] = useState(false)
    const [addressToggle, setaddressToggle] = useState(false)
    const { isUserLogin } = useFilterContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (!isUserLogin) {
            navigate('/login')
        }
        else {
            setSpinner(false)
        }
    }, [])
    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />

                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Dashboard</h3>
                                <hr />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-success bg-green color-white" style={{ borderLeft: "4px solid #ea1d25", backgroundColor: '#64bc46' }} role="alert">
                                            <i className="fas fa-tags me-2"></i>
                                            Redeem your coupon "SONDERRABATT" now (valid till Dec 27, 2022) and save 100 CHF
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/user/order/past">
                                            <div className="alert alert-secondary text-center bg-red color-white" role="alert">
                                                <i className="fa fa-history" aria-hidden="true"></i>
                                                <span className="ml-2"> Past Orders</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/user/order/ongoing">
                                            <div className="alert alert-secondary text-center bg-red color-white" role="alert">
                                                <i className="fa fa-spinner" aria-hidden="true"></i>
                                                <span className="ml-2"> Ongoing Orders</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <h3 className="mt-5">Customer Information</h3>
                                    <hr />
                                    <div className="col-lg-6">
                                        <div className="alert alert-secondary bg-red color-white" role="alert">
                                            Personal Profile | <Link to="/user/profile" style={{ color: "#000" }}>EDIT</Link>
                                            <Link onClick={() => setprofileToggle(!profileToggle)} role="button" className="float-end text-light">
                                                <i className="fas fa-angle-down"></i>
                                            </Link>
                                        </div>
                                        {profileToggle &&
                                            <div>
                                                <div className="card card-body">
                                                    <span>
                                                        <strong>Full Name:</strong> Mariam swiss
                                                    </span>
                                                    <span>
                                                        <strong>Email:</strong> mariamswisstec@gmail.com
                                                    </span>
                                                    <span>
                                                        <strong>Phone Number:</strong> +(41) 98 787 67 65
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="alert alert-secondary bg-red color-white" role="alert">
                                            Address Book | <Link to="/user/address" style={{ color: "#000" }}>EDIT</Link>
                                            <Link onClick={() => setaddressToggle(!addressToggle)} role="button" className="float-end text-light">
                                                <i className="fas fa-angle-down"></i>
                                            </Link>
                                        </div>
                                        {addressToggle &&
                                            <div>
                                                <div className="card card-body">
                                                    <span>
                                                        <strong>House Number :</strong>768
                                                    </span>
                                                    <span>
                                                        <strong>Street: </strong>Test
                                                    </span>
                                                    <span>
                                                        <strong>Town/City: </strong> Winterthur
                                                    </span>
                                                    <span>
                                                        <strong>Postcode: </strong> 9000
                                                    </span>
                                                    <span>
                                                        <strong>Country: </strong> Switzerland
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <h3 className="mt-5">Contact Us</h3>
                                    <hr />
                                    <div className="col-lg-3 col-6" data-wow-delay="0.3s" >
                                        <div className="team-item overflow-hidden">
                                            <Link to="tel:+41 71 222 41 02">
                                                <div className="text-center mt-5">
                                                    <i className="fa fa-phone"></i>
                                                    <br />
                                                    <h3 className="fw-bold mb-0 mt-2 color-black fw-22">Call Us</h3>
                                                    <br />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6" data-wow-delay="0.3s" >
                                        <div className="team-item overflow-hidden">
                                            <Link to="mailto:eat.jetzt@gmail.com">
                                                <div className="text-center mt-5">
                                                    <i className="fa fa-envelope"></i>
                                                    <br />
                                                    <h3 className="fw-bold mb-0 mt-2 color-black fw-22">Email Us</h3>
                                                    <br />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6" data-wow-delay="0.3s" >
                                        <div className="team-item  overflow-hidden">
                                            <Link to="">
                                                <div className="text-center mt-5">
                                                    <i className="fa fa-question-circle"></i>
                                                    <br />
                                                    <h3 className="fw-bold mb-0 mt-2 color-black fw-22">Help Center</h3>
                                                    <br />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6" data-wow-delay="0.3s" >
                                        <div className="team-item  overflow-hidden">
                                            <Link to="">
                                                <div className="text-center mt-5">
                                                    <i className="fa fa-clipboard"></i>
                                                    <br />
                                                    <h3 className="fw-bold mb-0 mt-2 color-black fw-22">Register Complaint</h3>
                                                    <br />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
export default Dashboard