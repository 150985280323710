import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import { useFilterContext } from '../context/filterContext';
import { server } from '../util/server'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import { ProductDesRevNut } from './SingleFoodDetail/ProductDesRevNut';
import { PopularProduct } from './SingleFoodDetail/PopularProduct';
import Spinner from './Spinner';

const SingleItemDetail = ({props}) => {
    const [spinner, setSpinner] = useState(true);
    const { slug } = useParams()
    const location = useLocation();
    const xyz = location.state;
    const food_category = xyz[0].item_slug;
    const food_subcategory = xyz[1].slug;

    const navigate = useNavigate()
    const { i18n, t } = useTranslation();
    const [data, setData] = useState(null);
    const [allergens, setAllergens] = useState([]);
    const [nutrients, setNutrients] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    // Quantity State
    const [quantity, setQuantity] = useState(1);
    //allergens
    const [checkboxes, setCheckboxes] = useState([]);
    const { updatemetas, updatePageBanner, page_banner, media_server, project, setCartTotalCount, isUserLogin } = useFilterContext();
    const [show, setShow] = useState(false);
    const [deleteFromCart, setDeleteFromCart] = useState(null);
    const handleClose = (type) => {
        if (type === '0') {
            setDeleteFromCart(null)
        } else {
            addToCart()

        }
        setShow(false);
    }
    const handleShow = (item) => setShow(true);

    const getData = async () => {
        var slugArray = slug.split("-");
        try {
            let url = server + 'auth/web/food-detail?product_id=' + slugArray[0] + '&page_link=menu&api_type=web'
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setData(data.data)
                setAllergens(data.allergens)
                setNutrients(data.nutrients)
                setReviews(data.reviews)
                setPopularProducts(data.popular_products)
                setSpinner(false)
                // updatemetas(null,0,data.meta)
                // updatePageBanner(data.page_banner)

            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getData()
    }, [slug])

    const addToCart = () => {
        if (!isUserLogin) {
            sessionStorage.setItem('last_url', '/food/' + slug)
            toast.error(t("Please login to add food to your cart!"));
            navigate('/login')
        } else if (sessionStorage.getItem('cart_res') && !deleteFromCart && data.restaurant_id !== parseInt(sessionStorage.getItem('cart_res'))) {
            setDeleteFromCart(data.product_id)
            handleShow()
        } else {
            sessionStorage.removeItem('last_url')
            let url = server + "auth/cart/add-to-cart"
            axios.post(url, { 'food_id': data.product_id, 'api_type': 'web', 'project': project, 'user_id': isUserLogin, 'allergens': checkboxes, 'quantity': quantity }).then(function (result) {
                if (result.data.status === 0) {
                    toast.error(t(result.data.message));
                } else {
                    toast.success(t(result.data.message));
                    setCartTotalCount(result.data.cart_count);
                    sessionStorage.removeItem('cart_res', result.data.restaurant_id)
                    sessionStorage.setItem('cart_res', result.data.restaurant_id)
                }
                setDeleteFromCart(null)
            })
                .catch(function (error) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }

    }
    // Increase Quantity
    const AddItems = () => setQuantity(quantity => quantity + 1);

    // Decrease Quantity
    const DecreaseItems = () => {
        if (quantity > 0) setQuantity(quantity => quantity - 1);
    }


    
    const addToWishlist = (food_id) => {
        if (!isUserLogin) {
            sessionStorage.setItem('last_url', window.location.pathname)
            toast.error(t("Please login to add food to your wishlist!"));
            navigate('/login')
        } else {
            let url = server + "auth/web/add-to-wishlist"
            axios.post(url, { 'product_id': food_id, 'api_type': 'web', 'project': project, 'user_id': isUserLogin }).then(function (result) {
                if (result.data.status === 0) {
                    toast.error('Food is already in your wishlist!');
                } else {
                    toast.success('Food is added to your wishlist!');
                }
            })
                .catch(function (error) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }
    }



    return (
        <>
            {spinner ? <Spinner /> :
                <>
                    <div className="product-container my-5">
                        <div className="row">
                            <div className="col-12">
                                {/* BreadCrumb Start */}
                                <ul className="bread mb-2" id="custom-bread">
                                    <li>
                                        <b><Link to="/" className="color-black">Home</Link></b>
                                    </li>
                                    <li>
                                        <b><Link to="/menu" className="color-black">Menu</Link></b>
                                    </li>
                                    <li>
                                        <b><Link to={'/'+food_category} className="color-black">Category</Link></b>
                                    </li>
                                    <li>
                                        <b><Link to={'/'+food_category+'/'+food_subcategory} className="color-black">Subcategory</Link></b>
                                    </li>
                                    <li className="color-red">
                                        <b>Product</b>
                                    </li>
                                </ul>
                                {/* BreadCrumb End */}
                            </div>
                            <div className="col-md-6">
                                {
                                    data && <img src={media_server + "/" + data['product_image']} width="100%" style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }} alt={data['alt_' + i18n.language]} />
                                }

                            </div>
                            {/* Main Content Start */}
                            <div className="col-md-6 pt-2 pt-sm-5">
                                <form id="myform">
                                    <div className="product-dtl">
                                        <div className="product-info">
                                            <h1 className="product-name">{data && data.product_name}</h1>

                                            {
                                                data &&
                                                <ul className="rating" style={{ display: 'inline', paddingLeft: '0rem' }}>
                                                    <li className={1 <= data.reviews ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                    <li className={2 <= data.reviews ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                    <li className={3 <= data.reviews ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                    <li className={4 <= data.reviews ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                    <li className={5 <= data.reviews ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                </ul>
                                            }


                                            <div className="product-price-discount">
                                                {
                                                    data && data.discount_type &&
                                                    <span><s className="color-red" style="font-size: 16px;">CHF  {(data.cost_price + data.profit).toFixed(2)}</s>  CHF {(data.product_price).toFixed(2)}</span>
                                                }

                                                {
                                                    data && !data.discount_type &&
                                                    <span>CHF {(data.product_price).toFixed(2)}</span>
                                                }
                                            </div>
                                        </div>
                                        <label className="fw-bold">{t("Choose Allergens")}</label>
                                        <div className="my-container">
                                            {
                                                allergens.length > 0 &&
                                                allergens.map(allergen => (
                                                    <label>
                                                        <input type="checkbox"
                                                            //  name={allergen.ingredient_id}
                                                            //  checked={checkboxes.find(checkbox => checkbox.ingredient_id === allergen.ingredient_id)?.checked || false}
                                                            value={checkboxes}
                                                            onChange={(e) => {
                                                                // add to list
                                                                if (e.target.checked) {
                                                                    setCheckboxes([
                                                                        ...checkboxes,
                                                                        {
                                                                            id: allergen.ingredient_id,
                                                                            name_en: allergen.ingredient_name_en,
                                                                            name_de: allergen.ingredient_name_de,
                                                                            name_fr: allergen.ingredient_name_fr,
                                                                            name_it: allergen.ingredient_name_it
                                                                        },
                                                                    ]);
                                                                } else {
                                                                    // remove from list
                                                                    setCheckboxes(
                                                                        checkboxes.filter((item) => item.id !== allergen.ingredient_id),
                                                                    );
                                                                }
                                                            }} />
                                                        <span>{allergen['ingredient_name_' + i18n.language]}</span>
                                                    </label>
                                                ))
                                            }

                                        </div>
                                        <div className="product-count">
                                            <label>Quantity</label>
                                            <div className="display-flex">
                                                <div className="qtyminus" onClick={() => DecreaseItems()}>-</div>
                                                <input type="text" name="quantity" value={quantity} className="qty" />
                                                <div className="qtyplus" onClick={() => AddItems()}>+</div>
                                                <input type="hidden" value="88" name="product_id" />
                                            </div>
                                        </div>
                                        <button type="button" onClick={() => addToCart()} className="round-black-btn" style={{ backgroundColor: '#ea1d25', border: '1px solid #ea1d25' }}>Add to Cart</button>
                                        <button type='button' onClick={() => addToWishlist(data.product_id)} className="round-black-btn ms-1">Add to Wishlist</button>
                                    </div>
                                </form>
                            </div>
                            {/* Main Content End */}
                        </div>
                    </div>
                    {
                        data && <ProductDesRevNut data={data} nutrients={nutrients} reviews={reviews} />
                    }

                    {
                        popularProducts.length > 0 && <PopularProduct popularProducts={popularProducts} />
                    }
                </>
            }





            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header>
                    <Modal.Title>{t("Delete previous cart?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <p>{t("You still have products from another category. Shall we start over with a fresh cart!")}</p>
                    <button type="button" className="btn btn-dark" style={{ backgroundColor: '#ea1d25', border: "1px solid #ea1d25" }} onClick={() => handleClose('1')}>Remove</button>
                    <button type="button" className="btn btn-dark ms-1" onClick={() => handleClose('0')}>Don't Remove</button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SingleItemDetail