import React, { useState } from "react"

import { styles } from "../styles"



const EmailForm = props => {    
    const [email, setEmail] = useState('')



    function handleSubmit(event) {
        event.preventDefault();
        props.setUser && props.setUser(email)
        sessionStorage.setItem('chat', email)
    }

    return (
        <div 
            style={{
                ...styles.emailFormWindow,
                ...{ 
                    height: props.visible ? '100%' : '0px',
                    opacity: props.visible ? '1' : '0'
                }
            }}
        >
            <div style={{ height: '0px' }}>
                <div style={styles.stripe} />
            </div>

           
           

            <div style={{ position: 'absolute', height: '100%', width: '100%', textAlign: 'center' }}>
                {/* <img src="http://127.0.0.1:8000/storage/media/1673940860_logo.png" 
                    style={{ 
                        position: 'relative',
                        left: '0%',
                        top: '10%',
                    }}
                /> */}
                <i className="fas fa-times" style={{ float:'right',paddingRight:'10px',paddingTop:'10px',color:'#fff' }} onClick={() => props.setVisible(false)}></i>
                 <p
                    style={{ 
                        position: 'relative',
                        left: '0%',
                        top: '10%',
                        color:'#000',
                        fontWeight:'bold',
                        fontSize: '23px'
                    }}
                >JETZT EAT</p>

                <div style={styles.topText}>
                    Welcome to <br /> customer support
                </div>

                <form 
                    onSubmit={e => handleSubmit(e)}
                    style={{ position: 'relative', width: '100%', top: '19.75%' }}
                >
                    <input 
                        placeholder='Your Email'
                        onChange={e => setEmail(e.target.value)}
                        style={styles.emailInput}
                    />
                </form>
                
                <div style={styles.bottomText}>
                    Enter your email <br /> to get started.
                </div>
            </div>
        </div>
    )
}

export default EmailForm;