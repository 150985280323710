import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { server } from '../../util/server';
import { toast } from 'react-toastify';
import { useFilterContext } from '../../context/filterContext';
import { useTranslation } from 'react-i18next';
const CateringCheckoutForm3 = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { media_server, project, isUserLogin } = useFilterContext();
    const [dateTimeList, setdateTimeList] = useState([{ date: '', event_start_time: '', event_end_time: '' }]);
    const [rooms, setRooms] = useState()
    const [venues, setVenues] = useState([])
    const [roomsValue, setRoomsValue] = useState()
    const [venuePrice, setVenuePrice] = useState(0)
    const [venuePricePerRoom, setVenuePricePerRoom] = useState(0)
    const [venueTotalPrice, setVenueTotalPrice] = useState(0)
    const [venueID, setVenueID] = useState(null)
    const [spaceRequired, setSpaceRequired] = useState()
    const [delegates, setDelegates] = useState()
    const [roomSetUp, setRoomSetUp] = useState()
    const [audioVisualNeed, setAudioVisualNeed] = useState()
    const [outsideCatering, setOutsideCatering] = useState(0)
    const [extraSetUp, setExtraSetUp] = useState([])
    const handleExtraSetUP = (e) => {
        const list = [...extraSetUp];
        list.push(e.target.value)
        setExtraSetUp(list);
    }

    const getVenuesDetail = async () => {
        try {
            let url = server + 'auth/web/catering-checkout/third-step-form-get-data?project=' + project
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setVenues(data.data)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            //  return toast.error(t("Unexpected error occurred!"))
        }
    }

    const getSingleVenueData = async (id) => {
        setVenueID(id)
        try {
            let url = server + 'auth/web/catering-checkout/get-selected-venue-data?venue_id=' + id
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setRooms(data.data)
                setVenuePrice(data.venue_price)
                setVenuePricePerRoom(data.price_per_room)
                setVenueTotalPrice(data.venue_price)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            //  return toast.error(t("Unexpected error occurred!"))
        }
    }

    const handleTotalPrice = (rooms) => {
        var total_price_of_rooms = 0;
        total_price_of_rooms = rooms * venuePricePerRoom
        setVenueTotalPrice(venuePrice + total_price_of_rooms)
    }


    let roomsList = [];
    for (let index = 1; index <= rooms; index++) {
        roomsList.push(<option key={index} value={index}>{index}</option>)
    }
    let datesList = []
    const getEventDateTimeDetails = async () => {
        try {
            let url = server + 'auth/web/catering-checkout/second-step-form-get-data?catering_cart_id=' + localStorage.getItem('catering_cart_id')
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                let event_dates = data.data
                event_dates.map((item, key) => (
                    datesList.push({ date: item.event_date, event_start_time: item.event_start_time, event_end_time: item.event_end_time })
                ))
                setdateTimeList(datesList)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            //  return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (isUserLogin && localStorage.getItem('catering_cart_id')) {
            getVenuesDetail()
            getEventDateTimeDetails()
        }
        else {
            navigate('/catering')
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (venueID === null) {
            toast.error('Please Select a Venue to proceed further!')
        }
        else {
            try {
                let res = await axios.post(server + "auth/web/catering-checkout/third-step-form-submit", { 'catering_cart_id': localStorage.getItem('catering_cart_id'), 'venue_id': venueID, 'outside_catering': outsideCatering, 'space_Required': spaceRequired, 'audio_visual_need': audioVisualNeed, 'delegates': delegates, 'num_of_rooms': roomsValue, 'rooms_Setup': roomSetUp, 'extra_setup': extraSetUp, 'eventDateTimeArray': dateTimeList });
                let data = await res.data;
                if (data.status === 0) {
                    if (data.message) { toast.error(t(data.message)) }
                    if (data.errors) {
                        Object.keys(data.errors).forEach(key => {
                            data.errors[key].map(item => {
                                toast.error(item);
                            })
                        })
                    }
                } else {
                    toast.success(data.message)
                    localStorage.removeItem('catering_cart')
                    localStorage.removeItem('catering_food_plan_cart')
                    localStorage.removeItem('catering_cart_id')
                    localStorage.removeItem('caterer_id')
                    navigate('/catering-menu')
                    
                }
            } catch (error) {
                toast.error('Unexpected error occurred!')
            }
        }
    }

    return (
        <>
            {/* Banner section Start */}
            <section className="banner-n" style={{ backgroundImage: `url('/assets/front/images/contact-us.jpg')`, backgroundRepeat: "no-repeat" }}>
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end">
                        <div className="col-lg-10 text-center mx-auto wow slideInLeft" data-wow-delay="0.1s text-center" style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                            <h1 className="color-red">Catering Checkout -  Step 3</h1>
                            <ul className="bread">
                                <li><b><Link to="/">Home</Link></b></li>
                                <li className="color-red"><b>Catering Checkout -  Step 3</b></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner section End */}

            {/* Main section Start */}
            <section className="bg-red">
                <div className="container" style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                    <div className="col-12">
                        <ul className="bread mb-2" id="custom-bread">
                            <li><b><Link to="/catering-cart" className="color-black">Cart</Link></b></li>
                            <li><b><Link to="/catering-checkout/step-1" className="color-black">Step 1</Link></b></li>
                            <li><b><Link to="/catering-checkout/step-2" className="color-black">Step 2</Link></b></li>
                            <li className="color-white"><b>Step 3</b></li>
                        </ul>
                    </div>

                    <div className="contact-wrap" style={{ paddingTop: "5%", paddingBottom: "5%", paddingLeft: "5%", paddingRight: "5%" }}>
                        <div className="col-md-12 ">
                            <div className="form2 h-100">
                                <h2 className="site-font text-center">Step:3</h2>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3 className="site-font">Venue Booking</h3>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <b className="">Charges: </b>
                                        CHF <span id="venue_charges">{venueTotalPrice}</span>
                                    </div>
                                </div>
                                <form className="mb-1" id="contactForm" name="contactForm" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <h5>Please click below to choose any venue</h5>
                                        </div>

                                        {venues && venues.length > 0 &&
                                            venues.map((item, key) => (
                                                <div key={key} className="col-md-4 mb-3">
                                                    <div className="card card-vn-nz-2" style={{ border: venueID===item.venue_id ? '3px solid #ea1d25' : '' }} onClick={() => { getSingleVenueData(item.venue_id) }} id="card-vz-">
                                                        <img src={media_server + item.venue_image} className="card-img-top" alt={item.name} height="200" />
                                                        <div className="card-body">
                                                            <h5 className="card-title fw-bold text-uppercase">{item.name}</h5>
                                                            <div className="row">
                                                                <div className="col-md-6 text-start color-black">
                                                                    <i className="fa fa-arrow-circle-right" aria-hidden="true" style={{ fontSize: "12px" }}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        <div className="col-12"></div>

                                        <div className="col-md-6 form-group mb-2">
                                            <label htmlFor="" className="col-form-label">Space Required</label>
                                            <textarea name="space_Required" onChange={(e) => setSpaceRequired(e.target.value)} rows="3" style={{ height: "140px" }} className="form-control"></textarea>
                                            <input type="hidden" name="venue_id" id="venue_id" defaultValue="" />
                                            <input type="hidden" name="venue_total" id="venue_total_id" defaultValue="" />
                                            <input type="hidden" name="venue_room_charges" id="venue_room_charges_id" defaultValue="" />
                                        </div>

                                        <div className="col-md-6 form-group mb-2">
                                            <label htmlFor="" className="col-form-label">
                                                Do you have any delegates with special needs (i.e.
                                                wheelchair-bound, allergies, etc.)?
                                            </label>
                                            <textarea name="delegates" rows="3" onChange={(e) => setDelegates(e.target.value)} style={{ height: "140px" }} className="form-control"></textarea>
                                        </div>

                                        <div className="col-md-6 form-group mb-2 outside_venue">
                                            <label htmlFor="" className="col-form-label">Do you want to take the rooms? If yes then please select</label>
                                            <select name="num_of_rooms" onChange={(e) => { setRoomsValue(e.target.value); handleTotalPrice(e.target.value) }} className="form-control" id="num_of_rooms">
                                                <option>Select Rooms</option>
                                                {roomsList && roomsList}
                                            </select>
                                        </div>

                                        <div className="col-md-6 form-group mb-2">
                                            <label htmlFor="" className="col-form-label">Room Setup</label>
                                            <select name="rooms_Setup" onChange={(e) => setRoomSetUp(e.target.value)} className="form-control" id="">
                                                <option defaultValue="Theater (No Tables)">Theater (No Tables)</option>
                                                <option defaultValue="Classroom (tables and chairs all facing the front)">Classroom (tables and chairs all facing the front)</option>
                                                <option defaultValue="Boardroom style (one table with chairs all around)">Boardroom style (one table with chairs all around)</option>
                                                <option defaultValue="U-Shape (tables with chairs in a U facing the front)">U-Shape (tables with chairs in a U facing the front)</option>
                                                <option defaultValue="Cabaret (round tables with chairs facing the front)">Cabaret (round tables with chairs facing the front)</option>
                                                <option defaultValue="Any Style">Any Style</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 form-group mb-2">
                                            <label htmlFor="" className="col-form-label">Please include</label>
                                            <div className="form-check">
                                                <input className="form-check-input" onChange={(e) => { handleExtraSetUP(e) }} type="checkbox" name="extra_setup[]" defaultValue="A podium at front of the room" />
                                                <label className="form-check-label">A podium at front of the room</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" onChange={(e) => { handleExtraSetUP(e) }} type="checkbox" name="extra_setup[]" defaultValue="A table at the back of the room for handouts" />
                                                <label className="form-check-label">A table at the back of the room for handouts</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" onChange={(e) => { handleExtraSetUP(e) }} type="checkbox" name="extra_setup[]" defaultValue="A head table at the front" />
                                                <label className="form-check-label">A head table at the front</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" onChange={(e) => { handleExtraSetUP(e) }} type="checkbox" name="extra_setup[]" defaultValue="A hospitality table outside the room for name badges or handouts" />
                                                <label className="form-check-label">A hospitality table outside the room for name badges or handouts</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 form-group mb-2 ">
                                            <label htmlFor="" className="col-form-label">
                                                Audio Visual needs?
                                            </label>
                                            <textarea name="audio_visual_need" onChange={(e) => setAudioVisualNeed(e.target.value)} style={{ height: "115px" }} className="form-control"></textarea>
                                        </div>
                                        {localStorage.getItem('catering_cart_id') === null &&
                                            <div className="col-md-12 form-group mb-2">
                                                <label htmlFor="" className="col-form-label">Are you using outside Catering?</label><br />
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" onChange={() => setOutsideCatering(0)} name="outside_catering" defaultValue="0" defaultChecked />
                                                    <label className="form-check-label">No</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" onChange={() => setOutsideCatering(1)} name="outside_catering" defaultValue="1" />
                                                    <label className="form-check-label">Yes</label>
                                                </div>
                                                <br />
                                                <small><b className="color-red">Note:</b> In case of outsourcing the catering extra charges will be applicable</small>
                                            </div>

                                        }

                                        <div className="col-12 form-group mt-3">
                                            <input type="submit" defaultValue="Request Booking" name="third Step" className="btn btn-danger py-2 px-4 text-center follow-1 bg-red" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Main section End */}
        </>
    )
}

export default CateringCheckoutForm3