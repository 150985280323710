import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OwlCarousel from 'react-owl-carousel'
import { useTranslation } from 'react-i18next';
export const ProductDesRevNut = ({data,nutrients,reviews}) => {
    // console.log(reviews);
    const { i18n, t } = useTranslation();
    const options = {
        loop: false,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 1500,
        responsive: {
            0: {
                margin: 0,
                items: 1,
            },
            576: {
                margin: 3,
                items: 1,
            },
            768: {
                margin: 3,
                items: 3,
            },
            992: {
                margin: 3,
                items: 3,
            },
            1200: {
                margin: 3,
                items: 3,
            },
        },
    };
  return (
    <>
    
             {/* Description Section Start */}
             <section style={{ backgroundColor: '#fff' }} id="desk_desc">
                <div className="product-container py-5">
                    <Tabs
                        defaultActiveKey="description"
                        id="uncontrolled-tab-example"
                        className="mb-3 tabs"
                    >
                        <Tab eventKey="description" title="Description" className="tabby-tab">
                            <p className='text-center'>
                                {
                                     data['product_desc_'+i18n.language] ?
                                     data['product_desc_'+i18n.language] :
                                     t("No Description Found!") 
                                }</p>
                        </Tab>
                        <Tab eventKey="nutrients" title="Nutrients" className="tabby-tab">
                            {
                                nutrients.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table table-bordered text-center" border="1" align="center" cellSpacing="10" cellPadding="10" style={{ color: '#000', borderColor: '#000', width: '500px' }}>
                                        <tbody>
                                            {
                                                nutrients.map(item => (
                                                    <tr>
                                                        <th>{item['ingredient_name_'+i18n.language]}</th>
                                                        <td>{item['ingredient_value']} </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                t("No Nutrients Information Found!") 
                            }
                           
                        </Tab>
                        <Tab eventKey="reviews" title="Reviews" className="tabby-tab text-center">
                            {
                                reviews.length > 0 ?
                                <OwlCarousel className='testimonial-carousel wow fadeInUp' {...options}>
                                    {
                                        reviews.map(singItem => (
                                            <div className="row item active text-center p-3" style={{ alignItems: 'center' }}>
                                                <div className='col-12'>
                                                    <img src='https://demo.ninjashushi.ch/backend/front/images/profile-14.png' alt='' style={{ borderRadius: '50%', margin: 'auto', width: '70px', height: '70px', border: '3px solid #64bc46', clear: 'both' }} />
                                                </div>
                                                <div className="col-12 mt-1">
                                                    <ul className="rating" style={{ display: 'inline', paddingLeft: '0rem' }}>
                                                        <li className={1 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                        <li className={2 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                        <li className={3 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                        <li className={4 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                        <li className={5 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                    </ul>
                                                    <p className="fw-bold mb-1">{singItem.review_data}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </OwlCarousel>
                                :
                                t("No Review Found!") 
                            }
                           
                        </Tab>
                    </Tabs>


                </div>
            </section>
            {/* Description Section End */}

            {/* Product Mobile Description start */}
            <section style={{ backgroundColor: '#fff' }} className="mob_desc">
                <div className="product-container py-5">
                    <h3>Description</h3>
                    <p>
                    {
                                     data['product_desc_'+i18n.language] ?
                                     data['product_desc_'+i18n.language] :
                                     t("No Description Found!") 
                                }
                    </p>
                </div>
            </section>
            {/* Product Mobile Description End */}

            {/* Nutrients Mobile Section start */}
            <section className="bg-whitesmoke mob_desc">
                <div className="product-container py-5">
                    <h3>Nutrients</h3>
                    {
                                nutrients.length > 0 ?
                                nutrients.map(item => (
                                    <>
                                    <b className='me-2'>{item['ingredient_name_'+i18n.language]}:</b>{item['ingredient_value']} <br />
                                    </>
                                ))
                                :
                                t("No Nutrients Information Found!") 
                            }
                    
                </div>
            </section>
            {/* Nutrients Section End */}

            {/* Reviews Mobile Section start */}
            <section style={{ backgroundColor: '#fff' }} className="mob_desc">
                <div className="product-container py-5">
                    <h3>Reviews</h3>
                        {
                                reviews.length > 0 ?
                                <OwlCarousel className='testimonial-carousel wow fadeInUp' {...options}>
                                    {
                                        reviews.map(singItem => (
                                            <div className="row item active text-center p-3" style={{ alignItems: 'center' }}>
                                                <div className='col-12'>
                                                    <img src='https://demo.ninjashushi.ch/backend/front/images/profile-14.png' alt='' style={{ borderRadius: '50%', margin: 'auto', width: '70px', height: '70px', border: '3px solid #64bc46', clear: 'both' }} />
                                                </div>
                                                <div className="col-12 mt-1">
                                                    <ul className="rating" style={{ display: 'inline', paddingLeft: '0rem' }}>
                                                        <li className={1 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                        <li className={2 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                        <li className={3 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                        <li className={4 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                        <li className={5 <= singItem.review_rating ? "fas fa-star color-red" : "fas fa-star color-lightgrey"}></li>
                                                    </ul>
                                                    <p className="fw-bold mb-1">{singItem.review_data}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </OwlCarousel>
                                :
                                t("No Review Found!") 
                        }
                </div>
            </section>
            {/* Reviews Mobile Section End */}
    
    
    </>
  )
}
