import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import axios from 'axios'
import { server } from '../../../util/server'
import { useFilterContext } from '../../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../../Components/Spinner';

const PlannerWiseOrderDetail = () => {
    const { meal_planner_days_id } = useParams()
    //console.log(meal_planner_days_id);
    const [spinner, setSpinner] = useState(true);
    const { project, isUserLogin } = useFilterContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [plannerData, setPlannerData] = useState()

    const getSinglePlanner = async () => {
        try {
            let url = server + 'auth/web/meal-planner/single-order?api_type=web&meal_planner_days_id=' + meal_planner_days_id + '&user_id=' + isUserLogin + '&project=' + project
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                //console.log(data);
                setPlannerData(data)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }
    //console.log(plannerData);

    useEffect(() => {
        if (isUserLogin) {
            getSinglePlanner()
        }
        else {
            navigate('/login')
        }
    }, [])

    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner />
                        :
                            <>
                                <h3>Meal Planner Order</h3>
                                <hr />
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <tbody>
                                            {/* Item 1 */}
                                            <tr className="bg-red color-white text-center">
                                                <td colSpan="3" className='py-2 px-2'>Meal 2, 2023-04-05</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-6 col-12 mb-2">
                                                            <b>Time: </b> 2:10 pm
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12 mb-2">
                                                            <b>Restaurant: </b>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12 mb-2">
                                                            <b>Rider: </b> Sachin
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12 mb-2">
                                                            <b>Order Status:</b>
                                                            <span className="badge bg-success ms-1">Delivered</span>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="bg-secondary color-white text-center">
                                                <td className='py-2 px-2'>Name</td>
                                                <td className='py-2 px-2'>Qty</td>
                                                <td className='py-2 px-2'>Price</td>
                                            </tr>
                                            <tr>
                                                <td className='py-2 px-2'>
                                                    Valtelina<br />
                                                </td>
                                                <td className='py-2 px-2'>Qty: 3</td>
                                                <td className='py-2 px-2'>CHF 59</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" style={{ textAlign: "right", fontWeight: "bold" }}>Delivery Address</td>
                                                <td className='py-2 px-2'>50, street, 9000, Winterthur</td>
                                            </tr>
                                            <tr>
                                                <td className='py-2 px-2' colSpan="2" style={{ textAlign: "right", fontWeight: "bold" }}>Standard</td>
                                                <td className='py-2 px-2'>CHF 20</td>
                                            </tr>
                                            <tr>
                                                <td className='py-2 px-2' colSpan="2" style={{ textAlign: "right", fontWeight: "bold" }}>Total</td>
                                                <td className='py-2 px-2'>CHF 172</td>
                                            </tr>
                                            {/* Item 2 */}
                                            <tr className="bg-red color-white text-center">
                                                <td colSpan="3" className='py-2 px-2'>Meal 2, 2023-04-05</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-6 col-12 mb-2">
                                                            <b>Time: </b> 2:10 pm
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12 mb-2">
                                                            <b>Restaurant: </b>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12 mb-2">
                                                            <b>Rider: </b> Sachin
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12 mb-2">
                                                            <b>Order Status:</b>
                                                            <span className="badge bg-success ms-1">Delivered</span>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="bg-secondary color-white text-center">
                                                <td className='py-2 px-2'>Name</td>
                                                <td className='py-2 px-2'>Qty</td>
                                                <td className='py-2 px-2'>Price</td>
                                            </tr>
                                            <tr>
                                                <td className='py-2 px-2'>
                                                    Valtelina<br />
                                                </td>
                                                <td className='py-2 px-2'>Qty: 3</td>
                                                <td className='py-2 px-2'>CHF 59</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" style={{ textAlign: "right", fontWeight: "bold" }}>Delivery Address</td>
                                                <td className='py-2 px-2'>50, street, 9000, Winterthur</td>
                                            </tr>
                                            <tr>
                                                <td className='py-2 px-2' colSpan="2" style={{ textAlign: "right", fontWeight: "bold" }}>Standard</td>
                                                <td className='py-2 px-2'>CHF 20</td>
                                            </tr>
                                            <tr>
                                                <td className='py-2 px-2' colSpan="2" style={{ textAlign: "right", fontWeight: "bold" }}>Total</td>
                                                <td className='py-2 px-2'>CHF 172</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlannerWiseOrderDetail