import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../util/server'
import { useFilterContext } from '../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Components/Spinner'
const Wishlist = () => {
    const [spinner, setSpinner] = useState(true);
    const { t } = useTranslation();
    const { updatemetas, updatePageBanner, page_banner, media_server, project, setCartTotalCount, isUserLogin } = useFilterContext();
    const [wishlist, setWishlist] = useState([])
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [deleteFromCart, setDeleteFromCart] = useState(null)
    const handleShow = (item) => setShow(true);

    const getWishListData = async () => {
        try {
            let url = server + 'auth/web/wishlist?api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data) {
                setWishlist(data.data)
                setSpinner(false)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const removeFromWishList = async (id) => {
        try {
            let url = server + 'auth/web/remove-from-wishlist?wishlist_id=' + id
            let res = await axios.post(url);
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }

                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                toast.success('Item Removed from Wishlist!')
                getWishListData()
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getWishListData()
        }
        else {
            navigate('/login')
        }
    }, [])

    const addToCart = (product_id) => {
        let url = server + "auth/cart/add-to-cart"
        axios.post(url, { 'food_id': product_id, 'api_type': 'web', 'quantity': 1, 'project': project, 'user_id': isUserLogin }).then(function (result) {
            if (result.data.status === 0) {
                toast.error(t(result.data.message));
            } else {
                toast.success(t(result.data.message));
                setCartTotalCount(result.data.cart_count);
                sessionStorage.removeItem('cart_res', result.data.restaurant_id)
                sessionStorage.setItem('cart_res', result.data.restaurant_id)
            }
            setDeleteFromCart(null)
        })
            .catch(function (error) {
                toast.error(t("Unexpected error occurred!"));
            });
    }
    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Wishlist</h3>
                                <hr />
                                <div className="table-responsive">
                                    <table className="table table-bordered text-center">
                                        <thead>
                                            <tr>
                                                <th colSpan="2">Product</th>
                                                <th>Price</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wishlist && wishlist.length > 0 ?
                                                wishlist.map((item, key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <img src={media_server + "/" + item.wishlist_product.product_image} height="100px" alt={item.wishlist_product.product_name} />
                                                        </td>
                                                        <td> {item.wishlist_product && item.wishlist_product.product_name}</td>
                                                        <td> CHF {item.wishlist_product && item.wishlist_product.product_price}</td>
                                                        <td>
                                                            <form action="" method="POST" id="myform">
                                                                <input type="hidden" name="quantity" value="1" />
                                                                <input type="hidden" value="" name="product_id" />

                                                                <button type="button" onClick={() => addToCart(item.product_id)} className="btn btn-danger"><i className="fa fa-shopping-cart"></i></button>
                                                                <Link to="#" onClick={() => removeFromWishList(item.wishlist_id)} className="btn btn-dark ms-1"><i className="fa fa-trash"></i></Link>
                                                            </form>
                                                        </td>
                                                    </tr>
                                                )) : 
                                                (
                                                    <tr>
                                                        <td colSpan={4}>Your wishlist is empty!</td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default Wishlist