import React from 'react'
import { useFilterContext } from '../context/filterContext';
import { SingleItem } from './SingleItem'

export const MenuItemList = ({ data, mainSlug, subCatSlug }) => {
    const { productSortHandler } = useFilterContext();
    return (
        <>
            <div className="row">
                <div className="col-xl-9 col-lg-8 col-md-6 col-12 text-center">
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 text-center mb-3">
                    <select name="sortby" className="form-control" onChange={productSortHandler}>
                        <option value="default">Default Sorting</option>
                        <option value="name_asc">Sort By Name (Asc)</option>
                        <option value="name_desc">Sort By Name (Desc)</option>
                        <option value="rating">Sort By Rating</option>
                        <option value="price_asc">Sort By Price (Low to High)</option>
                        <option value="price_desc">Sort By Price (High to Low)</option>
                    </select>
                </div>
                {/* sorting */}
                {
                    data && data.length === 0 && <div className="col-12 text-center"><p>No Food Found!</p></div>
                }
                {
                    data && data.length > 0 && data.map(item => (
                        <div className="col-lg-4 col-sm-6 mb-2 wow fadeInUp px-sm-2" data-wow-delay="0.2s" key={item.slug}>
                            <SingleItem product={item} />
                        </div>
                    ))
                }

            </div>


        </>
    )
}
