import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useFilterContext } from '../../context/filterContext';
import { server } from '../../util/server';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CateringFoodPlan from './CateringFoodPlan'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
const CateringMenuPlan = () => {
    const [cateringFoodSection, setCateringFoodSection] = useState(true)
    const [foodPlanId, setFoodPlanId] = useState('')
    const [cateringFoodPlanSection, setCateringFoodPlanSection] = useState(false)
    const { t } = useTranslation();
    const { slug } = useParams();
    const menu_plan = slug.replace('menu-plan-', '')
    const { project, media_server } = useFilterContext();
    const [pageData, setPageData] = useState([]);
    const [cateringFood, setCateringFood] = useState([]);
    const [cateringCategories, setCateringCategories] = useState([]);
    const [foodPlans, setFoodPlans] = useState([]);
    const [cateringFoodCart, setCateringFoodCart] = useState();

    const getData = async () => {
        try {
            let url = server + 'auth/web/catering/menu-plan-data?menu_plan=' + menu_plan + '&api_type=web&project=' + project
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setPageData(data)
                setCateringFood(data.catering_foods)
                setCateringCategories(data.catering_categories)
                setFoodPlans(data.food_plans)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }
    const filterData = (value) => {
        if (value === 'All') {
            setCateringFood(pageData.catering_foods)
        }
        else {
            const filteredList = pageData.catering_foods.filter(item => {
                return item.category === value.toString();
            });
            setCateringFood(filteredList)
        }
    }
    const splitNutrientsArray = (nutrientsList) => {
        let nutrientsArray = nutrientsList.split('|')
        let newnutrientsArray = nutrientsArray.map((foodItem, foodKey) => {
            return foodItem.split('/')
        })
        return newnutrientsArray
    }

    const addToCart = (food_id, food_type, food_name, food_price, food_image, caterer_id) => {
        let url = server + "auth/web/catering/add-to-cart"
        axios.post(url, { 'food_id': food_id, 'food_type': food_type, 'food_name': food_name, 'price': food_price, 'image': food_image, 'caterer_id': caterer_id, 'catering_food_cart_array': JSON.parse(localStorage.getItem('catering_cart')) }).then(function (result) {
            if (result.data.status === 0) {
                toast.error(t(result.data.message));
            } else {
                setCateringFoodCart(result.data.catering_food_cart_array)
                var caterer_id = Object.values(result.data.catering_food_cart_array)[0].caterer_id;
                localStorage.setItem('caterer_id', caterer_id)
                var catering_cart = JSON.stringify(result.data.catering_food_cart_array)
                localStorage.setItem('catering_cart', catering_cart)
                toast.success('Food is added to cart!');
            }
        })
        .catch(function (error) {
            toast.error(t("Unexpected error occurred!"));
        });
    }

    useEffect(() => {
        getData()
    }, [slug])

    return (
        <>
            {/* Banner section Start */}
            <section className="banner-n catering_menu_plan_image">
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end">
                        <div className="col-lg-12">
                            <div className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                <h1 className="color-red">{"Menu Plan " + menu_plan}</h1>
                                <ul className="bread">
                                    <li><b><Link to="/">Home</Link></b></li>
                                    <li><b><Link to="/catering">Catering</Link></b></li>
                                    <li><b><Link to="/catering-menu">Catering Food & Drinks</Link></b></li>
                                    <li className="color-red"><b>{"Menu Plan " + menu_plan}</b></li>
                                </ul>
                                <Link to="/catering-cart" className="btn btn-danger">Go To Cart</Link>
                                {cateringFoodPlanSection &&
                                    <Link to="#" className="btn btn-success ms-1" onClick={() => { setCateringFoodPlanSection(false); setCateringFoodSection(true) }}>Back</Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner section End */}

            {/* We Serve Section Start */}
            <section className="fd">
                <div className="container">
                    <div className="container py-5 color-white">
                        <div className="row ">
                            {foodPlans && foodPlans.length > 0 &&
                                <div className="col-12 mb-5">
                                    <div className="row">
                                        {foodPlans.map((item, key) => (
                                            <div key={key} className="col-md-4 color-black text-center">
                                                <Link to="#" onClick={() => { setCateringFoodPlanSection(true); setFoodPlanId(item.id); setCateringFoodSection(false) }} className="color-black">
                                                    <img src="https://demo.ninjashushi.ch/backend/front/images/catering-food-plan.png" width="100%" height="200px" alt="" />
                                                    <h4 className="mb-0 mt-2">{item.name}</h4>
                                                    <h6>CHF {item.plan_price_per_head}/<sub>Per Head</sub></h6>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {cateringFoodSection &&
                                <>
                                    <div className="col-lg-3 col-md-4 m-account p-2 accordion-menu-bg color-white sd-border">
                                        <h3 className="text-center fd-sidebar-heading">Dishes</h3>
                                        {/* <hr />
                                        <h4 className="d-block sidebar color-white"><b>Search</b></h4>
                                        <div className="container mt-3">
                                            <div className="row d-flex justify-content-center ">
                                                <div className="">
                                                    <div className="">
                                                        <div className="input-box">
                                                            <input type="text" placeholder="Search" className="form-control" id="catering-mrnu-search" />
                                                            <Link to=""><i className="fa fa-search"></i> </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <hr />
                                        <h4 className="d-block sidebar color-white">
                                            <b>Category</b></h4>
                                        <Link to="#" className="d-block sidebar dropdownlink dropdownlink-clr" onClick={() => filterData('All')}>All </Link>
                                        {cateringCategories &&
                                            cateringCategories.map((item4, key4) => (
                                                <div key={key4}>
                                                    {item4.subcategory.length > 0 ?
                                                        (<Link to={"#starter-" + item4.id} className="d-block sidebar dropdownlink dropdownlink-clr" data-bs-toggle="collapse" aria-expanded="true">
                                                            {item4.name}<i className="fas fa-angle-down float-end " style={{ float: "right" }}></i>
                                                        </Link>
                                                        )
                                                        :
                                                        (<Link to="#" onClick={() => filterData(item4.id)} className="d-block sidebar dropdownlink dropdownlink-clr">Desserts </Link>)}
                                                    <div className="collapse" id={"starter-" + item4.id}>
                                                        {item4.subcategory.length > 0 &&
                                                            item4.subcategory.map((item5, key5) => (
                                                                <Link to="#" onClick={() => filterData(item5.id)} className="d-block sidebar ms-4 dropdownlink dropdownlink-clr">{item5.name}</Link>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {/* Start Dishes Section */}
                                    <div className="tab-content col-lg-9 col-md-8 col-sm-12">
                                        <div id="tab-1" className="tab-pane fade show p-0 active">
                                            <div className="row g-4">
                                                {cateringFood.length > 0 ?
                                                    cateringFood.map((item1, key1) => (
                                                        <div key={key1} className="col-md-6 col-sm-6 col-xs-12 wow fadeInUp pt-4" data-wow-delay="0.2s">
                                                            <div className="product-grid fd-shadow">
                                                                <div className="product-image">
                                                                    <Link to="#" className="image">
                                                                        <img className="pic-1 img-fluid" style={{ height: '230px' }} src={media_server + '/' + item1.image} alt={item1.name} />
                                                                    </Link>
                                                                </div>
                                                                <div className="dishes-name">
                                                                    <h5 className="text-center color-red"><b>{item1.name}</b></h5>
                                                                </div>
                                                                <div className="product-content-fd fd-card-clr">
                                                                    <h3 className="title text-center justify-content-center fd-price">
                                                                        CHF {item1.price}
                                                                    </h3>
                                                                    <div className="product-content-fd">
                                                                        <a className="btn btn-danger fd-btn" data-bs-toggle="modal" href={"#catering_food_popup_" + item1.id} role="button">Quick View</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal fade " id={"catering_food_popup_" + item1.id} aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                                                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg ">
                                                                    <div className="modal-content bg-red-gradient">
                                                                        <div className="fd-popup-1">
                                                                            <div type="button" className=" float-end fd-popup-2" data-bs-dismiss="modal" aria-label="Close">
                                                                                <i className="fa fa-times-circle color-white fd-popup-3"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <div className="row ">
                                                                                <div className="col-lg-4 ">
                                                                                    <div className="fd-popup-4">
                                                                                        <img src={media_server + '/' + item1.image} className="fd-popup-img" alt={item1.name} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-8 color-white ">
                                                                                    <h3 className="mb-0">{item1.name}</h3>
                                                                                    <h5 className="color-black">CHF {item1.price}</h5>
                                                                                    {item1.allergens &&
                                                                                        <>
                                                                                            <p className="fd-popup-6">Allergens:</p>
                                                                                            <div className="my-container-fd-popup fd-popup-7">
                                                                                                <label>
                                                                                                    <input defaultValue={item1.allergens} />
                                                                                                    <span className="color-white color-white fd-popup-8">{item1.allergens}</span></label>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    {(Number(localStorage.getItem('caterer_id')) === item1.caterer_id) ? (
                                                                                        <button className="btn btn-success fd-popup-btn" type="button" onClick={() => addToCart(item1.id, 'Dishes', item1.name, item1.price, item1.image, item1.caterer_id)}>Add to Cart</button>
                                                                                    ) : (
                                                                                        <button className="btn btn-success fd-popup-btn" type="button" onClick={() => { if (window.confirm('Adding this dish to cart will remove all other dishes from another plan. Are you sure you want to continue?')) { localStorage.removeItem('catering_cart'); localStorage.removeItem('caterer_id'); addToCart(item1.id, 'Dishes', item1.name, item1.price, item1.image, item1.caterer_id) }; }}>Add to Cart</button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="fd-popup-9">
                                                                                <section style={{ backgroundColor: '#fff' }} id="desk_desc">
                                                                                    <div className="product-container catering-food-modal">
                                                                                        <Tabs
                                                                                            defaultActiveKey="description"
                                                                                            id="uncontrolled-tab-example"
                                                                                            className="mb-3 tabs text-center"
                                                                                        >
                                                                                            <Tab eventKey="description" title="Description" className="tabby-tab text-center">
                                                                                                <p className='text-center text-black pb-4'>
                                                                                                    {item1.description}
                                                                                                </p>
                                                                                            </Tab>
                                                                                            <Tab eventKey="nutrients" title="Nutrients" className="tabby-tab text-center">
                                                                                                <div className="table-responsive">
                                                                                                    <table className="table table-bordered text-center" border="1" align="center" cellSpacing="10" cellPadding="10" style={{ color: '#000', borderColor: '#000', width: '500px' }}>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                splitNutrientsArray(item1.nutrients).map((item3, key3) => (
                                                                                                                    <tr key={key3}>
                                                                                                                        <th>{item3[0]}</th>
                                                                                                                        <td>{item3[1]}</td>
                                                                                                                    </tr>
                                                                                                                ))
                                                                                                            }
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </Tab>
                                                                                        </Tabs>
                                                                                    </div>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) :
                                                    (
                                                        <p className='col-12 text-black wow fadeInUp pt-4 text-center mt-5'>No Dishes Found against this category!</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {cateringFoodPlanSection &&
                                <CateringFoodPlan food_plan_id={foodPlanId} />
                            }
                        </div>
                    </div>
                </div>
            </section>
            {/* We Serve Section End */}
        </>
    )
}

export default CateringMenuPlan