import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import WOW from 'wowjs'
import '../Nav.css'
import {useFilterContext} from '../context/filterContext';
import { useTranslation } from "react-i18next";

const Navbar = () => {
    const [catering, setCatering] = useState(false)
    const [sticky, setSticky] = useState(false)
    const [mobile, setmobile] = useState(false)
    const location = useLocation()
    const path = location.pathname
    const { webSettingsData,navbar_items,main_project_url,main_project_media_url,project, unsetUserLogin, isUserLogin } = useFilterContext();
    const { i18n, t } = useTranslation();
   
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
    }, [path])
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

    return (
        <>
            <nav className={`myNavheader ${sticky && "sticky-top"}`} >
                <div className="container" id="myhomecontainer">
                    <div className="navbar">
                        <i className='bx bx-menu color-red' onClick={() => setmobile(true)}></i>
                        <div className="logo">
                            <Link to={main_project_url}>
                            {
                                webSettingsData &&  webSettingsData['logo'] && <img className="nav-logo" src={main_project_media_url+"/"+webSettingsData['logo'].name} alt={webSettingsData['logo']['alt_'+i18n.language]} />
                            }
                            </Link>
                        </div>
                        <div className="nav-links">
                            <div className="sidebar-logo">
                                <i className='bx bx-x color-black'></i>
                            </div>
                            <ul className="links">
                                {
                                    navbar_items && navbar_items.length > 0 &&
                                    navbar_items.map((navitem,navkey) => (
                                        navitem.sub_menu.length > 0 
                                        ?
                                            <li>
                                                <Link to="#">Catering</Link>
                                                <i className="bx bxs-chevron-down htmlcss-arrow arrow"></i>
                                                <ul className="htmlCss-sub-menu sub-menu">
                                                        {
                                                            navitem.sub_menu.map((submen,submenkey) => (
                                                              
                                                            submen.project === project &&   <li key={submenkey}>
                                                            <Link to={submen.status === 0 ? submen.link : main_project_url+"/"+submen.link} className="dropdown-item nav-dropdown-txt">{submen['name_'+i18n.language]}</Link>
                                                            </li>
                                                                
                                                               
                                                            ))
                                                        }
                                                </ul>
                                            </li>
                                        :
                                            <li key={navkey}>
                                                <Link to={navitem.status === 0 ?
                                                     navitem.link 
                                                     :
                                                     navitem.link === '/' ? main_project_url
                                                      :main_project_url+"/"+navitem.link}>{navitem['name_'+i18n.language]}</Link>
                                            </li>
                                    ))
                                }
                                
                                
                                
                            </ul>
                        </div>
                        <div className="search-box">
                            <Link to="/search"><i className="fa fa-search color-black"></i></Link>
                        </div>
                    </div>
                </div>
            </nav>


            {/* Mobile Sidebar Start */}
            <div className={`mobile-nav__wrapper ${mobile && "expanded"}`}>
                <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
                <div className="mobile-nav__content">
                    <nav>
                        <div className='navbar'>
                            <div className="nav-links" style={{ left: '0px' }}>
                                <div className="sidebar-logo" onClick={() => setmobile(false)}>
                                    <i className="bx bx-x color-black "></i>
                                </div>
                                <ul className="links">
                                    {
                                        navbar_items && navbar_items.length > 0 &&
                                        navbar_items.map((navitem2,navkey2) => (
                                            navitem2.sub_menu.length > 0 
                                            ?
                                                <li>
                                                    <Link to="#" onClick={() => setCatering(!catering)}>{navitem2['name_'+i18n.language]} <i className="bx bxs-chevron-down htmlcss-arrow arrow"></i></Link>
                                                    {
                                                        catering &&
                                                        <ul className="htmlCss-sub-menu sub-menu" style={{ display: 'block' }}>
                                                            {
                                                                navitem2.sub_menu.map((submen,submenkey) => (
                                                                    submen.project === project && 
                                                                    <li key={submenkey}>
                                                                        <Link to={submen.status === 0 ? submen.link : main_project_url+"/"+submen.link}>{submen['name_'+i18n.language]}</Link>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </li>
                                            :
                                                <li key={navkey2}>
                                                    <Link to={navitem2.status === 0 ? navitem2.link :
                                                    navitem2.link === '/' ? main_project_url : main_project_url+"/"+navitem2.link}>{navitem2['name_'+i18n.language]}</Link>
                                                </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>

                    </nav>
                </div>
            </div>

        </>
    )
}

export default Navbar