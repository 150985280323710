import React, { useEffect } from 'react'
import {useFilterContext} from '../context/filterContext';

const OrderReviews = () => {
  const { main_project_url } = useFilterContext();
    window.location.replace(main_project_url+""+window.location.pathname)
  return (
    <div style={{ height: '100vh' }}></div>
  )
}

export default OrderReviews