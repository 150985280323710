import React,{useState} from 'react'
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../context/filterContext';
import {Link} from "react-router-dom";
export const MenuSidebar = ({sidebarCategoryList,priceRange,productCount,slug}) => {
    const { i18n, t } = useTranslation();
    const [price, setPrice] = useState(priceRange && priceRange.max_price_range !== 0 ? priceRange.max_price_range : 100);
    const { applyPriceRange,clearFilter} = useFilterContext();
    return (
        <>

            <div className="col-xl-3 col-lg-4 col-md-4 sidebar-blog mt-sm-4 px-0">
                <div className="mside bg-white py-4 px-3" style={{ borderRadius: '3px' }}>

                    {
                        productCount > 6 && priceRange && priceRange.max_price_range !== 0 &&
                        <>
                        <h5 className="h6 fw-bold mb-0" style={{ borderBottom: '1px dashed #000',lineHeight: '38px' }}>Filter By Price</h5>
                            <input type="range" className="form-range" onChange={(event) => setPrice(event.target.value)}
                              defaultValue={priceRange.max_price_range} min={priceRange.min_price_range} max={priceRange.max_price_range} step="5"
                            />

                                <div className="d-flex justify-content-between">
                                    <span>
                                    {priceRange.min_price_range}
                                    </span>
                                    <span>
                                    {priceRange.max_price_range}
                                    </span>
                                </div>

                                   
                                <div className="d-flex justify-content-end mt-2">
                                    <button className="btn btn-danger btn-sm" onClick={() => applyPriceRange(price)}>Filter Upto {price}</button>
                                    <button className="btn btn-dark btn-sm ms-1" onClick={() => clearFilter()}>Clear</button>
                                </div>
                                </>
                    }
                    
                    <h5 className="h6 fw-bold mb-3 mt-4" style={{ borderBottom: '1px dashed #000',lineHeight: '38px' }}>Filter By Food Type</h5>

                    {
                        sidebarCategoryList.length > 0 && 
                        <ul style={{ listStyle:'none' }} className="ps-0">
                            { sidebarCategoryList.map((item, index )=> (
                                <>
                                    <li key={index} className="py-2"><Link to={"/"+item.item_slug} className={slug === item.item_slug ? 'color-red' : 'text-dark'}>{item.item_name}</Link></li>
                                    {
                                        item.subcategory.length > 0 && item.subcategory.map((subitem, index2) => (
                                            <li key={'00'+index2} className="ps-4"><Link to={"/"+item.item_slug+"/"+subitem.slug} className={slug === subitem.slug ? 'color-red' : 'text-dark'}>{subitem.name}</Link></li>
                                        ))
                                    }
                                </>
                                ))
                             }
                        </ul>
                        
                        
                        
                       
                    }


                </div>
            </div>

        </>
    )
}
