import React, { useEffect, useState, useRef } from "react";
import { server } from "../../util/server";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../../context/filterContext';

const ChatEngine = props => {
    const hiddenFileInput = useRef(null);
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const {project, media_server} = useFilterContext();
    // const [fileValue, setFileValue] = useState(null);
   
    const submitPostdata = () => {
        const uformData = new FormData();
        uformData.append("message", inputValue);
        uformData.append("project", project);
        if (sessionStorage.getItem('chat_id')) {
            uformData.append("session_id", sessionStorage.getItem('chat_id'));
        }
    
        
        axios.post(media_server + "/api/auth/web/customer-support/save-chat", uformData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
        .then(function (response) {
            if(response.data.status === false){
                toast.error(t(response.data.message))
            }else{
                if (!sessionStorage.getItem('chat_id')) {
                    sessionStorage.setItem('chat_id', response.data.session_id);
                }
                setInputValue('')
                props.getCustomerChat()
            }
           
          
        })
        .catch(function (response) {
            toast.error(t("Unexpected error occurred!"));
        });
    }

    const submitdata = (e) => {
        if (inputValue) {
            submitPostdata()
        }else{
            toast.error(t("Please write something to proceed"))
        }
    }

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

   

    const handleChange = event => {
            const uformData = new FormData();
            uformData.append("file", event.target.files[0]);
            uformData.append("project", project);
            if (sessionStorage.getItem('chat_id')) {
                uformData.append("session_id", sessionStorage.getItem('chat_id'));
            }
    
            uformData.append("email", props.user);
            
            axios.post(media_server + "/api/auth/web/customer-support/save-chat", uformData, {
                headers: { "Content-Type": "multipart/form-data" }
            })
            .then(function (response) {
                if(response.data.status === 0){
                    toast.error(t(response.data.message))
                }else{
                    if (!sessionStorage.getItem('chat_id')) {
                        sessionStorage.setItem('chat_id', response.data.session_id);
                    }
                    props.getCustomerChat()
                }
            
            
            })
            .catch(function (response) {
                toast.error(t("Unexpected error occurred!"));
            });
       
    };

    //console.log(props.showinitialChat, props.showChat);
    return (
        <div
            className='transition-5' 
            style={{
                ...styles.chatEngineWindow,
                ...{
                    height: props.visible ? '100%' : '0px',
                    zIndex: props.visible ? '100' : '0',
                }
            }}
        >
            <div className="chatbox">
                <div className="chatbox-header">
                    <div className="row">
                        <div className="col"><i className="fas fa-comments"></i></div>
                        <div className="col text-center fw-bold" style={{ fontSize: '20px' }}>{t("Chat")}</div>
                        <div className="col text-end" style={{ fontSize: '22px', cursor: 'pointer' }}><i className="fas fa-times" onClick={() => props.setVisible(false)}></i></div>
                    </div>
                </div>
                <div className="chat-messages">
                    {
                        props.showChat.length === 0 && !props.showinitialChat ? <p className="text-center fw-bold">Welcome! Ask us anything</p>
                        :
                        ''
                    }

                    {
                        props.showinitialChat && props.showinitialChat.message &&
                        props.showinitialChat.message.split(",").map(intialitem => (
                            <div className="message-box-holder" key={intialitem}>
                                <div className="message-box">
                                    {
                                        intialitem.startsWith("storage/chat")
                                            ? <img src={media_server + "/" + intialitem} width="100%" />
                                            : intialitem
                                    }
                                </div>
                                <div className="message-sender msg-reciever">
                                    {t("You")}
                                </div>
                            </div>
                        ))
                    }
                    
                    {
                        props.showChat.length > 0 && props.showChat.map(item => (
                            (!item.reciever_id && !item.sender_id) || !item.sender_id
                                ? <div className="message-box-holder" key={item.id}>
                                    <div className="message-box">
                                        {
                                            item.msg.startsWith("storage/chat")
                                                ? <img src={media_server + "/" + item.msg} width="100%" />
                                                : item.msg
                                        }
                                    </div>
                                    <div className="message-sender msg-reciever">
                                        {t("You")}
                                    </div>
                                </div>
                                : <div className="message-box-holder" key={item.id}>
                                    <div className="message-sender">
                                        {t("Admin")}
                                    </div>
                                    <div className="message-box message-partner">
                                        {
                                            item.msg.startsWith("storage/chat")
                                                ? <img src={media_server + "/" + item.msg} width="100%" />
                                                : item.msg
                                        }
                                    </div>
                                </div>
                        ))
                    }
                    

                </div>

                <div className="chat-input-holder">
                    <form encType='multipart/form-data'>
                    <div className="row">
                        <div className="col-12">
                            <div className="pseudo-search">
                                <input type="text" placeholder="write something..." required  value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                
                                />
                                <input type="file"
                                    ref={hiddenFileInput}
                                    style={{ display: 'none' }}
                                    onChange={handleChange} />
                                <i className="fas fa-paper-plane pe-2"  onClick={(e) => submitdata(e)}></i>
                                <i className="fas fa-paperclip" onClick={handleClick}></i>
                            </div>
                        </div>
                 
                    </div>
                    </form>

                    {/*  */}
                </div>

            </div>
        </div>
    )
}

export default ChatEngine;

const styles = {
    chatEngineWindow: {
        width: '100%',
        backgroundColor: '#fff',
    }
}
