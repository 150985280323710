import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import { useFilterContext } from '../context/filterContext'
import Star from '../Components/Star'
const MiniReviewSlider = () => {
    const { miniReviewSlider } = useFilterContext();
    const sliderOptions = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 1000,
        responsive: {
            0: {
                margin: 10,
                items: 1,
            },
            576: {
                margin: 10,
                items: 1,
            },
            768: {
                margin: 10,
                items: 1,
            },
            992: {
                margin: 20,
                items: 1,
            },
            1200: {
                margin: 30,
                items: 1,
            },
        },
    };
    const [show, setShow] = useState(false)
    useEffect(() => {
        let reviewSlider = sessionStorage.getItem('reviewSlider');
        if (!reviewSlider) {
            sessionStorage.setItem('reviewSlider', 'shown');
            setTimeout(() => {
                setShow(true);
            }, 2000)
        }
    }, [])
    return (
        <>
            {miniReviewSlider && miniReviewSlider.length>0 &&
                show &&
                <div className='footer review-slider-mini-popup'>
                    <div className='cnt224'>
                        <Link to="#" onClick={() => setShow(false)} className="color-black text-right" style={{ float: "right" }}>
                            <i className="fa fa-times-circle close-mini-popup c-i mini-slider-close" aria-hidden="true"></i>
                        </Link>
                        <div>
                            <div className="card p-0" style={{ boxShadow: "rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px" }}>
                                <div className="card-body">
                                    <OwlCarousel {...sliderOptions}>
                                        {
                                            miniReviewSlider.map((item, key) => (
                                                <div>
                                                    <h6 className="d-block w-100 text-center mb-0 fw-bold">
                                                        {item.given_by}
                                                    </h6>
                                                    <p className="text-center mb-0">
                                                        {item.review_data}
                                                    </p>
                                                    <div className="item-rate text-center mb-0">
                                                        <Star stars={item.review_rating} />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div><br />
                    </div>
                </div>
            }
        </>
    )
}

export default MiniReviewSlider