import React from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik';
import { signUpSchema } from '../Schema';
import { toast } from "react-toastify";
import { server } from '../util/server';
import axios from 'axios';
import { useFilterContext } from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

const Register = () => {
    const { project } = useFilterContext();
    const { t } = useTranslation();
    const submitData = async(values, actions) => {
        try {
            values['project'] = project;
            let res = await axios.post(server + "auth/user/user-register", values);
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(data.message) }
            } else {
                actions.resetForm();
                toast.success(data.message)
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }
    const initialValues = {
        firstName: '',
        lastName: '',
        phone_number: '',
        email: '',
        password: '',
        password_confirmation: '',
        house_number: '',
        street: '',
        postcode: '',
    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
        validationSchema: signUpSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            submitData(values, actions)
        }
    });

    const handleFacebookSignUp = async(data) => {
        var signUpData = data.data;
        try {
            let res = await axios.post(server + "auth/user/user-register", {'firstName' : signUpData.first_name, 'lastName' : signUpData.last_name, 'email' : signUpData.email ? signUpData.email : '', 'type_id' : signUpData.id, 'project' : project, 'login_type' : 'facebook'});
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(data.message) }
            } else {
                toast.success(data.message)
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }


    const handleGoogleSignUp = async(data) => {
        var signUpData = data;
        try {
            let res = await axios.post(server + "auth/user/user-register", {'firstName' : signUpData.given_name, 'lastName' : signUpData.family_name, 'email' : signUpData.email, 'type_id' : signUpData.sub, 'project' : project, 'login_type' : 'google'});
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(data.message) }
            } else {
                toast.success(data.message)
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }

    return (
        <>
            <div className="container login2-main col-lg-10 ">
                <div className="row">
                    <div className="col-lg-5 col-md-6 sign-form2-pic bg-white ps-4">
                        <img className="img-fluid" src="https://demo.ninjashushi.ch/backend/storage/banner/1667971919_register.jpg" alt="Register" />
                    </div>
                    <div className="col-lg-7 signUp-main2 col-md-6">

                        <form className="signup-form2 mt-4" onSubmit={handleSubmit}>
                            <h2 className="mb-4 text-white">Create an Account</h2>
                            <div className="row">
                                <div className="col-12">
                                    <ul className="color-white reg-ul">
                                        <li>Resister with JETZT EAT and get 1000 reward points</li>
                                        <li>Order your first meal with JETZT EAT and get discount of up to 25%</li>
                                        <li>Share JETZT EAT with your friends and family and get 500 referral reward points</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">First Name</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="text" name="firstName" defaultValue={values.firstName} onBlur={handleBlur} onChange={handleChange} />
                                    <span className="color-black f-13">{errors.firstName && touched.firstName && errors.firstName}</span>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">Last Name</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="text" name="lastName" defaultValue={values.lastName} onBlur={handleBlur} onChange={handleChange} />
                                    <span className="color-black f-13">{errors.lastName && touched.lastName && errors.lastName}</span>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">Phone Number</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="text" name="phone_number" id="contactphone" defaultValue={values.phone_number} onBlur={handleBlur} onChange={handleChange} />
                                    <span className="color-black f-13">{errors.phone_number && touched.phone_number && errors.phone_number}</span>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">Email</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="email" name="email" defaultValue={values.email} onBlur={handleBlur} onChange={handleChange} />
                                    <span className="color-black f-13">{errors.email && touched.email && errors.email}</span>
                                </div>

                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">Password</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="password" name="password" defaultValue={values.password} onBlur={handleBlur} onChange={handleChange} />
                                    <span className="color-black f-13">{errors.password && touched.password && errors.password}</span>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">Confirm Password</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="password" name="password_confirmation" defaultValue={values.password_confirmation} onBlur={handleBlur} onChange={handleChange} />
                                    <span className="color-black f-13">{errors.password_confirmation && touched.password_confirmation && errors.password_confirmation}</span>
                                </div>

                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">House Number</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="text" name="house_number" defaultValue={values.house_number} onBlur={handleBlur} onChange={handleChange} />
                                    <span className="color-black f-13">{errors.house_number && touched.house_number && errors.house_number}</span>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">Street</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="text" name="street" defaultValue={values.street} onBlur={handleBlur} onChange={handleChange} />
                                    <span className="color-black f-13">{errors.street && touched.street && errors.street}</span>
                                </div>

                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">Postcode</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="text" name="postcode" defaultValue={values.postcode} onBlur={handleBlur} onChange={handleChange} />
                                    <span className="color-black f-13">{errors.postcode && touched.postcode && errors.postcode}</span>
                                </div>
                                <div className="col-lg-6 mt-3">
                                    <label className="cheack-label text-white">City and Country</label>
                                    <input className="form-control bg-transparent text-white mt-3" type="text" value="Winterthur, Switzerland" disabled />
                                </div>
                                <div className="col-lg-12 col-sm-12 mt-3 signup-form2-btn">
                                    <div className="d-grid">
                                        <button type="submit" value="login mb-0" className="btn">Create my Account</button>
                                    </div>

                                </div>
                                <div className="col-lg-12 social-login mt-3">
                                    <p className="icon-p">OR?</p>
                                </div>
                                {/* <div className="col-xl-6 col-12 social-login text-center">
                                    <LoginSocialFacebook
                                        appId='1391976458320486'
                                        onResolve={(response) => handleFacebookSignUp(response)}
                                        onReject={(response) => console.log(response)}
                                    >
                                        <FacebookLoginButton style={{ fontSize: '16px' }}>SignUp with Facebook</FacebookLoginButton>
                                    </LoginSocialFacebook>
                                </div> */}
                                <div className="col-xl-6 col-12 social-login text-center">
                                    <GoogleOAuthProvider clientId="954796258794-lsstgps91299hot5nnjsompo4s1d5ujh.apps.googleusercontent.com">
                                       <GoogleLogin
                                        onSuccess={credentialResponse => {
                                            const details = jwt_decode(credentialResponse.credential)
                                            handleGoogleSignUp(details)
                                        }}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                        text= "signup_with"
                                        />
                                    </GoogleOAuthProvider>
                                </div>
                            </div>


                            <div className="sign2-last">
                                <p> Already have an account? <Link to="/login"> Login</Link></p>
                            </div>
                        </form>



                    </div>

                </div>
            </div>
        </>
    )
}

export default Register