import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFilterContext } from '../../context/filterContext';
import { server } from '../../util/server';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { cateringCheckoutForm1Schema } from '../../Schema';
const CateringCheckoutForm1 = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { isUserLogin, project } = useFilterContext();
    const [customerInfo, setCustomerInfo] = useState(null)
    const [oldData, setOldData] = useState()
    const [eventType, setEventType] = useState('Wedding')
    const [dateTimeList, setdateTimeList] = useState([{ date: '', event_start_time: '', event_end_time: '' }]);
    var res;
    // get UserData
    const getCustomerInfo = async () => {
        try {
            let url = server + 'auth/user/get-user-info?api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setCustomerInfo(data.data)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            // return toast.error(t("Unexpected error occurred!"))
        }
    }
    // get FormData
    const getForm1Data = async () => {
        try {
            let url = server + 'auth/web/catering-checkout/first-step-form-get-data?api_type=web&user_id=' + isUserLogin + '&project=' + project + '&catering_cart_id=' + localStorage.getItem('catering_cart_id')
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setOldData(data.data)
                setEventType(data.data.event_type)
                setdateTimeList(data.timings)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            //  return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getCustomerInfo()
        if (localStorage.getItem('catering_cart_id')) {
            getForm1Data()
        }
    }, [])

    const initialValues = {
        first_name: customerInfo ? customerInfo.firstName : '',
        last_name: customerInfo ? customerInfo.lastName : '',
        phone_number: customerInfo ? customerInfo.phone : '',
        email: customerInfo ? customerInfo.email : '',
        guest: oldData ? oldData.guest : null,
    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
        validationSchema: cateringCheckoutForm1Schema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            submitData(values)
        }
    });

    const submitData = async (query) => {
        try {
            const caterer_id = localStorage.getItem('caterer_id')
            query['user_id'] = isUserLogin;
            query['cart_caterer_id'] = caterer_id;
            query['event_type'] = eventType;
            query['eventDateTimeArray'] = dateTimeList;
            query['api_type'] = 'web';
            let res = await axios.post(server + "auth/web/catering-checkout/first-step-form-submit", query);
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(item);
                        })
                    })
                }
            } else {
                localStorage.setItem('catering_cart_id', data.catering_cart_id)
                navigate('/catering-checkout/step-2')
            }
        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...dateTimeList];
        list[index][name] = value;
        setdateTimeList(list);
    }
    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...dateTimeList];
        list.splice(index, 1);
        setdateTimeList(list);
        // alert(index)
    }
    // handle click event of the Add button
    const handleAddClick = () => {
        setdateTimeList([...dateTimeList, { date: "", event_start_time: "", event_end_time: "" }]);
    }

    const timeArray = ['01:00am', '02:00am', '03:00am', '04:00am', '05:00am', '06:00am', '07:00am', '08:00am', '09:00am', '10:00am', '11:00am', '12:00am', '13:00pm', '14:00pm', '15:00pm', '16:00pm', '17:00pm', '18:00pm', '19:00pm', '20:00pm', '21:00pm', '22:00pm', '23:00pm', '24:00pm'];

    return (
        <>
            {/* Banner section Start */}
            <section className="banner-n" style={{ backgroundImage: `url('/assets/front/images/contact-us.jpg')`, backgroundRepeat: "no-repeat" }}>
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end">
                        <div className="col-lg-10 text-center mx-auto wow slideInLeft" data-wow-delay="0.1s text-center" style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                            <h1 className="color-red">Catering Checkout -  Step 1</h1>
                            <ul className="bread">
                                <li><b><Link to="/">Home</Link></b></li>
                                <li className="color-red"><b>Catering Checkout -  Step 1</b></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner section End */}

            {/* Main section Start */}
            <section className="bg-red">
                <div className="container" style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                    <div className="col-12">
                        <ul className="bread mb-2" id="custom-bread">
                            <li>
                                <b>
                                    <Link to="/catering-cart" className="color-black">Cart</Link>
                                </b>
                            </li>
                            <li className="color-white"><b>Step 1</b></li>
                        </ul>
                    </div>
                    <div className="contact-wrap" style={{ paddingTop: "5%", paddingBottom: "5%", paddingLeft: "5%", paddingRight: "5%" }}>
                        <form className="mb-1" id="contactForm" name="contactForm" onSubmit={handleSubmit}>
                            <div className="col-md-12 ">
                                <div className="form2 h-100">
                                    <h2 className="site-font text-center">Step:1</h2>
                                    {!isUserLogin &&
                                        <small className="color-black text-center">* Please login first and then proceed to fill out the form <Link to="/login" className="btn btn-dark btn-sm ms-2" target="_blank">Login</Link></small>
                                    }
                                    <h3 className="site-font">Personal Details</h3>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-2">
                                            <label htmlFor="" className="col-form-label">First Name *</label>
                                            <input className="form-control" type="text" placeholder="John" name="first_name" defaultValue={values.first_name} onBlur={handleBlur} onChange={handleChange} required />
                                            <span id='msg'>{errors.first_name && touched.first_name && errors.first_name}</span>
                                        </div>
                                        <div className="col-md-6 form-group mb-2">
                                            <label htmlFor="" className="col-form-label">Last Name *</label>
                                            <input className="form-control" type="text" placeholder="Doe" name="last_name" defaultValue={values.last_name} onBlur={handleBlur} onChange={handleChange} required />
                                            <span id='msg'>{errors.last_name && touched.last_name && errors.last_name}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-2">
                                            <label htmlFor="" className="col-form-label">Phone</label>
                                            <input className="form-control" type="text" placeholder="+(41) XX XXX XX XX" name="phone_number" id="contactphone" defaultValue={values.phone_number} onBlur={handleBlur} onChange={handleChange} required />
                                            <span id='msg'>{errors.phone_number && touched.phone_number && errors.phone_number}</span>
                                        </div>
                                        <div className="col-md-6 form-group mb-2">
                                            <label htmlFor="" className="col-form-label">Email *</label>
                                            <input className="form-control" type="email" placeholder="john@gmail.com" name="email" defaultValue={values.email} onBlur={handleBlur} onChange={handleChange} required />
                                            <span id='msg'>{errors.email && touched.email && errors.email}</span>
                                        </div>
                                    </div>
                                    <h3 className="site-font">Event Details</h3>
                                    <div className="col-md-6 form-group mb-2">
                                        <label htmlFor="" className="col-form-label">Number of Guest</label>
                                        <input className="form-control" type="number" placeholder="e.g 1000 or 500 - 1000" name="guest" defaultValue={values.guest} onBlur={handleBlur} onChange={handleChange} required />
                                        <span id='msg'>{errors.guest && touched.guest && errors.guest}</span>
                                    </div>
                                    <label htmlFor="" className="col-form-label">Event Type</label><br />
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" checked={oldData && oldData.event_type === 'Wedding' ? true : ''} type="radio" name="event_type" onChange={() => setEventType('Wedding')} />
                                        <label className="form-check-label">
                                            Wedding
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" checked={oldData && oldData.event_type === 'Social Events' ? true : ''} name="event_type" onChange={() => setEventType('Social Events')} />
                                        <label className="form-check-label">
                                            Social Events
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" checked={oldData && oldData.event_type === 'Corporate' ? true : ''} name="event_type" onChange={() => setEventType('Corporate')} />
                                        <label className="form-check-label">
                                            Corporate
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" checked={oldData && oldData.event_type === 'Concession' ? true : ''} name="event_type" onChange={() => setEventType('Concession')} />
                                        <label className="form-check-label">
                                            Concession
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" checked={oldData && oldData.event_type === 'Festivals' ? true : ''} name="event_type" onChange={() => setEventType('Festivals')} />
                                        <label className="form-check-label">
                                            Festivals
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" checked={oldData && oldData.event_type === 'Private Parties' ? true : ''} name="event_type" onChange={() => setEventType('Private Parties')} />
                                        <label className="form-check-label">
                                            Private Parties
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" checked={oldData && oldData.event_type === 'Funeral' ? true : ''} type="radio" name="event_type" onChange={() => setEventType('Funeral')} />
                                        <label className="form-check-label">
                                            Funeral
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-8 mt-3">
                                    <table className="add_datetime_table" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <th colSpan="4" className="text-center">
                                                    <h4>Event Date and Time</h4>
                                                </th>
                                            </tr>
                                            <tr style={{ paddingTop: "20px" }}>
                                                <td colSpan="4">
                                                    <button type="button" className="btn btn-success add_date float-end" style={{ padding: "4px 20px", fontSize: "16px" }} onClick={handleAddClick}>
                                                        <i className="fas fa-plus-circle"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr style={{ paddingTop: "20px" }} className="text-center">
                                                <td>
                                                    Event Date
                                                </td>
                                                <td>
                                                    Event Start Time
                                                </td>
                                                <td>
                                                    Event End Time
                                                </td>
                                            </tr>
                                            {dateTimeList.length > 0 && dateTimeList.map((x, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <input type="date" name="date" className="form-control" required min="" value={x.date} onChange={(e) => handleInputChange(e, i)} />
                                                        </td>
                                                        <td>
                                                            <select name="event_start_time" value={x.event_start_time} className="form-control" required onChange={(e) => handleInputChange(e, i)}>
                                                                <option>Select Time</option>
                                                                {timeArray.map((item, key) => (
                                                                    key < 12 ? (<>
                                                                        {res = item.split('am')}
                                                                        <option key={key} value={res[0] + res[1]}>{item}</option>
                                                                    </>) :
                                                                        (<>
                                                                            {res = item.split('pm')}
                                                                            <option key={key} value={res[0] + res[1]}>{item}</option>
                                                                        </>)
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select name="event_end_time" value={x.event_end_time} className="form-control" required onChange={(e) => handleInputChange(e, i)}>
                                                                <option>Select Time</option>
                                                                {timeArray.map((item, key) => (
                                                                    key < 12 ? (<>
                                                                        {res = item.split('am')}
                                                                        <option key={key} value={res[0] + res[1]}>{item}</option>
                                                                    </>) :
                                                                        (<>
                                                                            {res = item.split('pm')}
                                                                            <option key={key} value={res[0] + res[1]}>{item}</option>
                                                                        </>)
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button type="button" onClick={() => handleRemoveClick(i)} className="btn btn-danger remove-date float-end" style={{ padding: "4px 20px", fontSize: "16px", visibility: i === 0 ? "hidden" : "visible" }}><i className="fas fa-minus-circle"></i></button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 form-group mt-3">
                                    {/* <Link to='/catering-checkout/step-2' className="btn btn-danger py-2 px-4 text-center follow-1 bg-red">Next Step</Link> */}
                                    <input type="submit" value="Next Step" className="btn btn-danger py-2 px-4 text-center follow-1 bg-red" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {/* Main section End */}
        </>
    )
}

export default CateringCheckoutForm1