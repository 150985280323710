import React from 'react'

const Star = (stars) => {
    const starsval = stars.stars;
    const ratingStar = Array.from({length:5}, (elem, index) => {
        return (
            starsval > index ? <i key={index} className="fas fa-star color-red"></i> : <i key={index} className="fas fa-star color-lightgrey"></i>
        )
    })
    return (
       ratingStar
    )
}

export default Star