import React, { useEffect, useState } from 'react'
import { MenuItemList } from '../Components/MenuItemList'
import { MenuSidebar } from '../Components/MenuSidebar'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useFilterContext } from '../context/filterContext';
import { server } from '../util/server'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Meta } from '../Components/Meta';
import { useTranslation } from 'react-i18next';
import Spinner from '../Components/Spinner';
import Pagination from "react-js-pagination";

export const MenuItem = () => {
    const [spinner, setSpinner] = useState(true);
    const { i18n, t } = useTranslation();
    const { maincategory, subcategory = null } = useParams();
    const [data, setData] = useState([]);
    const [priceRange, setPriceRange] = useState(null);
    const [mainCategoryData, setMainCategoryData] = useState(null);
    const [subCategoryData, setSubCategoryData] = useState(null);
    const [sidebarCategoryList, setSidebarCategoryList] = useState([]);
    const { sort_value, selected_price_range, updatemetas, updatePageBanner, media_server, page_banner } = useFilterContext();
    const getData = async (pageNumber = 1) => {
        try {
            let url = server + `auth/web/menu-items?page=${pageNumber}&project=Winterthur&page_link=menu&maincategory=` + maincategory
            if (subcategory) {
                url = url + '&subcategory=' + subcategory
            }
            if (sort_value) {
                url = url + '&sort=' + sort_value
            }
            if (selected_price_range) {
                url = url + '&price_range=' + selected_price_range
            }
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setSidebarCategoryList(data.sidebarCategoryList)
                setData(data.data)
                setPriceRange(data.price_range)
                updatemetas(null, 0, data.meta)
                updatePageBanner(data.page_banner)
                setMainCategoryData(data.main_category)
                if (data.sub_category) {
                    setSubCategoryData(data.sub_category)
                }
                setSpinner(false)
                // setPageData(data.page_data)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            // return toast.error(t("Unexpected error occurred!"))
        }
    }
    // console.log(data);

    useEffect(() => {
        getData()
    }, [maincategory, sort_value, selected_price_range, subcategory])

    return (
        <>
            <Meta />
            {page_banner &&
                <section className="banner-n" style={{ backgroundImage: `url(${media_server}/${page_banner})` }}>
                    <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div className="col-lg-10 text-center mx-auto mb-4 wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                    <h1 className="col-lg-8text-left mx-auto wow fadeInUp color-red text-uppercase" data-wow-delay="0.5s text-center">{mainCategoryData && mainCategoryData.item_name} Menu</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {spinner ? <Spinner /> :
                <>
                    <section style={{ paddingBottom: '40px', paddingTop: '40px' }}>
                        <div className="container">
                            <div className="tab-content">
                                <div id="tab-1" className="tab-pane fade show p-0 active">
                                    <div className="row">
                                        <MenuSidebar sidebarCategoryList={sidebarCategoryList} priceRange={priceRange} productCount="23" slug={subcategory ? subcategory : maincategory} />
                                        
                                        <div className='col-xl-9 col-lg-8 col-md-8 sidebar-blog mt-4'>
                                            <MenuItemList data={data.data} mainSlug={maincategory} subCatSlug={subcategory} />
                                            <div className="d-flex justify-content-center mt-4">
                                                <Pagination
                                                    totalItemsCount={data && data.total}
                                                    activePage={data && data.current_page}
                                                    itemsCountPerPage={data && data.per_page}
                                                    onChange={(pageNumber) => { getData(pageNumber) }}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    activeClass='active'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </section>
                </>
            }

        </>
    )
}
