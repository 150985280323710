import React, { useState,useEffect } from "react"

import { styles } from "../styles";

import EmailForm from "./EmailForm";
import ChatEngine from "./ChatEngine";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../../context/filterContext';

const SupportWindow = props => {
    const {project,media_server} = useFilterContext();
    const [user, setUser] = useState(sessionStorage.getItem('chat') ? sessionStorage.getItem('chat') : null)
    const {  t } = useTranslation();
   
   

    const getCustomerChat = () => {
        if (sessionStorage.getItem('chat_id')) {
            axios({
                method: "get",
                url: media_server + "/api/auth/web/customer-support/get-chat?project="+project+"&session_id=" + sessionStorage.getItem('chat_id'),
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    if (response.data.status === 1) {
                        if(response.data.customer_initial_chat){
                            props.setshowInitialChat(response.data.customer_initial_chat)
                        }else{
                            props.setShowChat(response.data.data)
                            props.setshowInitialChat(null)
                        }
                    }else{
                        sessionStorage.removeItem('chat_id')  
                    }
                })
                .catch(function (response) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }
    }

   

    

    // useEffect(() => {
    //     if(document.querySelector('#chateng').style.display !== 'none'){
    //         setTimeout(() => {
    //             getCustomerChat();
    //         }, 60000)
    //     }
    // });

    useEffect(() => {
        if (sessionStorage.getItem('chat_id') && document.querySelector('#chateng').style.display !== 'none') {
            const timerId = setTimeout(() => {
                getCustomerChat();
            }, 30000)
            return () => clearTimeout(timerId);
        }
    })

    return (
        <div 
            className='transition-5'
            id="chateng"
            style={{
                ...styles.supportWindow,
                ...{ display: props.visible === false ? 'none' : 'inline-block' }
                // ...{ display: 'none' }
            }}
        >
            <EmailForm 
                visible={user === null}
                setUser={user => setUser(user)} 
                setVisible={props.setVisible}
                // setChat={chat => setChat(chat)} 
            />

            <ChatEngine 
                visible={user !== null}
                user={user} 
                showChat={props.showChat} 
                setShowChat={props.setShowChat}
                setVisible={props.setVisible}
                showinitialChat={props.showinitialChat}
                 setshowInitialChat={props.setshowInitialChat}
                getCustomerChat={getCustomerChat}
            />
        </div>
    )
}

export default SupportWindow;
