import React from 'react'
import { useFormik } from 'formik';
import { deliveryAddressSchema } from '../Schema';
import { toast } from "react-toastify";
import { server } from '../util/server';
import axios from 'axios';
import { useFilterContext } from '../context/filterContext';
import { useTranslation } from 'react-i18next';
const DeliveryAddress = ({getAddresses}) => {
    const { t } = useTranslation();
    const { project, isUserLogin } = useFilterContext();
    const initialValues = {
        housenumber: '',
        street: '',
        post_code: '',
        city: 'Winterthur Switzerland'
    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
        validationSchema: deliveryAddressSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            values['project'] = project;
            values['user_id'] = isUserLogin;
            values['default_address'] = 0;
            values['api_type'] = 'web';
        try {
            let res = await axios.post(server+"auth/user/add-address", values);
            let data = await res.data;
            if(data.status === 0){
                if(data.message){toast.error(t(data.message))}
                
                if(data.errors){
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(item);
                        })
                        
                    })
                }
            }else{
                actions.resetForm();
                document.getElementById('modal-cus-close').click()
                getAddresses()
            }
            
        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
        }
    });
    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form id="producForm" onSubmit={handleSubmit} >
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12" id="error-div"></div>
                                    <h3>Delivery Address</h3>
                                    <div className="col-md-6">
                                        <input className="form-control mt-3" type="text" placeholder="House Number" name="housenumber" defaultValue={values.housenumber} onBlur={handleBlur} onChange={handleChange} />
                                        <span id='msg'>{errors.housenumber && touched.housenumber && errors.housenumber}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <input className="form-control mt-3" type="text" placeholder="Street" name="street" onBlur={handleBlur} onChange={handleChange} />
                                        <span id='msg'>{errors.street && touched.street && errors.street}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <input className="form-control mt-3" type="text" placeholder="Postcode" name="post_code" onBlur={handleBlur} onChange={handleChange} />
                                        <span id='msg'>{errors.post_code && touched.post_code && errors.post_code}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <input className="form-control mt-3" type="text" disabled defaultValue={values.city} name='city' />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-dark" id="modal-cus-close" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-danger" id="form-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeliveryAddress