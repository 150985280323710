import * as Yup from 'yup'

export const giftCardSchema = Yup.object({
  recepient_email: Yup.string().email('Please enter valid email address!').required('Please enter recepient email!'),
  recepient_name: Yup.string('Recepient name must be a string!').required('Please enter recepient name!').max(30, 'Recepient should not be greater than 30 characters'),
  sender_name: Yup.string('Sender Name must be a string!').required('Please enter sender name!').max(30, 'Sender name should not be greater than 30 characters'),
  delivery_date: Yup.string().required('Please select a delivery date!'),
  message: Yup.string().max(150, 'Message should be maximum 150 characters!'),
  gift_image: Yup.string().required('Please select a card!'),
  gift_price: Yup.string().required('Please select card price!')
})


export const checkoutSchema = Yup.object({
  email: Yup.string().email('Please enter valid email address!').required('Please enter recepient email!'),
  firstName: Yup.string('First Name must be a string!').required('Please enter your First Name!').max(30, 'First Name not be greater than 30 characters'),
  lastName: Yup.string('Last Name must be a string!').required('Please enter your Last Name!').max(30, 'Last Name not be greater than 30 characters'),
  phone_number: Yup.string('').required('Please enter your Phone Number!'),
  order_note: Yup.string('').max(100, 'Order Note must not be greater than 100 characters!'),
  payment_method: Yup.string('').required('Please select a payment method!'),
  terms_conditons: Yup.bool().required('Check this box to proceed further!'),
})

export const deliveryAddressSchema = Yup.object({
  housenumber: Yup.string().required('Please enter House number!').max(10, 'House number must not be greater than 10 characters!'),
  street: Yup.string().required('Please enter street!').max(10, 'House number must not be greater than 15 characters!'),
  post_code: Yup.string().required('Please enter postcode/zipcode!').max(6, 'Postcode must not be greater than 6 characters!'),
})

export const signUpSchema = Yup.object({
  email: Yup.string().email('Please enter valid email address!').required('* Please enter recepient email!'),
  firstName: Yup.string().required('* Please enter your First Name!').max(30, 'First Name not be greater than 30 characters'),
  lastName: Yup.string().required('* Please enter your Last Name!').max(30, 'Last Name not be greater than 30 characters'),
  phone_number: Yup.number('Phone number must be integer!').required('* Please enter your Phone Number!'),
  password: Yup.string().required('* Password is required').min(8, 'Your password is too short.').max(20, 'Password must not be greater than 20 characters!'),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  house_number: Yup.string().required('* House Number is required!').max(20, 'Must not be greater than 20 characters!'),
  street: Yup.string().required('* Street is required!').max(20, 'Must not be greater than 20 characters!'),
  postcode: Yup.string().required('* Please enter postcode/zipcode!').max(6, 'Maximum 6 characters are allowed!'),
})

export const logInSchema = Yup.object({
  email: Yup.string().email('Please enter valid email address!').required('Please enter your email!'),
  password: Yup.string().required('Password is required'),
})

export const updateProfileSchema = Yup.object({
  firstName: Yup.string('First Name must be a string!').required('Please enter your First Name!').max(30, 'First Name not be greater than 30 characters'),
  lastName: Yup.string('Last Name must be a string!').required('Please enter your Last Name!').max(30, 'Last Name not be greater than 30 characters'),
  phone: Yup.string('').required('Please enter your Phone Number!')
})

export const addressSchema = Yup.object({
  housenumber: Yup.string().required('Please enter House number!').max(10, 'House number must not be greater than 10 characters!'),
  street: Yup.string().required('Please enter street!').max(40, 'Street must not be greater than 40 characters!'),
  post_code: Yup.string().required('Please enter postcode/zipcode!').max(6, 'Postcode must not be greater than 6 characters!'),
  project: Yup.string().required('Please enter Town!').max(30, 'Town name must not be greater than 30 characters!'),
  default_address: Yup.bool(),
})

export const cateringCheckoutForm1Schema = Yup.object({
  email: Yup.string().email('Please enter valid email address!').required('Please enter recepient email!'),
  first_name: Yup.string('First Name must be a string!').required('Please enter your First Name!').max(30, 'First Name not be greater than 30 characters'),
  guest: Yup.string('This field must be integer!').required('This field is required!').max(4, 'This field must not be greater than 4 digits!'),
  last_name: Yup.string('Last Name must be a string!').required('Please enter your Last Name!').max(30, 'Last Name not be greater than 30 characters'),
  phone_number: Yup.string('').required('Please enter your Phone Number!'),
})
export const passwordResetSchema = Yup.object({
  code: Yup.string().max(50).required("Please enter code you recieved in email"),
  password : Yup.string().min(8, 'Password must contain at least 8 characters').max(20, 'Password must not exceed 20 characters').required("Please enter your password!"),
  cpassword: Yup.string().oneOf([Yup.ref('password')], "Password does not match!").required("Please confirm your password!"),
  
})