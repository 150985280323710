import React, { useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import "bootstrap/js/src/collapse.js";
import {useFilterContext} from '../../context/filterContext';

const Sidebar = () => {
    const navigate = useNavigate()
    const {unsetUserLogin, isUserLogin} = useFilterContext();
    useEffect(() => {
        if(!isUserLogin){
            navigate('/login');
        }
     
    },[isUserLogin])
   
    return (
        <>
            <div className="col-lg-3 m-account py-2 px-4 accordion-menu" style={{ backgroundColor: '#fff' }}>
                <div className="Navmobilehide">
                    <h4 style={{ paddingTop: "7px" }}>My Account</h4>
                    <hr />
                    <Link to="/user/dashboard" className="d-block sidebar dropdownlink  dropdownlink-active">
                        <i className="fa fa-dashboard me-3" aria-hidden="true"></i>Dashboard
                    </Link>

                    <Link to="#profilecolalpse" className="d-block sidebar dropdownlink" data-bs-toggle="collapse" aria-expanded="true">
                        <i className="fas fa-user me-3" aria-hidden="true"></i>
                        Profile <i className="fas fa-angle-down float-end " style={{ float: "right" }}></i>
                    </Link>

                    <div className="collapse" id="profilecolalpse">
                        <Link to="/user/profile" className="d-block sidebar ms-4 dropdownlink">
                            Update Profile</Link>
                        <Link to="/user/address" className="d-block sidebar ms-4 mb-3 dropdownlink">
                            Manage Addresses</Link>
                    </div>

                    <Link to="#orderHistorycolalpse" className="d-block sidebar dropdownlink" data-bs-toggle="collapse" aria-expanded="true">
                        <i className="fas fa-file-upload me-3"></i>
                        Order History <i className="fas fa-angle-down float-end"></i>
                    </Link>

                    <div className="collapse" id="orderHistorycolalpse">
                        <Link to="/user/order/ongoing" className="d-block sidebar ms-4 dropdownlink">
                            Ongoing Orders
                        </Link>
                        <Link to="/user/order/past" className="d-block sidebar ms-4 dropdownlink">
                            Past Orders
                        </Link>
                    </div>

                    <Link to="#mealPlannercollapse" className="d-block sidebar dropdownlink" data-bs-toggle="collapse" aria-expanded="true">
                        <i className="fas fa-file-upload me-3"></i>
                        Meal Planner Orders
                        <i className="fas fa-angle-down float-end"></i>
                    </Link>

                    <div className="collapse" id="mealPlannercollapse">
                        <Link to="/user/meal-planner-orders" className="d-block sidebar ms-4 dropdownlink">
                            Meal Planner Orders
                        </Link>

                        <Link to="/user/ongoing-meal-planner-orders" className="d-block sidebar ms-4 dropdownlink">
                            Ongoing Orders
                        </Link>

                        <Link to="/user/scheduled-meal-planner-orders" className="d-block sidebar ms-4 dropdownlink">
                            Scheduled Orders
                        </Link>

                        <Link to="/user/meal-planner-orders-history" className="d-block sidebar ms-4 dropdownlink">
                            Past Orders
                        </Link>
                    </div>

                    <Link to="/user/catering-venue-booking" className="d-block sidebar dropdownlink">
                        <i className="fas fa-calendar-alt me-3"></i>Catering/Venue Booking
                    </Link>

                    <Link to="/user/gift-card" className="d-block sidebar dropdownlink">
                        <i className="fas fa-gift me-3"></i>Gift Cards
                    </Link>

                    <Link to="/vouchers" className="d-block sidebar dropdownlink">
                        <i className="fas fa-tags me-3"></i>Vouchers
                    </Link>

                    <Link to="/referrals" className="d-block sidebar dropdownlink">
                        <i className="fas fa-money-check-alt me-3"></i>
                        Refer & Earn
                    </Link>
                    <Link to="/user/eat-wallet" className="d-block sidebar dropdownlink">
                        <i className="fas fa-wallet me-3"></i>Eat Wallet
                    </Link>

                    <Link to="/user/wishlist" className="d-block sidebar dropdownlink">
                        <i className="fas fa-heart me-3"></i>My Wishlist
                    </Link>

                    <Link to="" className="d-block sidebar dropdownlink">
                        <i className="fa fa-map-marker me-3"></i>Track Your Order
                    </Link>

                    <Link to="#settingscolalpse" className="d-block sidebar dropdownlink" data-bs-toggle="collapse" aria-expanded="true">
                        <i className="fas fa-cogs me-3"></i>
                        Settings
                        <i className="fas fa-angle-down float-end"></i>
                    </Link>

                    <div className="collapse" id="settingscolalpse">
                        <Link to="" className="d-block sidebar ms-4 dropdownlink">Terms & Conditions</Link>
                        <Link to="" className="d-block sidebar ms-4 dropdownlink">Privacy Policy</Link>
                    </div>

                    <Link to="" className="d-block sidebar dropdownlink" onClick={() => unsetUserLogin()}>
                        <i className="fas fa-sign-out-alt"></i>Logout
                    </Link>
                </div>


            </div>
        </>
    )
}

export default Sidebar