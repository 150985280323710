import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { format } from 'date-fns';
import axios from 'axios';
import { server } from '../../util/server';
import { toast } from 'react-toastify';
import { useFilterContext } from '../../context/filterContext';
import { useTranslation } from 'react-i18next';
const CateringCheckoutForm2 = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [cateringDates, setCateringDates] = useState([])
    const [ourVenueServices, setOurVenueServices] = useState(1)
    const [cateringCart, setCateringCart] = useState('')
    const [venueName, setVenueName] = useState('')
    const [venuePhoneNo, setVenuePhoneNo] = useState('')
    const [venueAddress, setVenueAddress] = useState('')
    const [arrangementType, setarrangementType] = useState('')
    const [foodArray, setFoodArray] = useState([])
    const [foodPlanArray, setFoodPlanArray] = useState([])
    const { isUserLogin } = useFilterContext();
    const [eventDateID, setEventDateID] = useState()
    const [foodType, setFoodType] = useState()
    const [cateringFoodPlanCart, setCateringFoodPlanCart] = useState('')
    const catering_cart_id = localStorage.getItem('catering_cart_id')

    const getCateringDates = async () => {
        try {
            let url = server + 'auth/web/catering-checkout/second-step-form-get-data?catering_cart_id=' + catering_cart_id
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setCateringDates(data.data)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            //  return toast.error(t("Unexpected error occurred!"))
        }
    }

    const handleFoodArray = (e, index, type) => {
        if (type === 'Plans') {
            if(foodArray.length > 0){
                setFoodArray([])
                setFoodPlanArray([])
            }
            const { value } = e.target;
            const list = [foodArray];
            list[index] = value;
            setFoodPlanArray(value)
        }
        else if(type==='Dishes'){
            if(foodPlanArray.length > 0){
                setFoodArray([])
                setFoodPlanArray([])
            }
            const { value } = e.target;
            const list = [...foodArray];
            list[index] = value;
            setFoodArray(list);
        }
        setFoodType(type)
    }

    const handleFoodSubmit = async (e) => {
        e.preventDefault()
        try {
            let res = await axios.post(server + "auth/web/catering-checkout/submit-dishes", { 'food_type': foodType, 'food_array': foodType==='Plans' ? foodPlanArray : foodArray, 'date_id': eventDateID });
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(item);
                        })
                    })
                }
            } else {
                toast.success('Food is added!')
                getCateringDates()
                setFoodArray([])
                setFoodType()
            }
        } catch (error) {
            toast.error('Unexpected error occurred!')
        }
    }

    const splitOldFoodArray = (OldFoodList) => {
        if(OldFoodList!==null){
            let OldFoodArray = OldFoodList.split('|')
            let newFoodArray = OldFoodArray.map((foodItem, foodKey) => {
                return foodItem.split('/')[1]
            })
            return newFoodArray
        }
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let query;
        if (ourVenueServices === 0) {
            query = { 'user_id': isUserLogin, 'our_venue_services': ourVenueServices, 'venue_name': venueName, 'venue_contact_number': venuePhoneNo, 'arrangment_type': arrangementType, 'venue_address': venueAddress, 'catering_cart_id': localStorage.getItem('catering_cart_id') };
            try {
                let res = await axios.post(server + "auth/web/catering-checkout/second-step-form-submit", query);
                let data = await res.data;
                if (data.status === 0) {
                    if (data.message) { toast.error(t(data.message)) }
                    if (data.errors) {
                        Object.keys(data.errors).forEach(key => {
                            data.errors[key].map(item => {
                                toast.error(item);
                            })
                        })
                    }
                } else {
                    toast.success('Thank you for submitting your request. we will get back to you soon!')
                    navigate('/catering-menu')
                    localStorage.removeItem('catering_cart')
                    localStorage.removeItem('catering_food_plan_cart')
                    localStorage.removeItem('catering_cart_id')
                    localStorage.removeItem('caterer_id')
                }
            } catch (error) {
                toast.error('Unexpected error occurred!')
            }
        }
        else {
            query = { 'user_id': isUserLogin, 'our_venue_services': ourVenueServices, 'catering_cart_id': localStorage.getItem('catering_cart_id') };
            try {
                let res = await axios.post(server + "auth/web/catering-checkout/second-step-form-submit", query);
                let data = await res.data;
                if (data.status === 0) {
                    if (data.message) { toast.error(t(data.message)) }
                    if (data.errors) {
                        Object.keys(data.errors).forEach(key => {
                            data.errors[key].map(item => {
                                toast.error(item);
                            })
                        })
                    }
                } else {
                    navigate('/catering-checkout/step-3')
                }
            } catch (error) {
                toast.error('Unexpected error occurred!')
            }
        }
    }

    useEffect(() => {
        if (isUserLogin && localStorage.getItem('catering_cart_id')) {
            getCateringDates()
            setCateringCart(JSON.parse(localStorage.getItem('catering_cart')))
            setCateringFoodPlanCart(JSON.parse(localStorage.getItem('catering_food_plan_cart')))
        }
        else {
            navigate('/catering')
        }
    }, [])

    return (
        <>
            {/* Banner section Start */}
            <section className="banner-n" style={{ backgroundImage: `url('/assets/front/images/contact-us.jpg')`, backgroundRepeat: "no-repeat" }}>
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end">
                        <div className="col-lg-10 text-center mx-auto wow slideInLeft" data-wow-delay="0.1s text-center" style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                            <h1 className="color-red">Catering Checkout -  Step 2</h1>
                            <ul className="bread">
                                <li><b><Link to="/">Home</Link></b></li>
                                <li className="color-red"><b>Catering Checkout -  Step 2</b></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner section End */}

            {/* Main section Start */}
            <section className="bg-red">
                <div className="container" style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                    <div className="col-12">
                        <ul className="bread mb-2" id="custom-bread">
                            <li>
                                <b><Link to="/catering-cart" className="color-black">Cart</Link></b>
                            </li>
                            <li>
                                <b><Link to="/catering-checkout/step-1" className="color-black">Step 1</Link></b>
                            </li>
                            <li className="color-white"><b>Step 2</b></li>
                        </ul>
                    </div>
                    <div className="contact-wrap" style={{ paddingTop: "5%", paddingBottom: "5%", paddingLeft: "5%", paddingRight: "5%" }}>
                        <div className="col-md-12 ">
                            <div className="form2 h-100">
                                <h2 className="site-font text-center">Step:2</h2>
                                <h3 className="site-font">Catering Details</h3>
                                <div className="row">
                                    {((cateringCart && Object.entries(cateringCart).length > 0) || (cateringFoodPlanCart && Object.entries(cateringFoodPlanCart).length > 0)) ? (
                                        <>
                                            <div className="col-md-12">
                                                <h5>Please select dishes against each event day</h5>
                                            </div>
                                            {cateringDates && cateringDates.length > 0 &&
                                                cateringDates.map((item1, key1) => (
                                                    <div key={key1} className="col-md-6">
                                                        <div className='bg-secondary bg-gradient px-2 py-3 color-white mb-2'>
                                                            <h5> {format(new Date(item1.event_date), 'do  MMMM yyyy')}
                                                                {item1.catering_food !== null ? (<Link to={"#catering_dates" + key1} className="btn btn-dark float-end btn-sm" data-bs-toggle="modal">Food Added</Link>) : (<Link to={"#catering_dates" + key1} className="btn btn-dark float-end btn-sm" data-bs-toggle="modal">Add Food</Link>)}
                                                            </h5>
                                                            <div className="modal fade" id={"catering_dates" + key1} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title color-red" id="exampleModalLabel">{format(new Date(item1.event_date), 'do  MMMM yyyy')}</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <form method="POST" onSubmit={handleFoodSubmit}>
                                                                            <div className="modal-body">
                                                                                <p className="mb-2 color-black">Select either Dishes or Food Plans</p>
                                                                                <input type="hidden" name="date_food_id" defaultValue="" />
                                                                                <h6 className="text-success">Food Dishes</h6>
                                                                                <div className="my-container">
                                                                                    {Object.entries(cateringCart).map(([index, item], key) => (
                                                                                        <label key={key}>
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                defaultValue={index + '/' + item.name + '/' + item.price}
                                                                                                defaultChecked = {item1.catering_food && splitOldFoodArray(item1.catering_food).includes(item.name)}
                                                                                                onClick={(e) => handleFoodArray(e, key, 'Dishes')}
                                                                                                name="food_array" 
                                                                                            />
                                                                                            <span>{item.name}</span>
                                                                                        </label>
                                                                                    ))}
                                                                                </div>
                                                                                <h6 className="mt-3 text-success">Food Plans</h6>
                                                                                <div className="my-container">
                                                                                    {Object.entries(cateringFoodPlanCart).map(([index, item], key) => (
                                                                                        <label key={key}>
                                                                                            <input type="radio" className="food_plans_class" defaultValue={index + '/' + item.name + '/' + item.price} onClick={(e) => handleFoodArray(e, key, 'Plans')} name="food_plan_array" defaultChecked = {item1.catering_food && splitOldFoodArray(item1.catering_food).includes(item.name)} />
                                                                                            <span>{item.name}</span>
                                                                                        </label>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button type="submit" onClick={() => { setEventDateID(item1.id) }} className="btn btn-dark">Submit</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                            <div className="col-12"></div>

                                            <form onSubmit={handleSubmit} className="mb-1" method="post" id="contactForm" name="contactForm">
                                                <div className="col-12">
                                                    <label htmlFor="" className="col-form-label">Do you want to take our venue services?</label>
                                                    <br />
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="our_venue_services" defaultValue="Yes" defaultChecked required onClick={() => setOurVenueServices(1)} />
                                                        <label className="form-check-label">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="our_venue_services" defaultValue="No" onClick={() => setOurVenueServices(0)} />
                                                        <label className="form-check-label">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                {ourVenueServices === 0 &&
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-6 form-group mb-2 outside_venue">
                                                                <label htmlFor="" className="col-form-label">Venue Name *</label>
                                                                <input className="form-control ct-group" onChange={(e) => setVenueName(e.target.value)} type="text" name="venue_name" defaultValue="" />
                                                            </div>
                                                            <div className="col-md-6 form-group mb-2 outside_venue">
                                                                <label htmlFor="" className="col-form-label">Contact Number</label>
                                                                <input className="form-control ct-group" onChange={(e) => setVenuePhoneNo(e.target.value)} type="text" placeholder="+(41) XX XXX XX XX" name="venue_contact_number" id="contactphone" defaultValue="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 form-group mb-2 outside_venue">
                                                            <label htmlFor="" className="col-form-label">Venue Address</label>
                                                            <input className="form-control ct-group" onChange={(e) => setVenueAddress(e.target.value)} type="text" placeholder="House No., Street, City, Zip/Postcode, Country" name="venue_address" defaultValue="" />
                                                        </div>
                                                        <div className="col-12 outside_venue">
                                                            <label htmlFor="" className="col-form-label ">Arrangment Type</label>
                                                            <br />
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" onChange={(e) => setarrangementType('Inside')} type="radio" name="arrangment_type" defaultValue="Inside" defaultChecked />
                                                                <label className="form-check-label">
                                                                    Inside
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" onChange={(e) => setarrangementType('Outside')} type="radio" name="arrangment_type" defaultValue="Outside" />
                                                                <label className="form-check-label">
                                                                    Outside
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-12 form-group mt-3">
                                                    <input type="submit" id="next_Step" value={ourVenueServices===1 ? 'Next Step' : 'Request Booking'} name="second_step" className="btn btn-danger py-2 px-4 text-center follow-1 bg-red" />
                                                    {/* <Link to='/catering-checkout/step-3' className="btn btn-danger py-2 px-4 text-center follow-1 bg-red" >Next Step</Link> */}
                                                    <Link to="/catering-checkout/step-3" className="btn btn-dark py-2 ms-1 px-4 text-center follow-1 bg-red">Skip</Link>
                                                </div>
                                            </form>
                                        </>
                                    ) : (
                                        <form className="mb-1" id="contactForm" name="contactForm">
                                            {/* In case catering cart & catering food plan cart is empty */}
                                            <div className="col-12 form-group mt-3 text-center">
                                                <h6>Please visist catering menu and add dishes in cart if you want to get our catering service.</h6>
                                                <Link to="/catering-menu" className="btn btn-danger py-2 px-4 text-center follow-1 bg-red">Go To Catering Menu</Link>
                                                <Link to="/catering-checkout/step-3" className="btn btn-dark py-2 ms-1 px-4 text-center follow-1 bg-red">Skip To Venue</Link>
                                            </div>
                                        </form>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Main section End */}


        </>
    )
}

export default CateringCheckoutForm2