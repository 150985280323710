import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import { server } from '../../util/server'
import axios from 'axios'
import { useFilterContext } from '../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Star from '../../Components/Star'
import AddRating from '../../Components/AddRating'
import Spinner from '../../Components/Spinner'
const CateringVenueBookings = () => {
    const [spinner, setSpinner] = useState(true);
    const { t } = useTranslation();
    const [dataList, setDataList] = useState([])
    const { project, isUserLogin } = useFilterContext();
    const [rating, setRating] = useState(0)
    const [userMessage, setuserMessage] = useState('')
    const [userName, setuserName] = useState('')
    const [cateringId, setCateringId] = useState()
    const navigate = useNavigate();

    const getCateringVenueData = async () => {
        try {
            let url = server + 'auth/user/catering-venue-bookings/list?api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setDataList(data.bookings)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }
    const splitFoodArray = (foodList) => {
        let foodArray = foodList.split('|')
        let newFoodArray = foodArray.map((foodItem, foodKey) => {
            return foodItem.split('/')
        })
        return newFoodArray
    }
    const handleReviewSubmit = async (event) => {
        event.preventDefault()
        try {
            let url = server + 'auth/user/submit-review'
            let res = await axios.post(url, { 'rating_message': userMessage, 'user_name': userName, 'orderrating': rating, 'order_id': cateringId, 'project': project, 'api_type': 'web', 'user_id': isUserLogin, 'type': 'catering_venue_review' });
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                toast.success(t('Thank You for your kind reviews!'))
                getCateringVenueData()
                setuserMessage('')
                setRating(0)
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }
    const payCharges = async (id) => {
        try {
            let url = server + 'auth/user/catering-venue-bookings/payment'
            let res = await axios.post(url, { 'catering_id': id });
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                toast.success(t('Payment is Done!'))
                getCateringVenueData()
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }
    const cancelBooking = async (id) => {
        try {
            let url = server + 'auth/user/catering-venue-bookings/cancel'
            let res = await axios.post(url, { 'catering_id': id, 'project': 'Winterthur' });
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                toast.success(t('Booking Cancelled!'))
                getCateringVenueData()
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }
    useEffect(() => {
        if (isUserLogin) {
            getCateringVenueData()
        }
        else {
            navigate('/login')
        }
    }, [])
    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Catering/Venue Bookings</h3>
                                <hr />
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead className="text-center">
                                            <tr>
                                                <th></th>
                                                <th>Event Requested By</th>
                                                <th>Services</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-justify">
                                            {dataList && dataList.length > 0 ?
                                                dataList.map((item, key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <Link to={"#venueshow_" + item.catering_id} data-bs-toggle="modal" className="color-black">
                                                                <i className="fa fa-eye"></i>
                                                            </Link>
                                                            <br />
                                                            <div className="modal fade" id={"venueshow_" + item.catering_id} tabIndex="-1" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                        <div className="modal-body pt-4">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <h4 className="text-success font-weight-bold">Customer</h4>
                                                                                    <hr className="my-1" />
                                                                                    <b>Name: </b> {item.first_name} {item.last_name}
                                                                                    <br />
                                                                                    <b>Email: </b> {item.email}
                                                                                    <br />
                                                                                    <b>Phone Number: </b> {item.phone_number}
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <h4 className="text-success font-weight-bold">Order</h4>
                                                                                    <hr className="my-1" />
                                                                                    <b>Order Placed From: </b> {item.project}
                                                                                    <br />
                                                                                    <b>Event Type: </b> {item.event_type}
                                                                                    <br />
                                                                                    <b>Guests: </b> {item.guest}
                                                                                    <br />
                                                                                    <b>Caterer: </b> {item.catering_order_caterer && item.catering_order_caterer.name}
                                                                                    <br />
                                                                                    <b>Caterer Phone Number: </b> {item.catering_order_caterer && item.catering_order_caterer.catering_phone_number}
                                                                                    <br />
                                                                                </div>
                                                                                <div className="col-12 mt-3">
                                                                                    {item.catering_service_id ? (<h4 className="text-success font-weight-bold">
                                                                                        Catering Details (Charges: CHF {item.catering_charges + item.additional_catering_charges})</h4>) : (<h4 className="text-success font-weight-bold">Booking Days</h4>)}
                                                                                </div>
                                                                                <hr className="my-1" />
                                                                                <div className="col-12">
                                                                                    {
                                                                                        item.catering_order_detail && item.catering_order_detail.map((item2, key2) => (
                                                                                            <div key={key2}>{item2.event_date} : {item2.event_start_time} - {item2.event_end_time} <br /></div>
                                                                                        ))
                                                                                    }
                                                                                    {item.catering_service_id &&
                                                                                        <>
                                                                                            <b>Food: </b>
                                                                                            {item.catering_order_detail.map((item3, key3) => (
                                                                                                splitFoodArray(item3.catering_food).map((item4, key4) => (
                                                                                                    item4.map((item5, key5) => (
                                                                                                        <>
                                                                                                            {key5 === 1 ? item5 : ''}
                                                                                                            {item3.food_type === 'Plans' ?
                                                                                                                key5 === 2 && (<> (CHF{item5}<sub>/Per Head</sub>), </>)
                                                                                                                : key5 === 2 && (<> (CHF{item5}), </>)
                                                                                                            }
                                                                                                        </>
                                                                                                    ))
                                                                                                ))
                                                                                            ))
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                                <div className="col-12 mt-3">
                                                                                    <h4 className="text-success font-weight-bold">
                                                                                        Venue Details
                                                                                        {item.venue_charges !== null && item.venue_charges !== 0.00 &&
                                                                                            <>
                                                                                                (Charges: CHF {item.venue_charges + item.additional_venue_charges})
                                                                                            </>
                                                                                        }
                                                                                    </h4>
                                                                                </div>
                                                                                <hr className="my-1" />
                                                                                {item.our_venue_service === 0 ?
                                                                                    (<div className="col-md-12">
                                                                                        <b>Venue Name: </b> {item.venue_name} <br />
                                                                                        <b>Venue Contact Number: </b> {item.venue_contact_number}<br />
                                                                                        <b>Arrangment Type: </b> {item.arrangment_Type}
                                                                                        <br />
                                                                                        <b>Venue Address: </b> {item.venue_address}
                                                                                    </div>) : ''
                                                                                }
                                                                                {item.catering_order_venue &&
                                                                                    <>
                                                                                        <div className="col-md-6">
                                                                                            <b>Venue Name: </b> {item.catering_order_venue.name}
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <b>Venue Contact Number: </b> {item.catering_order_venue.venue_phone_number}
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <b>Venue Address: </b> {item.catering_order_venue.venue_housenumber + ', ' + item.catering_order_venue.venue_street + ', ' + item.catering_order_venue.venue_city + ', ' + item.catering_order_venue.postcode}
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <b>Arrangment Type: </b> {item.arrangment_Type}
                                                                                        </div>
                                                                                        {item.our_venue_service === 1 && item.catering_service_id === null &&
                                                                                            <div className="col-md-6">
                                                                                                <b>Outside Catering: </b> {item.outside_catering === 0 ? 'No' : 'Yes'}
                                                                                            </div>
                                                                                        }
                                                                                        {item.extra_rooms &&
                                                                                            <div className="col-md-6">
                                                                                                <b>Extra Rooms: </b> {item.extra_rooms}
                                                                                            </div>
                                                                                        }
                                                                                        {item.space_required &&
                                                                                            <div className="col-md-6">
                                                                                                <b>Space Required: </b> {item.space_required}
                                                                                            </div>
                                                                                        }
                                                                                        {item.audio_visual_needs &&
                                                                                            <div className="col-md-6">
                                                                                                <b>Audio Visual Needs: </b> {item.audio_visual_needs}
                                                                                            </div>
                                                                                        }
                                                                                        {item.any_delegates &&
                                                                                            <div className="col-md-6">
                                                                                                <b>Any Delegates: </b> {item.any_delegates}
                                                                                            </div>
                                                                                        }
                                                                                        {item.room_setup &&
                                                                                            <div className="col-md-6">
                                                                                                <b>Room Setup: </b> {item.room_setup}
                                                                                            </div>
                                                                                        }
                                                                                        {item.extra_Setup &&
                                                                                            <div className="col-md-6">
                                                                                                <b>Extra Furniture Setup: </b> {item.extra_Setup}
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <b>Order Ref: </b> JECV-{item.catering_id}<br />
                                                            <b>Name: </b>{item.first_name} {item.last_name}<br />
                                                            <b>Event Type: </b> {item.event_type}<br />
                                                            <b>Dates: </b> {item.catering_order_detail &&
                                                                item.catering_order_detail.map((item1, key1) => (
                                                                    <div key={key1}>{item1.event_date} <br /></div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td>
                                                            <b>Catering Charges: </b> {item.catering_charges === 0 ? 'Pending Estimation' : item.catering_charges + item.additional_catering_charges}<br />
                                                            <b>Venue Charges: </b> CHF {item.venue_charges}<br />
                                                            <b>Total: </b>CHF {item.catering_charges + item.additional_catering_charges + item.venue_charges + item.additional_venue_charges}<br />
                                                            <b>Requested At: </b> {format(new Date(item.created_at), 'do  MMMM yyyy')} <br />
                                                        </td>
                                                        <td className='text-center'>
                                                            {item.status === 1 ? (
                                                                <span className="badge text-center text-white bg-success" style={{ fontSize: "15px", padding: "8px 21px", whiteSpace: "pre-wrap" }}>Done</span>
                                                            ) : ''}
                                                            {(item.status === 2 || item.status === 3) ? (
                                                                <span className="badge text-center text-black bg-warning" style={{ fontSize: "15px", padding: "8px 21px", whiteSpace: "pre-wrap" }}>Requested For Approval</span>
                                                            ) : ''}
                                                            {item.status === 4 ? (
                                                                <span className="badge text-center text-black bg-info" style={{ fontSize: "15px", padding: "8px 21px", whiteSpace: "pre-wrap" }}>Confirmed</span>
                                                            ) : ''}
                                                            {item.status === 5 ? (
                                                                <span className="badge text-center text-white bg-danger" style={{ fontSize: "15px", padding: "8px 21px", whiteSpace: "pre-wrap" }}>Cancelled</span>
                                                            ) : ''}
                                                            {item.status === 6 ? (
                                                                <span className="badge text-center text-white bg-danger" style={{ fontSize: "15px", padding: "8px 21px", whiteSpace: "pre-wrap" }}>{
                                                                    item.payment_status === 0 ? 'Payment Pending' : 'Charges Paid'
                                                                }</span>
                                                            ) : ''}
                                                            <br />
                                                            {(item.status === 6 && item.payment_status === 0) ? (<Link to="#" onClick={() => payCharges(item.catering_id)} className="btn btn-dark btn-sm mt-1">Pay Charges</Link>) : ''}
                                                            {(item.status !== 6 && item.payment_status === 1) ? (<Link to="#" className="btn btn-dark btn-sm mt-1">Charges Paid</Link>) : ''}
                                                            <br />

                                                            {item.status === 1 ?
                                                                item.catering_order_review === null ?
                                                                    (<Link to={"#review_" + item.catering_id} className="btn btn-dark mt-1" data-bs-toggle="modal">Review</Link>) : (<Link to={"#seereview_" + item.catering_id} className="btn btn-dark ms-1 mt-1" data-bs-toggle="modal">See Review</Link>)
                                                                : ''
                                                            }

                                                            {(item.status === 4 || item.status === 6) &&
                                                                <Link to="#" onClick={() => cancelBooking(item.catering_id)} className="btn btn-danger ms-1 mt-1" >Cancel Booking</Link>
                                                            }

                                                            <div className="modal fade" id={"seereview_" + item.catering_id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-scrollable test-form">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header t-form-h color-white">
                                                                            <h5 className="modal-title" id="exampleModalLabel color-white">Review</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <div className="modal-body text-start">
                                                                            <b>Rating: </b>
                                                                            <Star stars={item.catering_order_review && item.catering_order_review.review_rating} />
                                                                            <br />
                                                                            <b>Review: </b>{item.catering_order_review && item.catering_order_review.review_data}
                                                                            <br />
                                                                            <b>Given On :</b> {item.catering_order_review && item.catering_order_review.review_date}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal fade" id={"review_" + item.catering_id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-scrollable test-form">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header t-form-h color-white">
                                                                            <h5 className="modal-title" id="exampleModalLabel color-white">Leave Us A Testimonial</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <div className="modal-body ">
                                                                            <form onSubmit={handleReviewSubmit} className="t-form-b needs-validation">
                                                                                <input type="hidden" className="form-control" name="order_id" defaultValue={item.catering_id} />
                                                                                <input type="hidden" className="form-control orderrating" name="orderrating" value="5" />
                                                                                <div className="row">
                                                                                    <div className="col-12 mb-3">
                                                                                        <label className="form-label float-start">
                                                                                            <h6>Service Rating</h6>
                                                                                        </label>
                                                                                        <div className='rating-stars text-center'>
                                                                                            <ul id='stars'>
                                                                                                <AddRating rating={rating} onRating={(rate) => setRating(rate)} />
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <div className="mb-3">
                                                                                            <label className="form-label float-start">
                                                                                                <h6>Your Message</h6>
                                                                                            </label>
                                                                                            <textarea type="text" className="form-control" id="mag-box" rows="7" value={userMessage} onChange={(e) => setuserMessage(e.target.value)} name='message' placeholder="Write your message"></textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="modal-footer d-block">
                                                                                    <button type="submit" onClick={() => { setCateringId(item.catering_id); setuserName(item.first_name) }} className="btn btn-success float-end t-f-btn customer_review_button">Submit</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                                : (
                                                    <tr>
                                                        <td colSpan={4} className='text-center'>
                                                            No Bookings Found!
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CateringVenueBookings