import React, { useEffect, useState } from 'react'
import { server } from '../util/server'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useFilterContext } from '../context/filterContext';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Meta } from '../Components/Meta'
import Spinner from '../Components/Spinner';
export const Menu = () => {
    const [spinner, setSpinner] = useState(true);
    const { i18n, t } = useTranslation();
    const [pageData, setPageData] = useState(null);
    const [data, setData] = useState([]);
    const { updatemetas, updatePageBanner, page_banner, media_server } = useFilterContext();
    const getData = async () => {
        try {
            let url = server + 'auth/web/menu-list?project=Winterthur&page_link=menu&api_type=web'
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setData(data.data)
                updatemetas(null, 0, data.meta)
                updatePageBanner(data.page_banner)
                setPageData(data.page_data)
                setSpinner(false)

            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Meta />
            {
                page_banner &&
                <section className="banner-n" style={{ backgroundImage: `url(${media_server}/${page_banner})` }}>
                    <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                        <div className=" g-0 gx-5 align-items-end">
                            <div className="col-lg-12">
                                <div className="col-lg-10 text-center mx-auto mb-4 wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                    <h1 className="site-font color-red"> <strong>{t("Hungry?")}</strong></h1>
                                    <h2 className="site-font color-whitesmoke">{t("Jetzt Eat is here to serve you")}</h2>
                                    <ul className="bread">
                                        <li><b><Link to="/">{t("Home")}</Link></b></li>
                                        <li className="color-red"><b>{t("Menu")}</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {spinner ? <Spinner /> :
                <>
                    <section>
                        <div className="container m-screen menu-dealing-section mds">
                            <div className="row  align-items-center">
                                <div className="col-lg-6 col-md-12 wow fadeIn " data-wow-delay="0.5s">
                                    {
                                        pageData &&
                                        <>
                                            <h2 className="mb-2 site-font color-red site-font h1 fw-bold" dangerouslySetInnerHTML={{ __html: pageData[0]['name_' + i18n.language] }} />
                                            <div dangerouslySetInnerHTML={{ __html: pageData[0]['text_' + i18n.language] }} />
                                        </>


                                    }


                                </div>
                                <div className="col-lg-6 col-md-6  wow fadeIn b-img" data-wow-delay="0.1s">
                                    <div className=" position-relative overflow-hidden p-5 pe-0 d-none d-md-block d-sm-block padding-10p">
                                        {
                                            pageData && <img className="img-fluid w-100 " src={media_server + "/" + pageData[0].link} alt={pageData[0]['alt_' + i18n.language]} />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="bg-clr py-2 mb-5">
                        <div className="container">
                            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                                <h2 className="mb-3 col-lg-8text-left mx-auto mb-5 wow fadeInUp color-red h1" data-wow-delay="0.5s text-center"><strong>{t("WE SERVE")}</strong></h2>
                                <div className="col-lg-4">
                                    <div className=" col-lg-4 text-left mx-auto mb-5 wow slideInRight" data-wow-delay="0.1s">
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div id="tab-1" className="tab-pane fade show p-0 active">
                                    <div className="row g-4">
                                        {
                                            data.length > 0 && data.map(item => (
                                                <div className="col-lg-4 col-sm-6 wow fadeInUp px-sm-2" data-wow-delay="0.3s" key={item.category_id}>
                                                    <div className="product-grid bg-red pb-4 mx-0">
                                                        <div className="product-image">
                                                            <Link to={"/" + item.item_slug} className="image">
                                                                <img className="pic-1" src={media_server + "/" + item.item_image} alt={item['alt_' + i18n.language]} />
                                                            </Link>
                                                            {
                                                                item.discount_type && <div className="price"> OFF {item.discount} {item.discount_type} </div>
                                                            }

                                                        </div>
                                                        <div className="product-reviews">
                                                            <p className="d-inline ps-2 f-15"><b>{item.categoryfood_count} {t("Dishes")}</b></p>
                                                            <ul className="rating float-end d-inline pe-2">
                                                                <li className={1 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                                                <li className={2 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                                                <li className={3 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                                                <li className={4 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                                                <li className={5 <= item.reviews ? "fas fa-star" : "fas fa-star color-lightgrey"}></li>
                                                            </ul>
                                                        </div>
                                                        <div className="product-content bg-red">
                                                            <h3 className="title"><Link to={"/" + item.item_slug} className="color-white">{item.name}</Link></h3>
                                                            <p className="color-white f-13">{t("From")} CHF{item.min_dishes_price} - CHF{item.max_dishes_price}</p>
                                                            <Link to={"/" + item.item_slug} className="btn btn-dark border-radius-4 f-15">{t("Explore Menu")}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }

        </>
    )
}
