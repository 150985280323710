import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import axios from 'axios'
import { server } from '../../../util/server'
import { useFilterContext } from '../../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'
import Spinner from '../../../Components/Spinner';
import Pagination from "react-js-pagination";

const MealPlannerOngoingOrder = () => {
    const [spinner, setSpinner] = useState(true);
    const { project, isUserLogin } = useFilterContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [plannersList, setPlannersList] = useState([]);
    const [data, setData] = useState([]);
    const getMealPlannerList = async (pageNumber = 1) => {
        try {
            let url = server + `auth/web/meal-planner/ongoing-meal-planner-orders?page=${pageNumber}&api_type=web&user_id=` + isUserLogin + `&project=` + project
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setPlannersList(data.data.data)
                setData(data.data)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getMealPlannerList()
        }
        else {
            navigate('/login')
        }
    }, [])

    const splitFoodArray = (food_items) => {
        let foodArray = food_items.split('|')
        let newFoodArray = foodArray.map((foodItem, foodKey) => {
            return foodItem.split('-')
        })
        return newFoodArray
    }

    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Meal Planner Ongoing Orders</h3>
                                <hr />
                                <div className="accordion" id="accordionExample">
                                    {plannersList && plannersList.length > 0 ?
                                        plannersList.map((item, key) => (
                                            <div className="accordion-item mb-2">
                                                <h2 className="accordion-header" id={"headingOne_" + item.meal_planner_days_id}>
                                                    <button className="accordion-button" style={{ backgroundColor: "black", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne_" + item.meal_planner_days_id} aria-expanded="true" aria-controls={"collapseOne_" + item.meal_planner_days_id}>
                                                        Order ID# JEMP-{item.meal_planner_days_id}
                                                    </button>
                                                </h2>
                                                <div id={"collapseOne_" + item.meal_planner_days_id} className={item.key < 1 ? "accordion-collapse collapse show" : 'accordion-collapse collapse'} aria-labelledby={"headingOne_" + item.meal_planner_days_id} data-bs-parent="#accordionExample">
                                                    <div className="accordion-body" style={{ backgroundColor: "#f7f7f7" }}>
                                                        <article className="card" style={{ backgroundColor: '#f7f7f7', border: 'none' }}>
                                                            <div className="card-body">
                                                                <article className="card" style={{ backgroundColor: '#f7f7f7', border: 'none' }}>
                                                                    <div className="card-body row">
                                                                        <div className="col-md-3 col-sm-6 col-12 mt-2">
                                                                            <strong>Subtotal:</strong>
                                                                            <br />
                                                                            CHF {item.subtotal}</div>
                                                                        <div className="col-md-3 col-sm-6 col-12 mt-2">
                                                                            <strong>Delivery Charges: </strong> <br /> CHF {item.delivery_charges} </div>
                                                                        <div className="col-md-3 col-sm-6 col-12 mt-2">
                                                                            {item.meal_order_rider !== null ?
                                                                                <>
                                                                                    <b>Rider: </b> <br /> {item.meal_order_rider.rider_name}
                                                                                    <Link to={'tel:' + item.meal_order_rider.rider_number}>
                                                                                        <i className="fa fa-phone me-3"></i>
                                                                                    </Link>
                                                                                </>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-3 col-sm-6 col-12 mt-2">
                                                                            <strong>Order Placed From: </strong>
                                                                            <br /> {item.meal_planner.project}
                                                                        </div>
                                                                    </div>
                                                                </article>

                                                                <div className="track">
                                                                    <div className="step active">
                                                                        <span className="icon">
                                                                            <i className="fa fa-check"></i>
                                                                        </span>
                                                                        <span className="text">Confirmed</span>
                                                                    </div>
                                                                    <div className="step active">
                                                                        <span className="icon">
                                                                            <i className="fa fa-user"></i>
                                                                        </span>
                                                                        <span className="text">Preparing</span>
                                                                    </div>
                                                                    {item.order_status === 'Preparing' &&
                                                                        <>
                                                                            <div className="step">
                                                                                <span className="icon">
                                                                                    <i className="fa fa-truck"></i>
                                                                                </span>
                                                                                <span className="text">Picked Up</span>
                                                                            </div>
                                                                            <div className="step">
                                                                                <span className="icon">
                                                                                    <i className="fa fa-box"></i>
                                                                                </span>
                                                                                <span className="text">Delivered</span>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {item.order_status === 'Picked Up' &&
                                                                        <>
                                                                            <div className="step active">
                                                                                <span className="icon">
                                                                                    <i className="fa fa-truck"></i>
                                                                                </span>
                                                                                <span className="text">Picked Up</span>
                                                                            </div>
                                                                            <div className="step">
                                                                                <span className="icon">
                                                                                    <i className="fa fa-box"></i>
                                                                                </span>
                                                                                <span className="text">Delivered</span>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {item.order_status === 'Delivered' &&
                                                                        <>
                                                                            <div className="step active">
                                                                                <span className="icon">
                                                                                    <i className="fa fa-truck"></i>
                                                                                </span>
                                                                                <span className="text">Picked Up</span>
                                                                            </div>
                                                                            <div className="step active">
                                                                                <span className="icon">
                                                                                    <i className="fa fa-box"></i>
                                                                                </span>
                                                                                <span className="text">Delivered</span>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>

                                                                <hr />
                                                                <ul className="row">
                                                                    {item.meal_items !== null &&
                                                                        splitFoodArray(item.meal_items).map((item3, key3) => (
                                                                            <li key={key3} className="col-md-4 col-6">
                                                                                <p className="title mb-0">
                                                                                    {item3[4]}
                                                                                    <br />
                                                                                    Quantity :{item3[1]}
                                                                                </p>
                                                                                <span className="text-muted">
                                                                                    CHF {item3[1] * item3[2]}
                                                                                </span>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                                <hr />
                                                                <div className="row">
                                                                    <div className="col-md-6 m-auto text-center mt-md-0 mt-2">
                                                                        <Link to="#" className="btn btn-warning w-100" data-abc="true">Track Order</Link>
                                                                    </div>
                                                                    <div className="col-md-6 m-auto text-center mt-md-0 mt-2">
                                                                        <Link to="" className="btn btn-warning w-100" data-abc="true">Chat With Rider</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : (<p>No Meal Planners Found</p>)
                                    }
                                </div>
                            </>

                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default MealPlannerOngoingOrder