import React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {useFilterContext} from '../context/filterContext';

export const Meta = () => {
  const { i18n} = useTranslation();
  const {meta} = useFilterContext();
  return (
    <Helmet>
    <title>{meta && meta['meta_title_'+i18n.language] ? meta['meta_title_'+i18n.language] : 'JETZT EAT'}</title>
    <meta name="description" content={meta && meta['meta_description_'+i18n.language]} />
    <meta name="keywords" content={meta && meta['meta_keywords_'+i18n.language]} />
</Helmet>
  )
}
