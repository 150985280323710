import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios'
import { server } from '../../util/server'
import { useFilterContext } from '../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import Spinner from '../../Components/Spinner';

const UserGiftCards = () => {
    const [spinner, setSpinner] = useState(true);
    const { t } = useTranslation();
    const { isUserLogin, media_server } = useFilterContext();
    const [receivedGiftCardsList, setReceivedGiftCardsList] = useState([])
    const [purchasedGiftCardsList, setPurchasedGiftCardsList] = useState([])
    const navigate = useNavigate();
    const getGiftCardsList = async () => {
        try {
            let url = server + 'auth/user/gift-cards?api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data) {
                setPurchasedGiftCardsList(data.purchased_cards)
                setReceivedGiftCardsList(data.recieved_cards)
                setSpinner(false)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getGiftCardsList()
        }
        else {
            navigate('/login')
        }
    }, [])

    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3 giftcards">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Gift Cards</h3>
                                <hr />
                                <Tabs
                                    defaultActiveKey="purchased"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="purchased" title="Purchased" style={{ width: '50% !important' }}>
                                        <div className="row g-4 d-flex text-wrap">
                                            {purchasedGiftCardsList && purchasedGiftCardsList.length > 0 ?
                                                purchasedGiftCardsList.map((item1, key1) => (
                                                    <div key={key1}>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
                                                            <div className="property-item rounded overflow-hidden m-card">
                                                                <div className="position-relative overflow-hidden">
                                                                    <Link to="#" data-bs-target="#exampleModalToggleLabel1" data-bs-toggle="modal" data-bs-dismiss="modal">
                                                                        <img className="img-fluid" src={media_server + "/" + item1.gift_image} alt={item1.card_type} />
                                                                    </Link>
                                                                    <div className="rounded-top  site-font position-absolute start-0 bottom-0 mx-0 pt-0 px-0 menu-card">
                                                                        <Link to="#" data-bs-target={"#modal_1" + item1.id} data-bs-toggle="modal" data-bs-dismiss="modal" className="c-txt">
                                                                            <h4 className="color-white site-font m-card-h">View</h4>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal fade " id={"modal_1" + item1.id} aria-hidden="true" aria-labelledby="exampleModalToggleLabel1" tabIndex="-1">
                                                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                                <div className="modal-content">
                                                                    <div className="modal-header" style={{ backgroundColor: "#ea1d25" }}>
                                                                        <h3 className="modal-title text-center" id="exampleModalToggleLabel2" style={{ color: "whitesmoke" }}>Gift Card</h3>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img className="img-fluid" src={media_server + "/" + item1.gift_image} alt={item1.card_type} />
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-12">
                                                                                <hr />
                                                                                <h6 className="color-red">Amount</h6>
                                                                                <p>CHF {item1.gift_price}</p>
                                                                            </div>
                                                                            <div className="col-md-6 col-12">
                                                                                <hr />
                                                                                <h6 className="color-red">Validation Date</h6>
                                                                                <p>{item1.valid_till}</p>
                                                                            </div>
                                                                            <div className="col-md-6 col-12">
                                                                                <hr />
                                                                                <h6 className="color-red">Sender Name</h6>
                                                                                <p>{item1.sender_name}</p>
                                                                            </div>
                                                                            <div className="col-md-6 col-12">
                                                                                <hr />
                                                                                <h6 className="color-red">Message From Sender</h6>
                                                                                <p>{item1.message ? item1.message : 'No message found!'}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) :
                                                (<p className='text-center'>No Gift Cards Found!</p>)
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="received" title="Received">
                                        <div className="row g-4 d-flex text-wrap">
                                            {receivedGiftCardsList && receivedGiftCardsList.length > 0 ?
                                                receivedGiftCardsList.map((item, key) => (
                                                    <div key={key}>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
                                                            <div className="property-item rounded overflow-hidden m-card">
                                                                <div className="position-relative overflow-hidden">
                                                                    <Link to="#" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">
                                                                        <img className="img-fluid" src={media_server + "/" + item.gift_image} alt={item.card_type} />
                                                                    </Link>
                                                                    <div className="rounded-top  site-font position-absolute start-0 bottom-0 mx-0 pt-0 px-0 menu-card">
                                                                        <Link to="#" data-bs-target={"#modal_2" + item.id} data-bs-toggle="modal" data-bs-dismiss="modal" className="c-txt">
                                                                            <h4 className="color-white site-font m-card-h">View</h4>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="modal fade " id={"modal_2" + item.id} aria-hidden="true" aria-labelledby="exampleModalToggle2" tabIndex="-1">
                                                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                                <div className="modal-content">
                                                                    <div className="modal-header" style={{ backgroundColor: "#ea1d25" }}>
                                                                        <h3 className="modal-title text-center" id="exampleModalToggleLabel2" style={{ color: "whitesmoke" }}>Gift Card</h3>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <img className="img-fluid" src={media_server + "/" + item.gift_image} alt={item.card_type} />
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-12">
                                                                                <hr />
                                                                                <h6 className="color-red">Amount</h6>
                                                                                <p>CHF {item.gift_price}</p>
                                                                            </div>
                                                                            <div className="col-md-6 col-12">
                                                                                <hr />
                                                                                <h6 className="color-red">Validation Date</h6>
                                                                                <p>{item.valid_till}</p>
                                                                            </div>
                                                                            <div className="col-md-6 col-12">
                                                                                <hr />
                                                                                <h6 className="color-red">Sender Name</h6>
                                                                                <p>{item.sender_name}</p>
                                                                            </div>
                                                                            <div className="col-md-6 col-12">
                                                                                <hr />
                                                                                <h6 className="color-red">Message From Sender</h6>
                                                                                <p>{item.message ? item.message : 'No message found!'}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) :
                                                (<p className='text-center'>No Gift Cards Found!</p>)
                                            }
                                        </div>
                                    </Tab>
                                </Tabs>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserGiftCards