import React, { useRef, useEffect, useState } from "react";

import SupportWindow from './SupportWindow'

import Avatar from './Avatar'
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { toast } from "react-toastify";
import { useFilterContext } from '../context/filterContext';
const SupportEngine = () => {
    const {project,media_server} = useFilterContext();
    const wrapperRef = useRef(null);
    const {  t } = useTranslation();
    useOutsideAlerter(wrapperRef);
    const [visible, setVisible] = useState(false)
    const [showChat, setShowChat] = useState([])
    const [showinitialChat, setshowInitialChat] = useState(null)
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    // setVisible(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const getCustomerChat = async() => {
        if (sessionStorage.getItem('chat_id')) {
            await axios({
                method: "get",
                url: media_server + "/api/auth/web/customer-support/get-chat?project="+project+"&session_id=" + sessionStorage.getItem('chat_id'),
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    if(response.data.status === 0){
                        sessionStorage.removeItem('chat_id')  
                    }else{
                        if(response.data.customer_initial_chat){
                            setshowInitialChat(response.data.customer_initial_chat)
                            setShowChat([])
                        }else{
                            setShowChat(response.data.data)
                            setshowInitialChat(null)
                        }
                    }
                    
                })
                .catch(function (response) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }
    }


    const openChatEng = () => {
        getCustomerChat();
        setVisible(true)
    }

    return (
        <div ref={wrapperRef}>
            <SupportWindow visible={visible} setVisible={setVisible} showChat={showChat} setShowChat={setShowChat} showinitialChat={showinitialChat} setshowInitialChat={setshowInitialChat} />

            <Avatar 
                onClick={() => openChatEng()}
                style={{
                    position: 'fixed',
                    bottom: '24px',
                    right: '24px',
                }}
            />
        </div>
    )
}

export default SupportEngine;

