import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import { server } from '../../util/server';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../../context/filterContext';
const CateringFoodPlan = ({ food_plan_id }) => {
    const foodPlanId = food_plan_id;
    const { project, media_server } = useFilterContext();
    const { t } = useTranslation();
    const [foodPlanDetail, setFoodPlanDetail] = useState();
    const [foodList, setFoodList] = useState([]);
    const [cateringFoodCart, setCateringFoodCart] = useState();

    const getFoodPlanDetail = async () => {
        try {
            let url = server + 'auth/web/catering/catering-food-plan-detail?food_plan_id=' + foodPlanId + '&api_type=web&project=' + project
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setFoodPlanDetail(data.food_plan_Detail)
                setFoodList(data.food_list)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const splitNutrientsArray = (nutrientsList) => {
        let nutrientsArray = nutrientsList.split('|')
        let newnutrientsArray = nutrientsArray.map((foodItem, foodKey) => {
            return foodItem.split('/')
        })
        return newnutrientsArray
    }

    const addToCart = (food_id, food_type, food_name, food_price, food_image, caterer_id) => {
        let url = server + "auth/web/catering/add-to-cart"
        axios.post(url, { 'food_id': food_id, 'food_type': food_type, 'food_name': food_name, 'price': food_price, 'image': food_image, 'caterer_id': caterer_id, 'catering_foodplan_cart_array': JSON.parse(localStorage.getItem('catering_food_plan_cart')) }).then(function (result) {
            if (result.data.status === 0) {
                toast.error(t(result.data.message));
            } else {
                setCateringFoodCart(result.data.catering_foodplan_cart_array)
                var caterer_id = Object.values(result.data.catering_foodplan_cart_array)[0].caterer_id;
                localStorage.setItem('caterer_id', caterer_id)
                var catering_food_plan_cart = JSON.stringify(result.data.catering_foodplan_cart_array)
                localStorage.setItem('catering_food_plan_cart', catering_food_plan_cart)
                toast.success('Food Plan is added to cart!');
            }
        })
            .catch(function (error) {
                toast.error(t("Unexpected error occurred!"));
            });
    }

    useEffect(() => {
        getFoodPlanDetail()
    }, [])
    return (
        <>
            <div className="tab-content col-sm-12">
                <h3 className="text-center color-red mb-0">{foodPlanDetail && foodPlanDetail.name} Dishes</h3>
                <h6 className="text-center color-black">CHF {foodPlanDetail && foodPlanDetail.plan_price_per_head}/<sub>Per Head</sub></h6>
                <div id="tab-1" className="tab-pane fade show p-0 active">
                    <div className="row g-4">
                        {foodList.length > 0 ?
                            foodList.map((item, key) => (
                                <>{key === 0 ? (Number(localStorage.getItem('caterer_id')) === item.caterer_id) ? (
                                    <h6 className="text-center"><button onClick={() => addToCart(foodPlanDetail.id, 'Plan', foodPlanDetail.name, foodPlanDetail.plan_price_per_head, foodPlanDetail.image, item.caterer_id)} className="btn btn-dark bg-black color-white btn-sm" type="button" >Add to Cart</button></h6>
                                ) : (
                                    <h6 className="text-center"><button className="btn btn-dark bg-black color-white btn-sm" type="button" onClick={() => { if (window.confirm('Adding this dish to cart will remove all other dishes from another plan. Are you sure you want to continue?')) { localStorage.removeItem('catering_food_plan_cart'); localStorage.removeItem('caterer_id'); addToCart(foodPlanDetail.id, 'Plan', foodPlanDetail.name, foodPlanDetail.plan_price_per_head, foodPlanDetail.image, item.caterer_id) }; }}>Add to Cart</button></h6>
                                )
                                    : ('')
                                }
                                    <div key={key} className="col-md-4 col-sm-6 col-xs-12 wow fadeInUp " data-wow-delay="0.2s">
                                        <div className="product-grid fd-shadow">
                                            <div className="product-image">
                                                <Link to="#" className="image">
                                                    <img className="pic-1" src={media_server + '/' + item.image} alt={item.name} width="100%" style={{ height: '200px' }} />
                                                </Link>
                                            </div>
                                            <div className="dishes-name">
                                                <h5 className="text-center color-red"><b>{item.name}</b></h5>
                                            </div>
                                            <div className="product-content-fd fd-card-clr">
                                                <h3 className="title text-center justify-content-center fd-price">
                                                    CHF {item.price}
                                                </h3>
                                                <div className="product-content-fd">
                                                    <a className="btn btn-danger fd-btn" data-bs-toggle="modal" href={"#catering_food_popup_" + item.id} role="button">Quick View</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade " id={"catering_food_popup_" + item.id} aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg ">
                                                <div className="modal-content bg-red-gradient">
                                                    <div className="fd-popup-1">
                                                        <div type="button" className=" float-end fd-popup-2" data-bs-dismiss="modal" aria-label="Close">
                                                            <i className="fa fa-times-circle color-white fd-popup-3"></i>
                                                        </div>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row ">
                                                            <div className="col-lg-4 ">
                                                                <div className="fd-popup-4">
                                                                    <img src={media_server + '/' + item.image} className="fd-popup-img" alt={item.name} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 color-white ">
                                                                <h3 className="mb-0">{item.name}</h3>
                                                                <h5 className="color-black">CHF {item.price}</h5>
                                                                <p className="fd-popup-6">Allergens:</p>
                                                                <div className="my-container-fd-popup fd-popup-7">
                                                                    <label>
                                                                        <input defaultValue="bread" />
                                                                        <span className="color-white color-white fd-popup-8">{item.allergens}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="fd-popup-9">
                                                            <section style={{ backgroundColor: '#fff' }} id="desk_desc">
                                                                <div className="product-container catering-food-modal">
                                                                    <Tabs
                                                                        defaultActiveKey="description"
                                                                        id="uncontrolled-tab-example"
                                                                        className="mb-3 tabs text-center"
                                                                    >
                                                                        <Tab eventKey="description" title="Description" className="tabby-tab text-center">
                                                                            <p className='text-center text-black pb-4'>
                                                                                {item.description}
                                                                            </p>
                                                                        </Tab>
                                                                        <Tab eventKey="nutrients" title="Nutrients" className="tabby-tab text-center">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-bordered text-center" border="1" align="center" cellSpacing="10" cellPadding="10" style={{ color: '#000', borderColor: '#000', width: '500px' }}>
                                                                                    <tbody>
                                                                                        {
                                                                                            splitNutrientsArray(item.nutrients).map((item2, key2) => (
                                                                                                <tr key={key2}>
                                                                                                    <th>{item2[0]}</th>
                                                                                                    <td>{item2[1]}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </Tab>
                                                                    </Tabs>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )) : (<p>No dishes found against this Food Plan!</p>)
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CateringFoodPlan