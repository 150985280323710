import React from 'react'
import { Link } from 'react-router-dom'
const CateringEvents = () => {
    return (
        <>
            {/* Catering Events Banner Section Start */}
            <section className="banner-n events_banner_image">
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end">
                        <div className="col-lg-12">
                            <div className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p" data-wow-delay="0.1s text-center ">
                                <h1 className="color-red">Catering Events</h1>
                                <ul className="bread">
                                    <li><b><Link to="/">Home</Link></b></li>
                                    <li><b><Link to="/catering">Catering</Link></b></li>
                                    <li className="color-red"><b>Catering Events</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Catering Events Banner Section Start */}

            {/* About Section Start */}
            <section style={{ backgroundColor: "lightgray" }}>
                <div className="container m-screen" style={{ paddingBottom: "7%", paddingTop: "7%", paddingRight: "3%", paddingLeft: "3%" }}>
                    <div className="row  align-items-center">
                        <div className="col-md-12 wow fadeIn m-screen text-center" data-wow-delay="0.5s">
                            <h2 className="mb-4 site-font color-red h1" style={{ fontFamily: "Euclid Circular A" }}>
                                <strong>CATERING EVENTS</strong>
                            </h2>
                            <p className="mb-4 center-justify" style={{ fontFamily: "Euclid Circular A" }}>
                                Catering is not just about serving the food, but it is an input on the design of your event. You will feel delighted by getting to know that we are not only a stand-up aperitif for a festive dinner for your wedding but also a team event. It does not matter how big the challenge is or how far the distance is. We serve anywhere, at any time, in Switzerland. Dealing with professionals, whether in small businesses or at large dinner parties, means your event will be for a short time, but the memories of this event will be everlasting and fresh in your guests' minds.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* About Section End */}

            {/* Main Section Start */}
            <div className="container-fluid catering-banner">
                <div className="catering-banner"></div>
                <div className="">
                    <br /><br />
                    <div className="container">
                        <div className="row g-5 align-items-center">
                            {/* Item 1 Start  */}
                            <div className="col-lg-6 wow fadeIn move-up" data-wow-delay="0.5s">
                                <h2 className="catering-red h1 color-red fw-bold">
                                    WEDDING CATERING
                                </h2>
                                <div className="mb-4 left-justify color-white">
                                    <p>Our catering services are of different types from different kitchens at remote
                                        locations. Wedding catering is one of the types from them. Different types of
                                        wedding catering packages we are also offering to our customers. We frequently
                                        find, especially with new clients, that cooking can fairly overpower. Whether your
                                        organization is requesting a gathering, or you are a recently drawn-in couple
                                        arranging your large wedding day, there are ways of working on your cooking and
                                        setting aside cash to get it done. However, in a real sense much heavenly food
                                        choices crossing many menus, it very well may be hard to figure out where to begin.
                                        So, we figured we would share straightforward, compelling ways of making a
                                        difference.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp move-down" data-wow-delay="0.1s" style={{ marginBottom: "40px" }}>
                                <div className=" position-relative overflow-hidden pe-0" style={{ marginBottom: "-47px" }}>
                                    <img className="img-border img-fluid w-100" data-aos="zoom-in" height="250px" width="400px" src="https://demo.ninjashushi.ch/backend/storage/setting/1660809997_imgpsh_fullsize_anim%20(11).png" alt="" />
                                </div>
                            </div>
                            {/* Item 1 End */}
                            <br /><br />
                            <div className="catering-banner"></div>
                            {/* Item 2 Start */}
                            <div className="col-lg-6 wow fadeInUp move-down" data-wow-delay="0.1s" style={{ marginBottom: "40px" }}>
                                <div className=" position-relative overflow-hidden pe-0" style={{ marginBottom: "-47px" }}>
                                    <img className="img-border img-fluid w-100" data-aos="zoom-in" height="250px" width="400px" src="https://demo.ninjashushi.ch/backend/storage/setting/1660809997_concession.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn move-up" data-wow-delay="0.5s">
                                <h2 className="catering-red h1 color-red fw-bold">
                                    CONCESSION CATERING
                                </h2>
                                <div className="mb-4 left-justify color-white">
                                    <p>All about your sporting events, live concerts, or any seasonal event we are dedicated
                                        to providing you all our best services in catering. We are not claiming to offer a large
                                        variety of food but our main focus is on the high quality and famous food to serve
                                        you quickly.</p>
                                    <p><i className="fa fa-circle  me-3 color-red"></i> Sporting Events</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Live Concerts</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Seasonal Events</p>
                                </div>
                            </div>
                            {/* Item 2 End */}
                            <br /><br />
                            <div className="catering-banner"></div>
                            {/* Item 3 Start */}
                            <div className="col-lg-6 wow fadeIn move-up" data-wow-delay="0.5s">
                                <h2 className="catering-red h1 color-red fw-bold">
                                    CORPORATE CATERING
                                </h2>
                                <div className="mb-4 left-justify color-white">
                                    <p>WHAT? If you are running an organization? Or you are an employee of an
                                        organization? Are you looking forward to catering services for your business parties
                                        and business meetings? Our corporate catering services are now here to facilitate
                                        your all problems. Choose us! Tell us what you want, when you want it? We’ll
                                        deliver according to your choice. Sometimes it will help you save your bit of money
                                        and pickup time</p>
                                    <p><i className="fa fa-circle  me-3 color-red"></i> office party groups</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Company Day or Founder’s Day catering</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Catered residential conferences and training sessions</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Corporate retreat food and fun</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Interview refreshments</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Outdoor catering and executive dining rooms</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Coworker and share food and drinks</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Gala dinners and buffets for award ceremonies</p>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp move-down" data-wow-delay="0.1s" style={{ marginBottom: "40px" }}>
                                <div className=" position-relative overflow-hidden pe-0" style={{ marginBottom: "-47px" }}>
                                    <img className="img-border img-fluid w-100" data-aos="zoom-in" height="250px" width="400px" src="https://demo.ninjashushi.ch/backend/storage/setting/1660809997_coorporate.jpg" alt="" />
                                </div>
                            </div>
                            {/* Item 3 End */}
                            <br /><br />
                            <div className="catering-banner"></div>
                            {/* Item 4 Start */}
                            <div className="col-lg-6 wow fadeInUp move-down" data-wow-delay="0.1s" style={{ marginBottom: "40px" }}>
                                <div className=" position-relative overflow-hidden pe-0" style={{ marginBottom: "-47px" }}>
                                    <img className="img-border img-fluid w-100" data-aos="zoom-in" height="250px" width="400px" src="https://demo.ninjashushi.ch/backend/storage/setting/1660810062_festival2.webp" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn move-up" data-wow-delay="0.5s">
                                <h2 className="catering-red h1 color-red fw-bold">
                                    FESTIVALS
                                </h2>
                                <div className="mb-4 left-justify color-white">
                                    <p>The popularity of Switzerland depends on crystal-clear medieval towns beautiful
                                        alpine mountains, and unforgettable natural scenery. You will be pleased by our
                                        adapted offer for your festival events. Our mission is to find something delicious
                                        that will give you an authentic feel for the country and not waste a meal on so-so
                                        food, but sometimes it can be tricky to identify what is truly going to taste good</p>
                                    <p><i className="fa fa-circle  me-3 color-red"></i> Seasonal festivals</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Cultural and historical events</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Film festivals</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Music and art festivals</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Food and drink festivals</p>
                                </div>
                            </div>
                            {/* Item 4 End */}
                            <br /><br />
                            <div className="catering-banner"></div>
                            {/* Item 5 Start */}
                            <div className="col-lg-6 wow fadeIn move-up" data-wow-delay="0.5s">
                                <h2 className="catering-red h1 color-red fw-bold">
                                    SOCIAL EVENTS CATERING
                                </h2>
                                <div className="mb-4 left-justify color-white">
                                    <p>All social events are really valuable in our lives. These events may be grand
                                        openings, birthday parties, house warming parties, retirement parties, or baby &amp;
                                        bridal showers. We are offering such types of catering services that may be included
                                        in social catering packages. Due to their different nature, their menus also vary from
                                        party to party. We are careful about our client’s food allergies, dietary needs, cultural
                                        beliefs, and preferences to make their events successful.</p>
                                    <p><i className="fa fa-circle  me-3 color-red"></i> Grand openings</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Birthday parties</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> House warming parties</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Retirement parties</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Baby shower</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Bridal shower</p>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp move-down" data-wow-delay="0.1s" style={{ marginBottom: "40px" }}>
                                <div className=" position-relative overflow-hidden pe-0" style={{ marginBottom: "-47px" }}>
                                    <img className="img-border img-fluid w-100" data-aos="zoom-in" height="250px" width="400px" src="https://demo.ninjashushi.ch/backend/storage/setting/1660809997_imgpsh_fullsize_anim%20(13).png" alt="" />
                                </div>
                            </div>
                            {/* Item 5 End */}
                            <br /><br />
                            <div className="catering-banner"></div>
                            {/* Item 6 Start */}
                            <div className="col-lg-6 wow fadeInUp move-down" data-wow-delay="0.1s" style={{ marginBottom: "40px" }}>
                                <div className=" position-relative overflow-hidden pe-0" style={{ marginBottom: "-47px" }}>
                                    <img className="img-border img-fluid w-100" data-aos="zoom-in" height="250px" width="400px" src="https://demo.ninjashushi.ch/backend/storage/setting/1660809997_imgpsh_fullsize_anim%20(12).png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn move-up" data-wow-delay="0.5s">
                                <h2 className="catering-red h1 color-red fw-bold">
                                    Private parties
                                </h2>
                                <div className="mb-4 left-justify color-white">
                                    <p>Do you want to take your parties to an extreme level and serve yourself with a unique
                                        food experience from the fabulous venue or comfort of your own home? Yes! You
                                        are at the right place where you can fulfill your desires come true. No one can put a
                                        finger on you because our well professional and well-experienced team and chefs
                                        know what they should do and what you want. Custom designs of the menu with a
                                        reasonable budget will be quite acceptable to you and your guests simultaneously.
                                        No matter Your private parties may include your:</p>
                                    <p><i className="fa fa-circle  me-3 color-red"></i> Weddings</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Birthdays</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Themed Parties</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Wedding Anniversaries</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> Family Events</p>
                                    <p><i className="fa fa-circle ry me-3 color-red"></i> VIP Events</p>
                                </div>
                            </div>
                            {/* Item 6 End */}
                            <br /><br />
                            <div className="catering-banner"></div>
                            {/* Item 5 Start */}
                            <div className="col-lg-6 wow fadeIn move-up" data-wow-delay="0.5s">
                                <h2 className="catering-red h1 color-red fw-bold">
                                    FUNERAL & MEMORIAL CATERING
                                </h2>
                                <div className="mb-4 left-justify color-white">
                                    <p>Although funerals &amp; memorials of loved ones are the time of sorrow, catering is not
                                        the only and first thing that comes to mind. But the ending of a solemn occasion by
                                        sharing a meal when friends and family together to mourn the passing of their loved
                                        one. Whether you are hosting your friends and family at your own home or in any
                                        venue relevant to the memorial. Whenever and wherever you will want funeral
                                        catering we are here to provide everything you need to fulfill your objectives.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp move-down" data-wow-delay="0.1s" style={{ marginBottom: "40px" }}>
                                <div className=" position-relative overflow-hidden pe-0" style={{ marginBottom: "-47px" }}>
                                    <img className="img-border img-fluid w-100" data-aos="zoom-in" height="250px" width="400px" src="https://demo.ninjashushi.ch/backend/storage/setting/1660809997_funeral.jpg" alt="" />
                                </div>
                            </div>
                            {/* Item 5 End */}
                        </div>
                    </div>
                    <br /><br />
                </div>
                <div className="catering-banner"></div>
            </div>
            {/* Main Section End */}
        </>
    )
}

export default CateringEvents