import React, { useMemo, useState } from 'react'
const Star = ({ count, rating, color, onRating }) => {

    const [hoverRating, setHoverRating] = useState(0)
    const getColor = index => {
        if(hoverRating >= index){
            return color.filled;
        }
        else if(!hoverRating && rating >= index){
            return color.filled;
        }
        else{
            return color.unfilled;
        }
    }
    const starRating = useMemo(() => {
        return Array(count)
        .fill(0)
        .map((_, i) => i+1)
        .map( idx => (
            <i key={idx}
            className='fas fa-star cursor-pointer ms-1 fs-5'
            onClick={() => onRating(idx)}
            style={{ color: getColor(idx) }}
            onMouseEnter= {() => setHoverRating(idx)}
            onMouseLeave = {() => setHoverRating(0)}
            ></i>
        ))
    }, [count, rating, hoverRating])

  return (
    <div>
    {starRating}
    </div>
  )
}

Star.defaultProps = {
    count: 5,
    rating: 0,
    color: {
        filled: '#dd201c',
        unfilled: '#e3e3e3'
    }
}

export default Star