import React from 'react'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFilterContext } from '../context/filterContext';
import { toast } from "react-toastify";
import { server } from '../util/server';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const PlannerDishes = ({ plannerInfo, checkboxes, setPlannerDataStatus, setPlannerDaysDataStatus, plannerDays }) => {
    const { isUserLogin, project, media_server } = useFilterContext();
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const { state } = useLocation()
    const [dishesOverlay, setDishesOverlay] = useState(false)
    const [categoriesList, setCategoriesList] = useState(true)
    const [subCategoriesList, setSubCategoriesList] = useState(false)
    /* overlay states*/
    const [mealData, setMealData] = useState({ 'mealTime': '', 'mealDay': null, 'mealWeek': null, 'mealNumber': null, 'mealCategory': null, 'mealRestaurant': null })
    const [menuList, setMenuList] = useState([])
    const [menuItemFoodList, setMenuItemFoodList] = useState([])
    const [selectedFood, setSelectedFood] = useState([]);
    const [addresses, setAddresses] = useState([])
    const [deliveryOption, setDeliveryOption] = useState(0)
    //console.log(plannerInfo,checkboxes,plannerDays);
    const getData = async () => {
        try {
            let url = server + 'auth/web/menu-list?project=' + project
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setMenuList(data.data)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const getAddresses = async () => {
        try {
            let url = server + 'auth/user/checkout-delivery?project=' + project + '&api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setAddresses(data.data)

            } else {
                toast.error(data.message)
            }
        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }


    useEffect(() => {
        if (sessionStorage.getItem('planner_session')) {
            getData()
            getAddresses()
        } else {
            navigate('/meal-planner')
        }

    }, [])



    const showFoodItems = (e, category_id, mealRestaurant) => {
        e.preventDefault();
        if (!mealData.mealTime) {
            alert("Please select Meal Time")
        } else {
            axios.get(server + "auth/web/show-food-item?restaurant_id=" + mealRestaurant + "&category_id=" + category_id)
                .then(function (response) {
                    if (response.data.status === 1) {
                        setMenuItemFoodList(response.data.data)
                        setCategoriesList(false)
                        setSubCategoriesList(true)
                        setMealData({ ...mealData, 'mealCategory': category_id, 'mealRestaurant': mealRestaurant })
                    } else {
                        toast.error(response.data.message);
                    }

                })
                .catch(function (error) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }



    }

    const slugify = (str) => {
        // Replace special characters with their corresponding ASCII characters
        let slug = str
            .replace(/[^\w\s-]/g, '')
            .replace(/-/g, ' ')
            .trim();

        // Replace spaces with hyphens and convert to lowercase
        slug = slug.replace(/\s+/g, '-').toLowerCase();

        return slug;
    };
    const backToCategoriesList = () => {
        setCategoriesList(true)
        setSubCategoriesList(false)
    }

    const OverlayData = (mealDay, mealWeek, mealNumber) => {
        setMealData({ ...mealData, 'mealDay': mealDay, 'mealWeek': mealWeek, 'mealNumber': mealNumber })
        setDishesOverlay(true)
        setCategoriesList(true)
    }

    const updateQuan = (type, food_id) => {
        let quantity = document.getElementById('quantity_' + food_id).innerText;
        let updated_quantity;
        if(type === 'decrement' && parseInt(quantity) === 0){
            updated_quantity = 0
        }else{
            updated_quantity = type === 'increment' ? parseInt(quantity) + 1 : parseInt(quantity) - 1
            
        }
        

        setSelectedFood(prevArray => {
            const index = prevArray.findIndex(obj => obj.food_id === food_id);

            if (index !== -1) {
                const newArray = [...prevArray];
                newArray[index] = { ...newArray[index], quantity: updated_quantity };
                return newArray;
            } else {
                return [...prevArray, { food_id: food_id, quantity: updated_quantity }];
            }
        });

        document.getElementById('quantity_' + food_id).innerText = updated_quantity
    }

    const submitFinalData = () => {

        if (selectedFood.length === 0) {
            toast.error("Please select at least 1 dish to proceed!")
        } else if (deliveryOption === 0) {
            alert("Please select delivery address!")
        } else {
            axios.post(server + 'auth/web/save-planner-day-data', {
                'meal_day': mealData.mealDay,
                'meal_time': mealData.mealTime,
                'meal_category_id': mealData.mealCategory,
                'meal_restaurant': mealData.mealRestaurant,
                'planner_id': sessionStorage.getItem('planner_session'),
                'meal_number': mealData.mealNumber,
                'meal_week': mealData.mealWeek,
                'api_type': 'web',
                planner_day_meal_food: selectedFood,
                'delivery_address': deliveryOption


            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            ).then(function (response) {
                if (response.data.status === 1) {
                    setDishesOverlay(false)
                    document.getElementById(slugify(mealData.mealDay + ' - ' + mealData.mealNumber)).style.backgroundImage = "linear-gradient(to bottom, #000, rgba(154, 40, 87, 0.24)), url(`${media_server}/front/images/pl.jpg`)"
                    document.getElementById(slugify(mealData.mealDay + ' - ' + mealData.mealNumber)).innerText = mealData.mealNumber + " Added"
                    sessionStorage.removeItem('planner_total')
                    sessionStorage.setItem('planner_total', response.data.total)
                    document.getElementById("cus_total").innerText = 'CHF ' + response.data.total
                    setMealData({ 'mealTime': '', 'mealDay': null, 'mealWeek': null, 'mealNumber': null, 'mealCategory': null, 'mealRestaurant': null })
                    setSelectedFood([])
                    setSubCategoriesList(false)
                    setMenuItemFoodList([])
                    setDeliveryOption(0)
                } else {
                    alert(response.data.message)
                }


            })
                .catch(function (error) {
                    toast.error(t("Unexpected error occurred!"));
                });

            //
        }
    }

    const checkCartCondition = (e) => {
        e.preventDefault();
        axios.post(server + 'auth/web/check-for-data-before-cart', {
            'planner_id': sessionStorage.getItem('planner_session'),
            'num': plannerInfo.planner_type == 'monthly' ? checkboxes.length * 7 : checkboxes.length,
            'api_type': 'web'
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(function (response) {
            if (response.data.status === 1) {
                navigate('/meal-cart')
            } else {
                alert(response.data.message)
            }


        })
            .catch(function (error) {
                toast.error(t("Unexpected error occurred!"));
            });

    }

    const backToPlanner = () => {
        setPlannerDataStatus(true)
        setPlannerDaysDataStatus(false)
    }




    return (
        <>
            
            {/* Meal Planner Dishes Start */}
            <div className="bg-clr-white">
                <div className="card-container p-5  meal-sec">
                    <h2 className="text-center color-red p-4 h1"><b>Our Plans</b></h2>
                    <div className="row mt-5" id="planner-dishes-1">
                        <div className="col-12 text-end">
                            <b className="me-3 color-red">Total:</b>
                            <span id="cus_total">CHF {sessionStorage.getItem('planner_total') ? sessionStorage.getItem('planner_total') : 0}</span>
                        </div>
                        {
                            plannerInfo.planner_type !== 'monthly' &&
                            checkboxes.map(item => (
                                <>
                                    {/* meal row */}
                                    <div className="col-12">
                                        <h3 className="color-black text-center"> {item.value}</h3>
                                    </div>
                                    <div className="tab-content">
                                        <div id="tab-1" className="tab-pane fade show p-0 active">
                                            <div className="row g-4 d-flex text-wrap">
                                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                    <button type="button" className="five" id={slugify(item.value + ' - Meal 1')} onClick={() => OverlayData(item.value, 0, 'Meal 1')}>
                                                        Meal 1
                                                    </button>
                                                </div>
                                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                    <button type="button" className="five" id={slugify(item.value + ' - Meal 2')} onClick={() => OverlayData(item.value, 0, 'Meal 2')}>
                                                        Meal 2
                                                    </button>
                                                </div>
                                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                    <button type="button" className="five" data-meal="Meal 3" id={slugify(item.value + ' - Meal 3')} onClick={() => OverlayData(item.value, 0, 'Meal 3')}>
                                                        Meal 3
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Meal row */}

                                </>

                            ))
                        }
                        {
                            plannerInfo.planner_type === 'monthly' &&
                            checkboxes.map(item => (
                                <>
                                    <div className="col-12">
                                        <h3 className="color-white bg-green p-2 text-center"> {item.value} </h3>
                                    </div>
                                    {
                                        Object.entries(plannerDays).map(([weekindex, weekdays], key) => (
                                            weekindex === item.value
                                            &&
                                            weekdays.map(weekdays => (
                                                <>
                                                    {/* meal row */}
                                                    <div className="col-12">
                                                        <h3 className="color-black text-center"> {weekdays}</h3>
                                                    </div>
                                                    <div className="tab-content">
                                                        <div id="tab-1" className="tab-pane fade show p-0 active">
                                                            <div className="row g-4 d-flex text-wrap">
                                                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                                    <button type="button" className="five" id={slugify(weekdays + ' - Meal 1')} onClick={() => OverlayData(weekdays, item.value, 'Meal 1')}>
                                                                        Meal 1
                                                                    </button>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                                    <button type="button" className="five" id={slugify(weekdays + ' - Meal 2')} onClick={() => OverlayData(weekdays, item.value, 'Meal 2')}>
                                                                        Meal 2
                                                                    </button>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                                                    <button type="button" className="five" data-meal="Meal 3" id={slugify(weekdays + ' - Meal 3')} onClick={() => OverlayData(weekdays, item.value, 'Meal 3')}>
                                                                        Meal 3
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Meal row */}
                                                </>
                                            ))
                                        ))
                                    }
                                </>

                            ))
                        }
                        {/* Week 1 Meals */}
                        {/* <div className="col-12">
                            <h3 className="color-white bg-green p-2"> Week 1 </h3>
                        </div> */}



                        <div className="col-12">
                            <Link to="#" onClick={(e) => checkCartCondition(e)} className="btn btn-success mt-5 float-end" disabled>
                                Go to cart
                            </Link>
                            <Link to="#" onClick={(e) => backToPlanner(e)} className="btn btn-success mt-5 float-end me-1" style={{ backgroundColor: "#000" }}>
                                Back
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
            {/* Meal Planner Dishes End */}


            {/* Meal Planner Overlay Start */}
            {dishesOverlay &&
                <div id="myplannerNavigation" className="overlay-2 overlay-width" style={{ width: '100%' }}>
                    <Link to="#" className="closebtn color-white" onClick={() => setDishesOverlay(false)}>&times;</Link>
                    <form id="mealForm">
                        <div className="container mt-5 mb-5">
                            <div className="d-flex justify-content-center row">
                                <div className="col-md-11">
                                    <h3 id="meal_num" className="color-red">{mealData.mealDay} - {mealData.mealNumber}</h3>
                                </div>
                                <div className="col-md-7 col-sm-5 mt-3">
                                    <h5 className="color-white text-end mt-2">Select Time</h5>
                                </div>
                                <div className="col-md-5 col-sm-7 mt-3">
                                    <input className="form-control" name="time" value={mealData.mealTime} onChange={(e) => setMealData({ ...mealData, 'mealTime': e.target.value })} type="time" id="meal_time" />
                                </div>
                                {/* Categories List Start */}
                                {categoriesList &&
                                    <div className="col-12" id="category_list">
                                        <h4 className="color-red mt-3">Please Select Menu</h4>
                                        {
                                            menuList.length > 0 && menuList.map(menu_list_item => (
                                                <div className="m-account accordion-menu">
                                                    <Link to="#" onClick={(e) => showFoodItems(e, menu_list_item.category_id, menu_list_item.restaurant_id)} className="d-block sidebar dropdownlink"><i className="fa fa-arrow-right ms-3"></i> {menu_list_item.item_name}</Link>
                                                </div>
                                            ))
                                        }

                                    </div>
                                }
                                {/* Categories List End */}
                                {subCategoriesList &&
                                    <>
                                        {/* subcategory list Start */}
                                        <div className="col-md-11 col-12" id="subcategory_list">
                                            <p className="color-white">Please Select Dishes</p>
                                            {
                                                menuItemFoodList.length > 0 &&
                                                menuItemFoodList.map(subcat => (
                                                    <>
                                                        <h4 className="color-red">{subcat.name}</h4>
                                                        {
                                                            subcat.subcategoryfood.length > 0 && subcat.subcategoryfood.map(food => (
                                                                <div div className="" >
                                                                    <div className="d-flex flex-row justify-content-md-between align-items-center  bg-white   rounded  meal-ovr-pd flex-wrap">

                                                                        <div className="mr-1 ">
                                                                            <img className="rounded meal-n-img" src={media_server + "/" + food.product_image} alt="" />
                                                                        </div>
                                                                        <div className=" flex-column align-items-center product-details meal-ovr-font ps-md-0 ps-2 screenDisplay" style={{ float: "left" }}>
                                                                            <p className="font-weight-bold meal-ovr-font mb-0" >
                                                                                {food.product_name}
                                                                            </p>
                                                                        </div>
                                                                        <div className="meal-ovr-font screenDisplay">
                                                                            <h6 className="text-grey meal-ovr-font color-red" >
                                                                                CHF {food.product_price}
                                                                            </h6>
                                                                        </div>
                                                                        <div className=" flex-column align-items-center product-details meal-ovr-font ps-md-0 ps-2" style={{ float: "left" }}>
                                                                            <p className="font-weight-bold meal-ovr-font mb-0 mobileDisplay" >
                                                                                {food.product_name}
                                                                            </p>
                                                                            <p className="meal-ovr-font mobileDisplay color-red mb-1 mobileDisplay">
                                                                                CHF {food.product_price}
                                                                            </p>
                                                                            <div className="display-flex flex-row align-items-center qty">
                                                                                <button className="btn btn-danger float-end meal-btn-minus" type="button" data-id="" onClick={() => updateQuan('decrement', food.product_id)}>
                                                                                    -
                                                                                </button>
                                                                                <button id={"quantity_" + food.product_id} type="button" className="btn btn-light float-end meal-btn-z" disabled>
                                                                                    0
                                                                                </button>
                                                                                <button className="btn btn-success float-end meal-btn-plus" type="button" data-id="" onClick={() => updateQuan('increment', food.product_id)}>
                                                                                    +
                                                                                </button>
                                                                                <input type="hidden" id="planner_food_id_" value="0" name="planner_food_class[]" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </>
                                                ))
                                            }



                                        </div>
                                        {/* subcategory list End */}
                                        {/* Delivery address information Start */}
                                        <div div className="col-md-11 col-12" id="delivery_location" >
                                            <h4 className="color-red mt-4">Please choose delivery address</h4>
                                            {
                                                addresses.length > 0 &&
                                                addresses.map(singleAddress => (
                                                    <div className="mb-1 ms-3 form-check">
                                                        <input type="radio" className="form-check-input" name="deliveryOption" value={deliveryOption} checked={singleAddress.address_id === deliveryOption} onChange={() => setDeliveryOption(singleAddress.address_id)} />
                                                        <label className="form-check-label color-white"> {singleAddress.street + " " + singleAddress.housenumber + ", " + singleAddress.post_code + " " + singleAddress.town + ", Switzerland"}</label>
                                                    </div>
                                                ))
                                            }

                                            <div className="mb-1 ms-3 form-check">
                                                <input type="radio" className="form-check-input" name="delivery_address" value="" />
                                                <label className="form-check-label color-white">76, 6, Winterthur, 9000, Switzerland </label>
                                            </div>
                                            <div className="d-flex flex-row align-items-center mt-3 p-2 bg-white rounded" style={{ marginTop: "5% !important" }}>
                                                <button type="button" id="form-submit" className="btn btn-success" onClick={() => submitFinalData()}>Submit</button>
                                                <button type="button" onClick={() => backToCategoriesList()} className="btn btn-success ms-3 delivery_status_sub_btn">Back</button>
                                            </div>
                                        </div>
                                        {/* Delivery address information End */}
                                    </>
                                }

                            </div>
                        </div>
                    </form>
                </div>
            }
            {/* Meal Planner Overlay End */}

        </>
    )
}

export default PlannerDishes