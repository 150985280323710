import React from 'react'
import { Link } from 'react-router-dom'

const VenueDetail = () => {
    return (
        <>
            {/* Banner Section Start */}
            <section className="banner-n catering_venue_detail_image">
                <div className="img-fluid col-sm-12 col-md-12  col-lg-12">
                    <div className=" g-0 gx-5 align-items-end">
                        <div className="col-lg-12">
                            <div className="col-lg-10 text-center mx-auto wow slideInLeft padding-10p" data-wow-delay="0.1s text-center">
                                <h1 className="site-font color-red"> <strong>Venues</strong></h1>
                                <br />
                                <ul className="bread">
                                    <li><b><Link to="/">Home</Link></b></li>
                                    <li><b><Link to="/venue">Venues</Link></b></li>
                                    <li className="color-red"><b>Swiss Events Agency</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner Section End */}

            {/* Main Section Start */}
            <div className="container gift-text">
                <div className="row mt-4">
                    <div className="text-center mx-auto mb-3 wow fadeInUp vn-3" data-wow-delay="0.1s" style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp" }}>
                        <h2 className="mb-0 text-left mx-auto wow fadeInUp color-red mt-4 h1" data-wow-delay="0.5s text-center clr-red" style={{ visibility: "visible", animationName: "fadeInUp" }}><strong>Swiss Events Agency</strong></h2>
                        <p className="mb-0 fw-bold">Charges: CHF 3500</p>
                        <p className="mb-3"><i className="fas fa-map-marker-alt"></i> Path of Flora 05 , 1225 Winterthur, Switzerland</p>
                        <div className="row mt-4">
                            <div className="col-lg-8 col-sm-12 left-justify">
                                <h4 className="text-start color-red"><strong>About Us</strong></h4>
                                <p className="px-md-0 px-2 left-justify">
                                    <p><strong>Swiss Events Agency</strong> wants to be at your side for the design, organization, and management of your events and special evenings. </p>
                                    <p>Whether it is in Geneva, Switzerland, or anywhere else on the planet, we will work together to come up with the concepts and original ideas to help make your event unforgettable. </p>
                                    <p>A gala dinner, cocktail, product launch, company evening, fashion parade, conference. </p>
                                    <br />
                                </p>
                            </div>
                            <div className="col-lg-4 col-sm-12 mb-lg-0 mb-4">
                                <img src="https://demo.ninjashushi.ch/backend/storage/customer/584b98aac2dddf59ee2cf19ca4ccb75e.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-4 col-sm-6">
                                <p id="features">
                                    <span className="float-start me-4 p-3">Total Capacity</span> <span className="me-4 rounded-circle float-end mt-3"><strong>800</strong></span>
                                </p>
                            </div>

                            <div className="col-lg-4 col-sm-6">
                                <p id="features">
                                    <span className="float-start me-4 p-3">Max Standing</span> <span className="me-4 rounded-circle float-end mt-3"><strong>200</strong></span>
                                </p>
                            </div>

                            <div className="col-lg-4 col-sm-6">
                                <p id="features">
                                    <span className="float-start me-4 p-3">Max Seated</span> <span className="me-4 rounded-circle float-end mt-3"><strong>600</strong></span>
                                </p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-8 col-sm-12">
                                <h4 className="text-start color-red"><strong>VENUE FEATURES</strong></h4>
                                <div className="d-flex flex-row ">
                                    <p style={{ width: "28px" }} className="mb-1"><i className="fas fa-star me-3 color-green"></i></p>
                                    <p className="text-left mb-1">Wifi</p>
                                </div>
                                <div className="d-flex flex-row ">
                                    <p style={{ width: "28px" }} className="mb-1"><i className="fas fa-star me-3 color-green"></i></p>
                                    <p className="text-left mb-1">Parking</p>
                                </div>
                                <div className="d-flex flex-row ">
                                    <p style={{ width: "28px" }} className="mb-1"><i className="fas fa-star me-3 color-green"></i></p>
                                    <p className="text-left mb-1">Rain Protection</p>
                                </div>
                                <div className="d-flex flex-row ">
                                    <p style={{ width: "28px" }} className="mb-1"><i className="fas fa-star me-3 color-green"></i></p>
                                    <p className="text-left mb-1">Wheel Chair</p>
                                </div>
                                <div className="d-flex flex-row ">
                                    <p style={{ width: "28px" }} className="mb-1"><i className="fas fa-star me-3 color-green"></i></p>
                                    <p className="text-left mb-1">Unique View</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12 mb-lg-0 mb-4">
                                <h4 className=" color-red text-start"><strong>ROOMS INFORMATION</strong></h4>
                                <div className="row">
                                    <div className="col-lg-12 col-md-4">
                                        <div className="d-flex flex-row">
                                            <p id="rooms_info">Change Room</p>
                                            <p id="rooms_info_value">2</p>
                                        </div>
                                        <div className="d-flex flex-row">
                                            <p id="rooms_info">Main Hall</p>
                                            <p id="rooms_info_value">1</p>
                                        </div>
                                        <hr className="m-0" style={{ width: "100%" }} />
                                    </div>
                                    <div className="col-lg-12 col-md-4">
                                        <div className="d-flex flex-row">
                                            <p id="rooms_info">Total No Of Rooms</p>
                                            <p id="rooms_info_value">3</p>
                                        </div>
                                        <hr className="m-0" style={{ width: "100%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                                <h4 className="color-red text-center"><strong>CATERING FEATURES</strong></h4>
                                <div id="cat_event_features">
                                    Indoor Catering
                                </div>
                                <div id="cat_event_features">
                                    Outdoor Catering
                                </div>
                                <div id="cat_event_features">
                                    Onsite Catering
                                </div>
                                <div id="cat_event_features">
                                    Jetzt Eat Catering
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                                <h4 className="color-red text-center"><strong>EVENT TYPE</strong></h4>
                                <div id="cat_event_features">
                                    All Type Of Events Like
                                </div>
                                <div id="cat_event_features">
                                    Wedding Ceremonies
                                </div>
                                <div id="cat_event_features">
                                    Birthday Parties
                                </div>
                                <div id="cat_event_features">
                                    Corporate Events etc
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Main Section End */}

            {/* About Section Start */}
            <section className="about-section section-padding contact-background-image mt-5">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg- 12 col-md-12 col-sm-12 col-xs-12">
                            <div className="row wow fadeIn" data-wow-delay="0.1s">
                                <div className="col-lg- 12 col-md-12 col-sm-12 col-xs-12  contact-main3">
                                    <h2 className="text-center site-font color-red">Want To Get Info or Book A Venue?</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 text-center contact-btn fadeIn" data-wow-delay="0.2s" style={{ paddingTop: "2%" }}>
                    <button className="btn" type="button"> <Link to="/contact" className="color-white">Contact Us</Link> </button>
                    <button className="btn ms-1" type="button"> <Link to="/catering-checkout/step-1" className="color-white">Book A Venue</Link> </button>
                </div>
            </section>
            {/* About Section End */}

            {/* Map Section Start */}
            <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2699.3909765725843!2d9.366487914934776!3d47.42381917917297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479b1e4a752a9da9%3A0x7cc78e603172ff06!2sRosenbergstrasse%2046%2C%209000%20St.%20Gallen%2C%20Switzerland!5e0!3m2!1sen!2s!4v1653973187669!5m2!1sen!2s" width="100%" height="550" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            {/* Map Section End */}
        </>
    )
}

export default VenueDetail