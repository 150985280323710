import React from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel'
import { SingleItem } from '../SingleItem';
export const PopularProduct = ({popularProducts}) => {
    const popularItemsOptions = {
        loop: false,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 1500,
        responsive: {
            0: {
                margin: 10,
                items: 1,
            },
            576: {
                margin: 10,
                items: 2,
            },
            768: {
                margin: 10,
                items: 2,
            },
            992: {
                margin: 20,
                items: 3,
            },
            1200: {
                margin: 30,
                items: 4,
            },
        },
    };
  return (
        <div className="bg-whitesmoke">
                <br /><br />
                <div className="container" style={{ height: '600px !important' }}>
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" >
                        <h2 className="mb-3 color-red h1">Most Popular Items</h2>
                        <OwlCarousel className='product-popular-carousel wow fadeInUp' data-wow-delay="0.1s" {...popularItemsOptions}>
                        {
                            popularProducts.map(item => (
                                <SingleItem product={item}/> 
                            ))
                        }
                        
                        </OwlCarousel>
                    </div>
                </div>
            </div>
  )
}
