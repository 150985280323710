import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import axios from 'axios'
import { server } from '../../../util/server'
import { useFilterContext } from '../../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'
import Spinner from '../../../Components/Spinner';
import Pagination from "react-js-pagination";

const PlannerWiseOrder = () => {
    const [spinner, setSpinner] = useState(true);
    const { project, isUserLogin } = useFilterContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [plannersList, setPlannersList] = useState([]);
    const [data, setData] = useState([]);

    const getMealPlannerList = async (pageNumber = 1) => {
        try {
            let url = server + `auth/web/meal-planner/meal-planner-orders?page=${pageNumber}&api_type=web&user_id=` + isUserLogin + `&project=` + project
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setPlannersList(data.data.data)
                setData(data.data)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }
    //console.log(plannersList);
    useEffect(() => {
        if (isUserLogin) {
            getMealPlannerList()
        }
        else {
            navigate('/login')
        }
    }, [])

    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Meal Planner Orders</h3>
                                <hr />
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Planner Detail</th>
                                                <th scope="col">Payment Status</th>
                                                <th scope="col">Order Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(plannersList && plannersList.length > 0) ?
                                                plannersList.map((item, key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <Link to={"/user/meal-planner-order-detail/" + item.id}><i className="fa fa-eye" style={{ color: "black" }}></i></Link>
                                                        </td>
                                                        <td>
                                                            <b>Planner Type: </b>
                                                            {item.planner_name === 'weekend_planner' ? 'Weekly Planner' : ''}
                                                            {item.planner_name === 'monthly_planner' ? 'Monthly Planner' : ''}
                                                            {item.planner_name === 'preorder_planner' ? 'Pre-Order Planner' : ''}
                                                            <br />
                                                            <b>Planner Scheduled From :</b> {item.project}<br />
                                                        </td>
                                                        <td>
                                                            <b>Payment: </b> Cash On Delivery<br />
                                                            <b>Total: </b> CHF {item.total}<br />
                                                        </td>
                                                        <td>
                                                            <b>Order Status: </b>{item.status === 1 ? 'Pending' : 'Delivered'}
                                                        </td>
                                                    </tr>
                                                ))
                                                : (<p>No Meal Planners Found</p>)
                                            }
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="d-flex justify-content-center mt-4">

                                                        {
                                                            data.last_page > 1 &&
                                                            <Pagination
                                                                totalItemsCount={data && data.total}
                                                                activePage={data && data.current_page}
                                                                itemsCountPerPage={data && data.per_page}
                                                                onChange={(pageNumber) => { getMealPlannerList(pageNumber) }}
                                                                itemClass='page-item'
                                                                linkClass='page-link'
                                                                activeClass='active'
                                                            />
                                                        }

                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlannerWiseOrder