import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import axios from 'axios'
import { useFormik } from 'formik';
import { server } from '../../util/server'
import { useFilterContext } from '../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { updateProfileSchema } from '../../Schema';
import Spinner from '../../Components/Spinner';
const Profile = () => {
    const [spinner, setSpinner] = useState(true);
    const { t } = useTranslation();
    const { project, isUserLogin } = useFilterContext();
    const [userInfo, setUserInfo] = useState(null)
    const [email, setEmail] = useState('')
    const [subscribe, setSubscribe] = useState(0)
    const [unSubscribe, setUnSubscribe] = useState(0)
    const navigate = useNavigate();

    const getUserInfo = async () => {
        try {
            let url = server + 'auth/user/get-user-info?api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setUserInfo(data.data)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const submitData = async (query, actions) => {
        try {
            // query['project'] = project;
            // query['api_type'] = 'web';
            let url = server + 'auth/user/update-profile?project=' + project + '&api_type=web&user_id=' + isUserLogin
            let res = await axios.post(url, query);
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }

                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))

                    })
                }
            } else {
                toast.success('Profile Updated Successfully!')
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }

    const initialValues = {
        firstName: userInfo ? userInfo.firstName : '',
        lastName: userInfo ? userInfo.lastName : '',
        phone: userInfo ? userInfo.phone : ''
    };

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
        validationSchema: updateProfileSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            submitData(values, actions)
        }
    });

    const handleSubsriptionSubmit = async (event) => {
        event.preventDefault()
        if (subscribe === 1) {
            try {
                let url = server + 'auth/web/newsletter-subscription'
                let res = await axios.post(url, { 'subscriber_email': email });
                let data = await res.data;
                if (data.status === 0) {
                    if (data.message) { toast.error(t(data.message)) }
                    if (data.errors) {
                        Object.keys(data.errors).forEach(key => {
                            data.errors[key].map(item => (
                                toast.error(item)
                            ))
                        })
                    }
                    setSubscribe(0)
                } else {
                    toast.success(t('Thank you for subscribing to our newsletter!'))
                    setSubscribe(0)
                    setEmail('')
                }

            } catch (error) {
                toast.error(t("Unexpected error occurred!"));
                setSubscribe(0)
            }
        }
        else {
            try {
                let url = server + 'auth/user/unsubscribe'
                let res = await axios.post(url, { 'email': email });
                let data = await res.data;
                if (data.status === 0) {
                    if (data.message) { toast.error(t(data.message)) }
                    if (data.errors) {
                        Object.keys(data.errors).forEach(key => {
                            data.errors[key].map(item => (
                                toast.error(item)
                            ))
                        })
                    }
                    setUnSubscribe(0)
                } else {
                    toast.success(t('You have un subscribed successfully!'))
                    setUnSubscribe(0)
                    setEmail('')
                }

            } catch (error) {
                toast.error(t("Unexpected error occurred!"));
                setUnSubscribe(0)
            }
        }

    }

    useEffect(() => {
        if (isUserLogin) {
            getUserInfo()
        }
        else {
            navigate('/login')
        }
    }, [])

    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />

                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3>Profile Update</h3>
                                <hr />
                                <form onSubmit={handleSubmit}>
                                    <div className="row mt-3">
                                        <div className="col-md-6 mb-3">
                                            <label>First Name</label>
                                            <input className="form-control" type="text" name="firstName" defaultValue={values.firstName} onBlur={handleBlur} onChange={handleChange} />
                                            <span id='msg'>{errors.firstName && touched.firstName && errors.firstName}</span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Last Name</label>
                                            <input className="form-control" type="text" name="lastName" defaultValue={values.lastName} onBlur={handleBlur} onChange={handleChange} />
                                            <span id='msg'>{errors.lastName && touched.lastName && errors.lastName}</span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Phone Number</label>
                                            <input className="form-control" type="text" placeholder="+(41) XX XXX XX XX" id="contactphone" name="phone" defaultValue={values.phone} onBlur={handleBlur} onChange={handleChange} />
                                            <span id='msg'>{errors.phone && touched.phone && errors.phone}</span>
                                        </div>
                                        <div className="col-lg-12 float-end">
                                            <input type="submit" defaultValue='Update' className="btn btn-dark mt-4" />
                                        </div>
                                    </div>
                                </form>

                                <h3 className="mt-4">Newsletter Subscription</h3>
                                <hr />
                                <form onSubmit={handleSubsriptionSubmit}>
                                    <div className="row mt-3">
                                        <div className="col-md-6 mb-3">
                                            <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} required className="form-control p-3 mt-3" placeholder="Enter Email" value={email} style={{ boxShadow: "-1px 1px 15px 0px rgba(194, 187, 187, 0.432)" }} />
                                            <input type="submit" className="btn btn-sm btn-danger mt-4" name="subscription" onClick={() => setSubscribe(1)} value="Subscribe" />
                                            <input type="submit" className="btn btn-sm btn-danger mt-4 ms-1" onClick={() => setUnSubscribe(1)} name="subscription" value="Unsubscribe" />
                                        </div>
                                    </div>
                                </form>
                            </>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default Profile