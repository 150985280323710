import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../util/server'
import { useFilterContext } from '../../context/filterContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import Spinner from '../../Components/Spinner'
const OngoingOrders = () => {
    const [spinner, setSpinner] = useState(true);
    const { t } = useTranslation();
    const { media_server, isUserLogin, project } = useFilterContext();
    const [ongoingOrdersList, setOngoingOrdersList] = useState([])
    const navigate = useNavigate();

    const getOngoingOrdersList = async () => {
        try {
            let url = server + 'auth/user/order-history/ongoing?api_type=web&user_id=' + isUserLogin
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === 1) {
                setOngoingOrdersList(data.data)
                setSpinner(false)
            } else {
                setSpinner(false)
                toast.error(data.message)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const cancelOrder = async (id) => {
        try {
            let url = server + 'auth/user/order-cancel'
            let res = await axios.post(url, { 'order_id': id, 'project': project, 'user_id': isUserLogin, 'api_type': 'web' });
            let data = await res.data;
            if (data.status === 0) {
                if (data.message) { toast.error(t(data.message)) }
                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => (
                            toast.error(item)
                        ))
                    })
                }
            } else {
                toast.success(t('Order is Cancelled!'))
                getOngoingOrdersList()
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }

    useEffect(() => {
        if (isUserLogin) {
            getOngoingOrdersList()
        }
        else {
            navigate('/login')
        }
    }, [])
    return (
        <>
            <div className="container py-5">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 py-2 px-3">
                        {spinner ? <Spinner /> :
                            <>
                                <h3 className="col-md-7 mb-0">My Ongoing Orders</h3>
                                <hr />
                                {ongoingOrdersList.length > 0 ?
                                    ongoingOrdersList.map((item, key) => (
                                        <Accordion defaultActiveKey='0'>
                                            <Accordion.Item eventKey={key}>
                                                <Accordion.Header>
                                                    Order ID# {item.order_ref} {item.coupon_code !== null && ('(Coupon: ' + item.coupon_code + ')')}
                                                </Accordion.Header>
                                                <Accordion.Body style={{ backgroundColor: "#f7f7f7" }}>
                                                    <article className="card" style={{ backgroundColor: '#f7f7f7', border: 'none' }}>
                                                        <div className="card-body">
                                                            <article className="card" style={{ backgroundColor: '#f7f7f7', border: 'none' }}>
                                                                <div className="card-body row">
                                                                    <div className="col-md-3 col-sm-6 col-12 mt-2">
                                                                        <strong>Subtotal:</strong>
                                                                        <br />
                                                                        CHF {item.basket_total}
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6 col-12 mt-2">
                                                                        <strong>Delivery Charges:</strong>
                                                                        <br />
                                                                        CHF {item.delivery_charges}
                                                                    </div>
                                                                    {item.order_detail.order_rider &&
                                                                        <div className="col-md-3 col-sm-6 col-12 mt-2">
                                                                            <strong>Rider</strong>
                                                                            <br />
                                                                            {item.order_detail.order_rider ? item.order_detail.order_rider.rider_name : ''}
                                                                            <Link to={"tel:" + item.order_detail.order_rider.rider_number} className="color-black">
                                                                                <i className="fa fa-phone"></i>
                                                                            </Link>
                                                                        </div>
                                                                    }
                                                                    <div className="col-md-3 col-sm-6 col-12 mt-2">
                                                                        <strong>Order Placed From:</strong>
                                                                        <br />
                                                                        {item.project}
                                                                    </div>
                                                                </div>
                                                            </article>
                                                            <div className="track">
                                                                {item.order_status === 'Preparing' &&
                                                                    <>
                                                                        <div className="step active">
                                                                            <span className="icon">
                                                                                <i className="fa fa-check"></i>
                                                                            </span>
                                                                            <span className="text">Confirmed</span>
                                                                        </div>
                                                                        <div className="step active">
                                                                            <span className="icon">
                                                                                <i className="fa fa-user"></i>
                                                                            </span>
                                                                            <span className="text">Preparing</span>
                                                                        </div>
                                                                        <div className="step">
                                                                            <span className="icon">
                                                                                <i className="fa fa-truck"></i>
                                                                            </span>
                                                                            <span className="text">Picked Up</span>
                                                                        </div>
                                                                        <div className="step">
                                                                            <span className="icon">
                                                                                <i className="fa fa-box"></i>
                                                                            </span>
                                                                            <span className="text">Delivered</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {item.order_status === 'Picked Up' &&
                                                                    <>
                                                                        <div className="step active">
                                                                            <span className="icon">
                                                                                <i className="fa fa-check"></i>
                                                                            </span>
                                                                            <span className="text">Confirmed</span>
                                                                        </div>
                                                                        <div className="step active">
                                                                            <span className="icon">
                                                                                <i className="fa fa-user"></i>
                                                                            </span>
                                                                            <span className="text">Preparing</span>
                                                                        </div>
                                                                        <div className="step active">
                                                                            <span className="icon">
                                                                                <i className="fa fa-truck"></i>
                                                                            </span>
                                                                            <span className="text">Picked Up</span>
                                                                        </div>
                                                                        <div className="step">
                                                                            <span className="icon">
                                                                                <i className="fa fa-box"></i>
                                                                            </span>
                                                                            <span className="text">Delivered</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {item.order_status === 'Delivered' &&
                                                                    <>
                                                                        <div className="step active">
                                                                            <span className="icon">
                                                                                <i className="fa fa-check"></i>
                                                                            </span>
                                                                            <span className="text">Confirmed</span>
                                                                        </div>
                                                                        <div className="step active">
                                                                            <span className="icon">
                                                                                <i className="fa fa-user"></i>
                                                                            </span>
                                                                            <span className="text">Preparing</span>
                                                                        </div>
                                                                        <div className="step active">
                                                                            <span className="icon">
                                                                                <i className="fa fa-truck"></i>
                                                                            </span>
                                                                            <span className="text">Picked Up</span>
                                                                        </div>
                                                                        <div className="step active">
                                                                            <span className="icon">
                                                                                <i className="fa fa-box"></i>
                                                                            </span>
                                                                            <span className="text">Delivered</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                            <hr />
                                                            <ul className="row">
                                                                {item.cart &&
                                                                    item.cart.map((item2, key2) => (
                                                                        <li key={key2} className="col-md-4 col-6">
                                                                            <figure className=" mb-3">
                                                                                <div className="aside">
                                                                                    <img src={media_server + "/" + item2.image} className="img-fluid border" alt={item2.product_name} />
                                                                                </div>
                                                                            </figure>
                                                                            <p className="title mb-0"> {item2.product_name}
                                                                                <br />
                                                                                Quantity: {item2.quantity}
                                                                            </p>
                                                                            <span className="text-muted">CHF {item2.price} </span>
                                                                            {item2.allergens &&
                                                                                <p className="title mb-4">Allergens : Allergen</p>
                                                                            }
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                            <hr />
                                                            <div className="row">
                                                                {item.order_status === 'Picked Up' &&
                                                                    <>
                                                                        <div className="col-md-6 m-auto text-center mt-md-0 mt-2">
                                                                            <Link to="#" className="btn btn-warning w-100" data-abc="true">Track Order</Link>
                                                                        </div>
                                                                        <div className="col-md-6 m-auto text-center mt-md-0 mt-2">
                                                                            <Link to="" className="btn btn-warning w-100" data-abc="true">Chat With Rider</Link>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {(item.order_status === 'Preparing' || item.order_status === 'Success') &&
                                                                    <div className="col-md-6 m-auto text-center">
                                                                        <Link to='#' className="btn btn-warning w-100" data-abc="true" onClick={() => cancelOrder(item.order_id)}>Cancel</Link>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </article>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                    ))
                                    : (<p className='text-center'>No Ongoing Orders Found</p>)}
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default OngoingOrders