import './App.css';
import React from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Routes, Route,Navigate, HashRouter  } from 'react-router-dom';
import { Menu } from './Pages/Menu';
import { MenuItem } from './Pages/MenuItem';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { GiftCardListing } from './Pages/GiftCardListing';
import { GiftCardDetail } from './Pages/GiftCardDetail';
import SingleItemDetail from './Components/SingleItemDetail';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import Thankyou from './Pages/Thankyou';
import Register from './Pages/Register';
import Login from './Pages/Login';
import Search from './Pages/Search';
import MealPlanner from './Pages/MealPlanner';
import MealPlannerCart from './Pages/MealPlannerCart';
import PlannerDishes from './Pages/PlannerDishes';
import Catering from './Pages/Catering/Catering';
import CateringEvents from './Pages/Catering/CateringEvents';
import CateringMenu from './Pages/Catering/CateringMenu';
import Venue from './Pages/Catering/Venue';
import VenueDetail from './Pages/Catering/VenueDetail';
import CateringMenuPlan from './Pages/Catering/CateringMenuPlan';
import CateringCart from './Pages/Catering/CateringCart';
import CateringCheckoutForm1 from './Pages/Catering/CateringCheckoutForm1';
import CateringCheckoutForm2 from './Pages/Catering/CateringCheckoutForm2';
import CateringCheckoutForm3 from './Pages/Catering/CateringCheckoutForm3';
import Dashboard from './Pages/User/Dashboard';
import Profile from './Pages/User/Profile';
import Address from './Pages/User/Address';
import OngoingOrders from './Pages/User/OngoingOrders';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Mealplanner.css'
import './Catering.css'
import PastOrders from './Pages/User/PastOrders';
import PlannerWiseOrder from './Pages/User/MealPlanner/PlannerWiseOrder';
import PlannerWiseOrderDetail from './Pages/User/MealPlanner/PlannerWiseOrderDetail';
import MealPlannerOngoingOrder from './Pages/User/MealPlanner/MealPlannerOngoingOrder';
import ScheduledMealPlannerOrder from './Pages/User/MealPlanner/ScheduledMealPlannerOrder';
import MealPlannerOrderHistory from './Pages/User/MealPlanner/MealPlannerOrderHistory';
import CateringVenueBookings from './Pages/User/CateringVenueBookings';
import UserGiftCards from './Pages/User/UserGiftCards';
import Referrals from './Pages/User/Referrals';
import EatWallet from './Pages/User/EatWallet';
import Wishlist from './Pages/User/Wishlist';
import MiniReviewSlider from './Pages/MiniReviewSlider';
import PromotionalBanner from './Pages/PromotionalBanner';
import Vouchers from './Pages/Vouchers';
import { Page404 } from './Pages/error/Page404';
import RecoverPassword from './Pages/RecoverPassword';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Tourism from './Pages/Tourism';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import OrderReviews from './Pages/OrderReviews';
import FAQs from './Pages/FAQs';
function App() {
  const { i18n } = useTranslation();
  const changeLangueg = (lng,lng_name) => {
      sessionStorage.setItem('lng', lng);
      sessionStorage.setItem('lng_name', lng_name)
      i18n.changeLanguage(lng);
  }
  return (
    <BrowserRouter>
      <ToastContainer />
        <Header changeLangueg={changeLangueg} />
        <PromotionalBanner />
        <Routes>
          <Route path="/" element={ <Home  /> } />
          <Route path='/about-us' element={ <About /> } />
          <Route path='/contact' element={ <Contact /> } />
          <Route path='/tourism' element={<Tourism /> } />
          <Route path='/privacy-policy' element={ <PrivacyPolicy /> } />
          <Route path='/terms-and-conditions' element={ <TermsAndConditions /> } />
          <Route path='/reviews/:type' element={ <OrderReviews />} />
          <Route path='/faq' element={ <FAQs /> } />
          <Route path="/menu" element={ <Menu /> } />
          <Route path="/gift-card" element={ <GiftCardListing /> } />
          <Route path="/gift-card/:slug" element={ <GiftCardDetail /> } />
          <Route path="/food/:slug" element={ <SingleItemDetail /> } />
          <Route path="/cart" element={ <Cart /> } />
          <Route path="/checkout" element={ <Checkout /> } />
          <Route path="/thankyou/:orderref" element={ <Thankyou /> } />
          <Route path="/register" element={ <Register /> } />
          <Route path="/login" element={ <Login /> } />
          <Route path="/recover-password" element={ <RecoverPassword /> } />
          <Route path="/search" element={ <Search /> } />
          <Route path="/vouchers" element={ <Vouchers /> } />
          <Route path="/meal-planner" element={ <MealPlanner /> } />
          <Route path="/meal-cart" element={ <MealPlannerCart /> } />
          <Route path="/planner-dishes" element={ <PlannerDishes /> } />
          <Route path="/catering" element={ <Catering /> } />
          <Route path="/events" element={ <CateringEvents /> } />
          <Route path='/catering-menu' element={ <CateringMenu /> } />
          <Route path='/venue' element={ <Venue /> } />
          <Route path='/venue-detail' element={ <VenueDetail /> } />
          <Route path='/catering/:slug' element={ <CateringMenuPlan /> } />
          <Route path='/catering-cart' element={ <CateringCart /> } />
          <Route path='/catering-checkout/step-1' element={ <CateringCheckoutForm1 /> } />
          <Route path='/catering-checkout/step-2' element={ <CateringCheckoutForm2 /> } />
          <Route path='/catering-checkout/step-3' element={ <CateringCheckoutForm3 /> } />
          <Route path='/user/dashboard' element={ <Dashboard /> } />
          <Route path='/user/profile' element={ <Profile /> } />
          <Route path='/user/address' element={ <Address /> } />
          <Route path='/user/order/ongoing' element={ <OngoingOrders /> } />
          <Route path='/user/order/past' element={ <PastOrders /> } />
          <Route path='/user/meal-planner-orders' element={ <PlannerWiseOrder /> } />
          <Route path='/user/meal-planner-order-detail/:id' element={ <PlannerWiseOrderDetail /> } />
          <Route path='/user/ongoing-meal-planner-orders' element={ <MealPlannerOngoingOrder /> } />
          <Route path='/user/scheduled-meal-planner-orders' element={ <ScheduledMealPlannerOrder /> } />
          <Route path='/user/meal-planner-orders-history' element={ <MealPlannerOrderHistory /> } />
          <Route path='/user/catering-venue-booking' element={ <CateringVenueBookings /> } />
          <Route path='/user/gift-card' element={ <UserGiftCards /> } />
          <Route path='/referrals' element={ <Referrals /> } />
          <Route path='/user/eat-wallet' element={ <EatWallet /> } />
          <Route path='/user/wishlist' element={ <Wishlist /> } />
          <Route path='/404' element={ <Page404 /> } />
          <Route path="/:maincategory/:subcategory?" element={<MenuItem />} />
        </Routes>
        <MiniReviewSlider />
        <Footer />
    </BrowserRouter>
  );
}

export default App;
