
import { createContext, useContext, useReducer } from "react";
import reducer from '../reducer/filterReducer';
import { server } from '../util/server';
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";

const FilterContext = createContext();

const initialState = {
    sort_value: null,
    selected_price_range:null,
    isUserLogin: (sessionStorage.getItem('session_id')) ? sessionStorage.getItem('session_id') : null,
    cartTotalCount: (sessionStorage.getItem('count')) ? sessionStorage.getItem('count') : 0,
    coupon: (sessionStorage.getItem('cp')) ? JSON.parse(sessionStorage.getItem('cp')) : null,
    navbar_items: [],
    webSettingsData: null,
    miniReviewSlider:[],
    promotionalBanner:[],
    media_server:"https://demo.ninjashushi.ch/backend",
    meta: null,
    page_banner: null,
    project:'Winterthur',
    main_project_media_url:'https://hamza.wintifood.swiss/backend',
    main_project_url:'https://hamza.wintifood.swiss'

}



export const FilterContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const initailWebsiteSettingData = async() => {
        try {
            let res = await axios.get(server+"auth/web/settings?project=Winterthur");
            let data = await res.data;
            return dispatch({type:"WEB_SETTINGS", payload: data})
        } catch (error) {
           toast.error('Unexpected error')
           
        }
    }
    useEffect(() => {
        initailWebsiteSettingData()
    },[]);

    //update meta
    const updatemetas = (page, page_type = 4, meta = null) => {
        // if (page_type === 0) {
            dispatch({ type: 'UPDATE_METAS', payload: meta })
        // } else {
        //     try {
        //         fetch(`${server}/api/meta-data?page=${page}&page_type=${page_type}`).then((response) => response.json())
        //             .then((data) => dispatch({ type: 'UPDATE_METAS', payload: data.meta }));

        //     } catch (error) {
        //         // return dispatch({type:"API_ERROR"})
        //     }
        // }

    }

     //page banner
     const updatePageBanner = (data) => {
        dispatch({ type: 'UPDATE_PAGE_BANNER', payload: data })

    }

   
    //global sorting method
    const productSortHandler = (event) => {
        let selected_sort_value = event.target.value;
        dispatch({type: 'PRODUCT_SORT_VALUE', payload: selected_sort_value})
    }

   

    //apply price range
    const applyPriceRange = (value) => {
        dispatch({type: 'PRODUCT_PRICE_RANGE_VALUE', payload: value});
    }

 
    // //clear filter
    const clearFilter = () => {
        dispatch({type: 'CLEAR_FILTERS'});
    }

    // //set customer login
    const setUserLogin = (token) => {
        dispatch({type: 'USER_LOGIN', payload:token});
    }

    // //unset customer login
    const unsetUserLogin = () => {
        dispatch({type: 'USER_LOGOUT'});
    }

    // //set cart total quantity
    const setCartTotalCount = (count) => {
        dispatch({type: 'CART_COUNT', payload:count});
    }

    // //coupon
    const setCoupon = (data) => {
        dispatch({type: 'COUPON', payload:data});
    }

    return (
        <FilterContext.Provider value={{ ...state, dispatch, updatemetas, updatePageBanner,productSortHandler, applyPriceRange, clearFilter ,setCartTotalCount,setUserLogin,setCoupon,unsetUserLogin}}>
            {children}
        </FilterContext.Provider>
    )

}

//custom hook
export const useFilterContext = () => {
    return useContext(FilterContext);
}

