
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import { server } from '../util/server';
import axios from 'axios';
import { useFilterContext } from '../context/filterContext';
import { useTranslation } from 'react-i18next';

const MealPlannerCart = () => {
  const navigate = useNavigate()
  const { t,i18n } = useTranslation();
  const [mealData, setMealData] = useState([])
  const [mealPlanner, setMealPlanner] = useState(null)
  const [cartHeading, setCartHeading] = useState(null)
  const {project, isUserLogin} = useFilterContext();
  const getData = async () => {
    try {
        let url = server + 'auth/web/meal-cart?project=' + project + '&api_type=web&user_id=' + isUserLogin
        let res = await axios.get(url);
        let data = await res.data;
        if (data.status === 1) {
          //console.log(data);
            setMealData(data.data)
            setCartHeading(data.planner_heading)
            setMealPlanner(data.meal_planner)

        } else {
            toast.error(data.message)
        }
    } catch (error) {
        return toast.error(t("Unexpected error occurred!"))
    }
}

    useEffect(() => {
        if (isUserLogin) {
            getData()
        }

    }, [])

    const updateMealCart = (type, meal_planner_days_id, quantity, food_id) => {

        axios.post(server+'auth/web/meal-cart/'+type, {
          meal_planner_days_id: meal_planner_days_id,
          quantity: type === 'quantity-increase' ? (Number(quantity)+1) : (Number(quantity)-1),
          food_id:food_id
        })
        .then(function (response) {
          if(response.data.status === 1){
             toast.success(response.data.message)
             getData()
          }else{
            toast.error("Unexpected error occured!")
          }
         
        })
        .catch(function (error) {
            toast.error("Unexpected error occured!")
        });
    }

    const mealPlannerCheckout = () => {
        axios.post(server+'auth/web/meal-cart/checkout', {
          planner_id: mealPlanner.id,
          user_id: isUserLogin
        })
        .then(function (response) {
          if(response.data.status === 1){
            sessionStorage.removeItem('planner_session')
            sessionStorage.removeItem('planner_total')
            toast.success("Meal planner order successfully placed!")
            navigate('/meal-planner')
          }else{
            toast.error("Unexpected error occured!")
          }
        
        })
        .catch(function (error) {
            toast.error("Unexpected error occured!")
        });
    }
  return (
    <>
      <div className="wishlist-area shopping_cart shop-list py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12 mycart">
              <h3 className="pt-4 mb-2">Meal Planner Basket</h3>
              <form method="POST">
                <div className="table-responsive-lg">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="bg-red color-white">
                        <th colSpan="4" className="text-center">{cartHeading} 
                          {
                            mealPlanner ? 
                            mealPlanner.planner_name === 'monthly_planner' || mealPlanner.planner_name === 'weekend_planner'
                            ?
                            "("+mealPlanner.start_date+' - '+mealPlanner.end_date+")"
                            :
                            "("+mealPlanner.start_date+")"
                            :
                            <span>Planner</span>
                          }
                          </th>
                      </tr>
                      <tr>
                        <th style={{ width: '10%' }}>Days</th>
                        <th style={{ width: "30%" }}>Meal 1</th>
                        <th style={{ width: "30%" }}>Meal 2</th>
                        <th style={{ width: "30%" }}>Meal 3</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        mealPlanner && mealPlanner.planner_name === 'monthly_planner' &&
                        Object.entries(mealData).map(([index, item], key) => (
                          <>
                            <tr className="bg-green color-white">
                                <th colSpan="4" className="text-center">{index}</th>
                            </tr>
                            {
                                Object.entries(item).map(([dataindex,data],datakey) => (
                                  <>
                                    {/* Day  */}
                                     {/* Day */}
                                     <tr>
                                      <td>{dataindex}</td>
                                      {
                                        data.map((daysdata, daysindex) => (
                                          <td>
                                              <div className="row mb-2">
                                                <div className="col-lg-8">
                                                  Delivery Charges : CHF {daysdata['delivery_charges']}
                                                </div>
                                                <div className="col-lg-4 text-end">
                                                  <i className="fas fa-clock"></i> {daysdata['time']}
                                                </div>
                                              </div>
                                              {/* Product Div 1 */}
                                              {
                                                daysdata['meal_items'].split("|").map((itemsArray,itemsIndex) => (

                                                    <div className="row mb-2">
                                                      <div className="col-lg-8">
                                                        <p className="color-red mb-0" style={{ lineHeight: "16px" }}>{itemsArray.split("-")[4]}</p>
                                                        <p className="f-13 mb-0" style={{ lineHeight: "16px" }}>CHF {itemsArray.split("-")[2]}</p>
                                                      </div>
                                                      <div className="col-lg-4">
                                                        <button className="btn btn-danger float-end" type="button" style={{ padding: '1px 6px', fontSize: '27px', lineHeight: '17px', borderRadius: '0 !important' }} onClick={() => updateMealCart('quantity-increase',daysdata['meal_planner_days_id'], itemsArray.split("-")[1], itemsArray.split("-")[0])}>+</button><button id="quantity_" className="btn btn-light float-end" style={{ padding: '1px 6px', fontSize: '15px', lineHeight: '17px', borderRadius: '0 !important' }} disabled> {itemsArray.split("-")[1]} </button><button className="btn btn-success float-end" type="button" style={{ padding: '4px 7px', fontSize: '21px', lineHeight: '11px', borderRadius: '0 !important' }} onClick={() => updateMealCart('quantity-decrease',daysdata['meal_planner_days_id'], itemsArray.split("-")[1], itemsArray.split("-")[0])} >-</button>
                                                      </div>
                                                  </div>
                                                ))
                                              }
                                              {/* Product Div end*/}
                                            </td>
                                        ))
                                      }
                                     
                                    </tr>
                                    {/* Day  */}
                                    {/* Day  */}
                                  </>
                                ))
                            }
                          </>
                        ))
                      }
                    {/* monthly planner */}

                    {
                        mealPlanner && mealPlanner.planner_name !== 'monthly_planner' &&
                                Object.entries(mealData).map(([dataindex,data],datakey) => (
                                  <>
                                    {/* Day */}
                                    <tr>
                                      <td>{dataindex}</td>
                                      {
                                        data.map((daysdata, daysindex) => (
                                          <td>
                                              <div className="row mb-2">
                                                <div className="col-lg-8">
                                                  Delivery Charges : CHF {daysdata['delivery_charges']}
                                                </div>
                                                <div className="col-lg-4 text-end">
                                                  <i className="fas fa-clock"></i> {daysdata['time']}
                                                </div>
                                              </div>
                                              {/* Product Div 1 */}
                                              {
                                                daysdata['meal_items'].split("|").map((itemsArray,itemsIndex) => (

                                                    <div className="row mb-2">
                                                      <div className="col-lg-8">
                                                        <p className="color-red mb-0" style={{ lineHeight: "16px" }}>{itemsArray.split("-")[4]}</p>
                                                        <p className="f-13 mb-0" style={{ lineHeight: "16px" }}>CHF {itemsArray.split("-")[2]}</p>
                                                      </div>
                                                      <div className="col-lg-4">
                                                        <button className="btn btn-danger float-end" type="button" style={{ padding: '1px 6px', fontSize: '27px', lineHeight: '17px', borderRadius: '0 !important' }} onClick={() => updateMealCart('quantity-increase',daysdata['meal_planner_days_id'], itemsArray.split("-")[1], itemsArray.split("-")[0])}>+</button><button id="quantity_" className="btn btn-light float-end" style={{ padding: '1px 6px', fontSize: '15px', lineHeight: '17px', borderRadius: '0 !important' }} disabled> {itemsArray.split("-")[1]} </button><button className="btn btn-success float-end" type="button" style={{ padding: '4px 7px', fontSize: '21px', lineHeight: '11px', borderRadius: '0 !important' }} onClick={() => updateMealCart('quantity-decrease',daysdata['meal_planner_days_id'], itemsArray.split("-")[1], itemsArray.split("-")[0])} >-</button>
                                                      </div>
                                                  </div>
                                                ))
                                              }
                                              {/* Product Div end*/}
                                            </td>
                                        ))
                                      }
                                     
                                    </tr>
                                    {/* Day  */}
                                  </>
                                ))
                      }
                    
                   
                      
                      <tr className="bg-black color-white">
                        <td colSpan="4" className="text-end"><b className="me-3">Total:</b> CHF {mealPlanner ? mealPlanner.total : '0.0'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <hr className="mb-4 bg-white" />
              <div className="col-12">
                  <p className="text-center">No Planner Found in Cart!</p>
              </div> */}
                <div className="row mt-3">
                  <div className="col-12">
                    <Link to="/meal-planner" className="btn my-3 btn-dark">Back to Planner</Link>
                    {
                      mealPlanner && <Link to="#" onClick={() => mealPlannerCheckout()} className="btn my-3 btn-danger ms-1">Proceed to checkout</Link>
                    }
                    
                  </div>
                </div>
              </form>
            </div>
            <div className="pt-3 col-md-4 mt-lg-0 mt-md-5 mycart">


            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MealPlannerCart